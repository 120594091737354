import dayjs from 'dayjs'
import { useGetNews } from 'hooks/useGetItems.hook'
import { useEffect, useState } from 'react'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import { NoticeListContainer, NoticeListLi, NoticeListLiDate, NoticeListUl } from '../5_1/NoticeList'

import ListPagenation from '../ListPagenation'
const NewsTitle = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.025em;
    text-align: left;
    padding-inline-start: 5px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    background: none;
    cursor: pointer;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border: none;
    background: none;
    letter-spacing: -0.025em;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: black;
    overflow: hidden;
    width: 215px;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
`
const NewsList = () => {
  const { data } = useGetNews()
  const windowOpen = (to: string) => {
    window.open(to, 'news link')
  }
  const pageSize = 5
  const [total, setTotal] = useState(0)
  const [currentPages, setCurrentPages] = useState(5)
  const handlePageSize = () => {
    if (currentPages < total) {
      setCurrentPages((cur) => (cur += pageSize))
    }
  }
  useEffect(() => {
    if (data != null) {
      setTotal(data.length)
    }
  }, [data])
  return (
    <NoticeListContainer>
      <NoticeListUl>
        {(data != null)
          ? data.map((news) => (
            <NoticeListLi key={news.Idx}>
              <NewsTitle onClick={() => windowOpen(news.LinkPath)}>{news.NewsTitle}</NewsTitle>
              <NoticeListLiDate>{dayjs(news.RegDT).format('YYYY.MM.DD')}</NoticeListLiDate>
            </NoticeListLi>
          ))
          : '없음'}
      </NoticeListUl>
      <ListPagenation currentPages={currentPages} total={total} handlePageSize={handlePageSize} />
    </NoticeListContainer>
  )
}

export default NewsList
