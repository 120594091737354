import AutocallBtn from 'components/Common/AutocallBtn'
import { AutocallForm, AutocallFormItem } from 'components/Common/form'
import AutocallInput from 'components/Common/form/AutocallInput'
import AutocallSelector, { SelectboxOptionType } from 'components/Common/form/AutocallSelector'
import { useValidate, useUser } from 'hooks'
import { useRecoilState } from 'recoil'
import { qnaAgreeQNA, qnaContents, qnaContentsType, qnaEMail, qnaPNumber, qnaTitle, qnaUName } from 'recoil/user/atom'

import QnaTerm from './QnaTerm'
import { useEffect } from 'react'
import { validateTypeFam } from '../../../recoil/validate/atom'

const QNA = () => {
  const { qna } = useUser()
  const qnaOptions: SelectboxOptionType[] = [
    {
      label: '서비스 가입',
      value: 1
    },
    {
      label: '서비스 해지',
      value: 2
    },
    {
      label: '아파트 도입문의',
      value: 6
    },
    {
      label: '이용문의',
      value: 3
    },
    {
      label: '제휴문의',
      value: 4
    },
    {
      label: '기타',
      value: 5
    }
  ]
  const { replace } = useValidate()
  const { qnaTypeV, qnaTitleV, qnaAgreeV, qnaContentsV, qnaEMailV, qnaUNameV, qnaPNumberV } = useValidate().qna
  const [qnaType, setQnaType] = useRecoilState(qnaContentsType)
  const [uName, setUName] = useRecoilState(qnaUName)
  const [pNumber, setPNumber] = useRecoilState(qnaPNumber)
  const [email, setEmail] = useRecoilState(qnaEMail)
  const [title, setTitle] = useRecoilState(qnaTitle)
  const [qnaContent, setQnaContents] = useRecoilState(qnaContents)
  const [agree, setAgree] = useRecoilState(qnaAgreeQNA)
  const qnaUNameValidState = useRecoilState(validateTypeFam('qnaUName'))
  const qnaPNumberValidState = useRecoilState(validateTypeFam('qnaPNumber'))
  const qnaEMailValidState = useRecoilState(validateTypeFam('qnaEMail'))
  const qnaTitleValidState = useRecoilState(validateTypeFam('qnaTitle'))
  const qnaContentsValidateState = useRecoilState(validateTypeFam('qnaContents'))
  const qnaTermAgreeValidState = useRecoilState(validateTypeFam('qnaAgree'))
  const qnaTypeValidState = useRecoilState(validateTypeFam('qnaType'))
  const { replaceName } = replace
  const submitQNAForm = async () => {
    qnaTypeV.validFunc(`${qnaType}`)
    qnaUNameV.validFunc(uName)
    qnaPNumberV.validFunc(pNumber)
    qnaEMailV.validFunc(email)
    qnaTitleV.validFunc(title)
    qnaContentsV.validFunc(qnaContent)
    qnaAgreeV.validFunc(`${agree}`)
    if (!qnaTypeV.validFunc(`${qnaType}`)) {
      window.scrollTo({
        top: 300,
        behavior: 'smooth'
      })
    } else if (!qnaUNameV.validFunc(uName)) {
      window.scrollTo({
        top: 420,
        behavior: 'smooth'
      })
    } else if (!qnaPNumberV.validFunc(pNumber)) {
      window.scrollTo({
        top: 570,
        behavior: 'smooth'
      })
    } else if (!qnaEMailV.validFunc(email)) {
      window.scrollTo({
        top: 720,
        behavior: 'smooth'
      })
    } else if (!qnaTitleV.validFunc(email) || !qnaContentsV.validFunc(qnaContent)) {
      window.scrollTo({
        top: 870,
        behavior: 'smooth'
      })
    } else if (
      qnaTypeV.validFunc(`${qnaType}`) &&
      qnaUNameV.validFunc(uName) &&
      qnaPNumberV.validFunc(pNumber) &&
      qnaEMailV.validFunc(email) &&
      qnaTitleV.validFunc(title) &&
      qnaContentsV.validFunc(qnaContent) &&
      qnaAgreeV.validFunc(`${agree}`)
    ) {
      await qna.requestRegistQNA()
    } else {
      // console.log("not ok")
    }
  }

  useEffect(() => {
    qnaTypeValidState[1]({ valid: true, msg: '' })
    qnaUNameValidState[1]({ valid: true, msg: '' })
    qnaPNumberValidState[1]({ valid: true, msg: '' })
    qnaEMailValidState[1]({ valid: true, msg: '' })
    qnaContentsValidateState[1]({ valid: true, msg: '' })
    qnaTitleValidState[1]({ valid: true, msg: '' })
    qnaTermAgreeValidState[1]({ valid: true, msg: '' })
    setUName('')
    setPNumber('')
    setTitle('')
    setEmail('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUName, setPNumber, setTitle, setEmail])
  return (
    <div>
      <AutocallForm>
        <AutocallFormItem title='문의 유형' validate={qnaTypeV.recoilState[0]}>
          <AutocallSelector
            state={qnaType}
            setState={setQnaType}
            placeholder='문의 유형을 선택해 주세요.'
            options={qnaOptions}
            validate={qnaTypeV}
          />
        </AutocallFormItem>
        <AutocallFormItem title='문의하시는 분' validate={qnaUNameV.recoilState[0]}>
          <AutocallInput
            inputType='text'
            placeholder='ex) 홍길동'
            state={uName}
            setState={setUName}
            validate={qnaUNameV}
            replacer={replaceName}
            maxLength={10}
          />
        </AutocallFormItem>
        <AutocallFormItem title='연락처' validate={qnaPNumberV.recoilState[0]}>
          <AutocallInput
            inputType='number'
            placeholder='ex) 01012345678'
            state={pNumber}
            setState={setPNumber}
            validate={qnaPNumberV}
            maxLength={11}
          />
        </AutocallFormItem>
        <AutocallFormItem title='이메일' validate={qnaEMailV.recoilState[0]}>
          <AutocallInput
            inputType='email'
            placeholder='답변 받으실 이메일 주소를 입력해 주세요. ex) abc@autocall.ai'
            state={email}
            setState={setEmail}
            validate={qnaEMailV}
            maxLength={40}
          />
        </AutocallFormItem>
        <AutocallFormItem title='제목' validate={qnaTitleV.recoilState[0]}>
          <AutocallInput
            inputType='text'
            placeholder='문의 내용을 간단히 입력해 주세요.'
            state={title}
            setState={setTitle}
            validate={qnaTitleV}
            maxLength={30}
          />
        </AutocallFormItem>
        <AutocallFormItem title='문의 내용' validate={qnaContentsV.recoilState[0]} currentLength={qnaContent.length}>
          <AutocallInput
            inputType='textarea'
            placeholder='상세한 문의 내용을 입력해 주세요.'
            maxLength={500}
            state={qnaContent}
            setState={setQnaContents}
            validate={qnaContentsV}
          />
        </AutocallFormItem>
        <AutocallFormItem title='이용 약관 동의' validate={qnaAgreeV.recoilState[0]}>
          <QnaTerm
            title='개인정보 수집 및 이용약관 (1:1문의)'
            state={agree}
            setState={setAgree}
            subTitle='문의 응대 외의 용도로는 사용되지 않습니다.'
            validate={qnaAgreeV}
          />
        </AutocallFormItem>
        <AutocallFormItem>
          <div className='qna-submit'>
            <AutocallBtn title='문의 내용 제출' type='default' onClickFunc={submitQNAForm} />
            <p className='under-text'>문의사항은 담당자 확인 후 답변드리고 있습니다.</p>
          </div>
        </AutocallFormItem>
      </AutocallForm>
    </div>
  )
}

export default QNA
