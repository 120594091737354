import { useGetPageBanner } from 'hooks'
import { Outlet } from 'react-router-dom'

const PageLayout = () => {
  const hook = useGetPageBanner()
  const info = hook.getPageInfo()
  return (
    <div className='page-layout'>
      <div className='page-header'>
        {info.icon !== 'mypage' && info.icon !== 'cancel' && (
          <div className={`header-contents ${info.icon}`}>
            <section className='text-section'>
              <h1>{info.title}</h1>
              <p dangerouslySetInnerHTML={{ __html: info.sub ?? '' }} />
            </section>
          </div>
        )}
      </div>
      <div className='page-contents'>
        <Outlet />
      </div>
    </div>
  )
}

export default PageLayout
