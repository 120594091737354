import { useModal, useTermsListByTelecom } from 'hooks'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpTelecom, otpTermsAgrees } from 'recoil/otp/atom'
import { ReactComponent as RArrow } from 'assets/img/form/termsDetailRightArrow.svg'
import { validateTypeFam } from 'recoil/validate/atom'
import { useEffect } from 'react'
import { TELECOM } from 'service/constants/common'

const AutocallTerms = () => {
  const tel = useRecoilValue(otpTelecom)
  const [termsAgrees, setTerms] = useRecoilState(otpTermsAgrees)
  const setTermsValid = useSetRecoilState(validateTypeFam('terms'))
  const termsList = useTermsListByTelecom()
  const modal = useModal()
  const createTermsAgrees = (
    t1: TermsType = 0,
    t2: TermsType = 0,
    t3: TermsType = 0,
    t4: TermsType = 0,
    t5: TermsType = 0,
    t6: TermsType = 0
  ): TermsAgrees => {
    return {
      Terms1Agree: t1,
      Terms2Agree: t2,
      Terms3Agree: t3,
      Terms4Agree: t4,
      Terms5Agree: t5,
      Terms6Agree: t6
    }
  }
  useEffect(() => {
    return setTerms(createTermsAgrees())
  }, [setTerms])
  const handleClickTerms = (value: number) => {
    switch (value) {
      case 1:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree === 1 ? 0 : 1,
            old.Terms2Agree,
            old.Terms3Agree,
            old.Terms4Agree,
            old.Terms5Agree,
            old.Terms6Agree
          )
        })
        break
      case 2:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree,
            old.Terms2Agree === 1 ? 0 : 1,
            old.Terms3Agree,
            old.Terms4Agree,
            old.Terms5Agree,
            old.Terms6Agree
          )
        })
        break
      case 3:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree,
            old.Terms2Agree,
            old.Terms3Agree === 1 ? 0 : 1,
            old.Terms4Agree,
            old.Terms5Agree,
            old.Terms6Agree
          )
        })
        break
      case 4:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree,
            old.Terms2Agree,
            old.Terms3Agree,
            old.Terms4Agree === 1 ? 0 : 1,
            old.Terms5Agree,
            old.Terms6Agree
          )
        })
        break
      case 5:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree,
            old.Terms2Agree,
            old.Terms3Agree,
            old.Terms4Agree,
            old.Terms5Agree === 1 ? 0 : 1,
            old.Terms6Agree
          )
        })
        break
      default:
        setTerms((old) => {
          return createTermsAgrees(
            old.Terms1Agree,
            old.Terms2Agree,
            old.Terms3Agree,
            old.Terms4Agree,
            old.Terms5Agree,
            old.Terms6Agree === 1 ? 0 : 1
          )
        })
        break
    }
  }
  const handleClickAll = () => {
    if (tel === 1) {
      termsAgrees.Terms1Agree && termsAgrees.Terms2Agree && termsAgrees.Terms4Agree
        ? setTerms(createTermsAgrees())
        : setTerms(createTermsAgrees(1, 1, 0, 1))
    } else if (tel === 2) {
      termsAgrees.Terms1Agree && termsAgrees.Terms2Agree && termsAgrees
        ? setTerms(createTermsAgrees())
        : setTerms(createTermsAgrees(1, 1, 0, 1, 1))
    } else {
      termsAgrees.Terms1Agree && termsAgrees.Terms2Agree
        ? setTerms(createTermsAgrees())
        : setTerms(createTermsAgrees(1, 1))
    }
  }
  const handleTermsDetail = (value: number, title: string) => {
    modal.alert({
      title: '',
      contents: (
        <div className='modal_terms'>
          <p>{title}</p>
          <div dangerouslySetInnerHTML={{ __html: (termsList != null) ? termsList[value] : '' }} />
        </div>
      ),
      isXBtn: false
    })
  }
  useEffect(() => {
    if (tel === TELECOM.KT) {
      if (termsAgrees.Terms1Agree && termsAgrees.Terms2Agree && termsAgrees.Terms3Agree) {
        setTermsValid({ valid: false, msg: '' })
      }
    } else {
      if (termsAgrees.Terms1Agree && termsAgrees.Terms2Agree) {
        setTermsValid({ valid: false, msg: '' })
      }
    }
  }, [tel, termsAgrees, setTermsValid])
  return (termsList != null)
    ? (
      <div className='terms-container'>
        <div
          className={`terms-checkbox all ${
          tel === 0
            ? termsAgrees.Terms1Agree && termsAgrees.Terms2Agree
              ? 'active'
              : ''
            : tel === 1 ? termsAgrees.Terms1Agree && termsAgrees.Terms2Agree && termsAgrees.Terms4Agree ? 'active' : ''
            : termsAgrees.Terms1Agree && termsAgrees.Terms2Agree && termsAgrees.Terms4Agree && termsAgrees.Terms5Agree ? "active" : ''
        }`}
        >
          <label>
            <input className='terms-input' type='checkbox' name='all' id='checkbox-all' onChange={handleClickAll} />
            전체 약관을 모두 확인했으며 이에 동의합니다.
          </label>
        </div>
        <div className={`terms-checkbox ${termsAgrees.Terms1Agree === 1 ? 'active' : ''}`}>
          <label>
            <input
              className='terms-input'
              type='checkbox'
              name='agree1'
              id='agree1'
              onChange={() => handleClickTerms(1)}
            />
            서비스 이용 약관
          </label>
          <button onClick={() => handleTermsDetail(1, '서비스 이용약관')}>
            <RArrow />
          </button>
        </div>
        <div className={`terms-checkbox ${termsAgrees.Terms2Agree === 1 ? 'active' : ''}`}>
          <label>
            <input
              className='terms-input'
              type='checkbox'
              name='agree2'
              id='agree2'
              onChange={() => handleClickTerms(2)}
            />
            개인정보 수집 및 이용 동의
          </label>
          <button onClick={() => handleTermsDetail(2, '개인정보 수집 및 이용 동의')}>
            <RArrow />
          </button>
        </div>

        {(tel !== 0 && termsList['4']) && (
          <div className={`terms-checkbox ${termsAgrees.Terms4Agree === 1 ? 'active' : ''}`}>
            <label>
              <input type='checkbox' name='agree4' id='agree4' onChange={() => handleClickTerms(4)} />
              {tel === 1 ? 'KT' : 'LGU+ 및 LGU+ MVNO'} 개인정보의 제3자 제공동의
            </label>
            <button onClick={() => handleTermsDetail(4, `${tel === 1 ? 'KT' : 'LGU+ 및 LGU+ MVNO'} 개인정보의 제3자 제공동의`)}>
              <RArrow />
            </button>
          </div>
        )}
        {termsList['5'] && (
          <div className={`terms-checkbox ${termsAgrees.Terms5Agree === 1 ? 'active' : ''}`}>
            <label>
              <input type='checkbox' name='agree5' id='agree5' onChange={() => handleClickTerms(5)} />
              {tel === 1 ? '피싱해킹보상보험 무료 동의(선택)' : '개인정보 제3자 제공 동의'}
            </label>
            <button onClick={() => handleTermsDetail(5, tel === 1 ? '피싱해킹보상보험 무료동의' : '개인정보 제3자 제공 동의')}>
              <RArrow />
            </button>
          </div>
        )}
        <div className={`terms-checkbox ${termsAgrees.Terms3Agree === 1 ? 'active' : ''}`}>
          <label>
            <input
              className='terms-input'
              type='checkbox'
              name='agree3'
              id='agree3'
              onChange={() => handleClickTerms(3)}
            />
            마케팅 정보 수신 동의(선택)
          </label>
          <button onClick={() => handleTermsDetail(3, '마케팅 정보 수신동의')}>
            <RArrow />
          </button>
        </div>
      </div>
      )
    : (
      <div />
      )
}

export default AutocallTerms
