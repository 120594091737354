import AutocallBtn from './AutocallBtn'
import xBtn from 'assets/img/modal/ico_close.png'
import {MVNOContents, MVNOTitle} from "../../styles/Web/Modal";
import {useSetRecoilState} from "recoil";
import {mvnoData} from "../../recoil/common/atom";

const MvnoModal = (props: {
    visible: boolean
    onCancel: () => void
    onConfirm: () => void
    termsContents: string
}) => {
    const {visible, onConfirm, onCancel, termsContents} = props
    const setIsMvnoConfirm = useSetRecoilState(mvnoData)
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsMvnoConfirm(e.target.checked)
    }
    return (
        <>
            {visible && (
                <div className='modal-container' style={{top:'0', left:'0'}}>
                    <div
                        className='modal-bg'
                        onClick={() => {
                            setIsMvnoConfirm(false)
                            onCancel()
                        }}
                    />
                    <div className='modal-contents'>
                        <button className='close-btn' onClick={() => {
                            setIsMvnoConfirm(false)
                            onCancel()
                        }}>
                            <img src={xBtn} alt='cancel btn'/>
                        </button>
                        <div className='modal-header'>
                            <MVNOTitle>
                                <p className="description">
                                    KT MVNO(알뜰폰) 추가
                                    <br/>
                                    약관 동의가 필요합니다.
                                </p>
                                <p className="title">아래 약관 확인 후 동의해주세요.</p>
                            </MVNOTitle>
                        </div>
                        <div className='modal-body'>
                            <MVNOContents>
                                <div className="check-wrap">
                                    <input type="checkbox" id="terms-check" onChange={handleCheckboxChange}/>
                                    <label className="terms-title" htmlFor="terms-check">
                                        KT MVNO
                                        <br/>
                                        제3자제공
                                    </label>
                                </div>
                                <div className="terms-contents">
                                    {termsContents}
                                </div>
                            </MVNOContents>
                        </div>
                        <div className='modal-footer'>
                            <AutocallBtn type="inlineButton" title='동의 및 인증번호 받기' onClickFunc={onConfirm}/>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MvnoModal