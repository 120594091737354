import { PropsWithChildren } from 'react'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
const UsageSection = styled.section`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    display: flex;
    gap: 40px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`
const UsageSectionContainer = ({ children }: PropsWithChildren) => {
  return <UsageSection>{children}</UsageSection>
}

export default UsageSectionContainer
