import { CSSProperties, ReactNode } from 'react'
import DaumPostcodeEmbed from 'react-daum-postcode'

interface DaumPostcodeEmbedProps {
  scriptUrl?: string
  onComplete?: any
  onSearch?: any
  onClose?: any
  onResize?: any
  className?: string
  style?: CSSProperties
  defaultQuery?: string
  autoClose?: boolean
  errorMessage?: ReactNode
}
const DaumPostCodeEmbed = (props: DaumPostcodeEmbedProps) => {
  return <DaumPostcodeEmbed {...props} />
}
export default DaumPostCodeEmbed