import { Apart2Desc } from '../../styles/Web/Apart/apart2desc_styled'
import { ApartLayout } from '../../styles/Web/Apart/styled'

import Apart2DescCard from './Apart2DescSection/Apart2DescCard'
const Apart2DescSection = () => {
  return (
    <ApartLayout>
      <Apart2Desc>
        <h1>
          오토콜 도입 단지, <br />
          어떤 점이 좋아질까요??
        </h1>
        <Apart2DescCard />
      </Apart2Desc>
    </ApartLayout>
  )
}
export default Apart2DescSection
