import STYLE_GUIDE from '../../service/constants/styleGuide'
import styled from 'styled-components'
// import sticker_banner from "assets/img/sticker/sticker_banner.png"
import sticker_banner2 from 'assets/img/sticker/sticker_banner_sub.png'
import sticker_banner3 from 'assets/img/sticker/zero.png'
import StickerOption from '../../components/4_Sticker/StickerOption'
const StickerWrap = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    text-align: center;
    .first_banner {
      width: 100%;
      height: 196px;
      margin-bottom: 89px;
      cursor: pointer;
    }
    .second_banner {
      margin: 0 auto;
      display: flex;
      width: 826px;
      height: 221px;
    }
    > div {
      display: flex;
      > span {
        font-weight: 300;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.025em;
        color: ${STYLE_GUIDE.COLOR.footer4};
        display: inline-block;
        margin: 0 auto;
        > img {
          width: 20px;
          height: 20px;
          padding-right: 5px;
        }
      }
    }
    > button {
      cursor: pointer;
      width: 320px;
      height: 74px;
      background: ${STYLE_GUIDE.COLOR.main01};
      box-shadow: 0px 4px 14px #bad8de;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      font-family: GmarketSansBold, serif;
      border-radius: 8px;
      color: #fff;
      border: none;
      margin-bottom: 150px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 320px;
    .first_banner {
      display: none;
    }
    .second_banner {
      width: 100%;
      height: 95px;
    }
    .third_banner {
      width: 320px;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      > span {
        > img {
        }
      }
    }
    > button {
      cursor: pointer;
      width: 320px;
      height: 60px;
      bottom: 510px;
      background: ${STYLE_GUIDE.COLOR.main01};
      border-radius: 10px;
      border: none;
      font-family: GmarketSansBold, serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
    }
  }
`

const Sticker = () => {
  const handleClick = () => {
    window.open('https://smartstore.naver.com/autocall/products/5197169515', 'newTab')
  }
  return (
    <StickerWrap>
      {/* <img className="first_banner" onClick={handleClick} src={sticker_banner} alt="" /> */}
      <img className='second_banner' src={sticker_banner2} alt='' />
      <div>
        <span>
          {/* <img src={icon} alt="" /> */}
          {/* 아이콘을 클릭하면 스티커와 실물 사진을 볼 수 있습니다. */}
        </span>
      </div>
      <StickerOption />
      <img className='third_banner' src={sticker_banner3} alt='' />
      <button onClick={handleClick}>오토콜 스마트스토어</button>
    </StickerWrap>
  )
}

export default Sticker
