import styled from 'styled-components'
import UsageSectionContainer from '../UsageSectionContainer'
import Sticker1 from 'assets/img/usage/tab1sticker1.png'
import Sticker2 from 'assets/img/usage/tab1sticker2.png'
import Sticker3 from 'assets/img/usage/tab1sticker3.png'
import Sticker4 from 'assets/img/usage/tab1sticker4.png'
import StickerPrintBtn from './StickerPrintBtn'
import { useNavigate } from 'react-router-dom'
import STYLE_GUIDE from 'service/constants/styleGuide'

const IntroduceContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 0 230px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    max-width: 540px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`
const IntroImg = styled.img`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 380px;
    height: 167px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 0 auto;
    width: 320px;
    height: 131px;
  }
`
const IntroSectionText = styled.p`
  background-color: ${STYLE_GUIDE.COLOR.sub08};
  border-radius: 30px;
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 520px;
    margin: 0;
    height: fit-content;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.025em;
    text-align: left;
    .first {
      display: inline-block;
      //width: 350px;
      padding: 40px;
      margin: 0;
    }
    .second {
      margin: 0;
      padding: 40px 40px 0 40px;
    }
    .third {
      //width: 350px;
      margin: 0;
      padding: 40px 40px 0 40px;
    }
    .four {
      margin: 0;
      padding: 40px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 320px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.025em;
    text-align: left;
    margin: 10px auto 60px auto;
    .first {
      padding: 24px;
      margin: 0;
    }
    .second {
      margin: 0;
      padding: 24px 24px 0 24px;
    }
    .third {
      margin: 0;
      padding: 24px 24px 0 24px;
      width: 260px;
    }
    .four {
      margin: 0;
      padding: 24px;
    }
  }
`

const BtnContainer = styled.div`
  margin: 16px 0 40px 0;
  > button {
    cursor: pointer;
    width: 125px;
    background-color: ${STYLE_GUIDE.COLOR.main01};
    border-radius: 8px;
    border: none;
    height: 50px;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      font-family: GmarketSansBold, serif;
      color: white;
    }
  }
`
const Introduce = () => {
  const navigate = useNavigate()
  const data = [
    {
      img: <IntroImg src={Sticker1} alt='sticker' />,
      text: (
        <IntroSectionText>
          <p className='first'>
            오토콜 서비스에 가입한 뒤 주차번호판의 연락처를 1566-5382로 교체해 주세요.
            <br />
            호출자의 요청은 서비스 가입 시 등록된 차량번호와 매칭해 가입자의 휴대폰으로 알려드립니다.
          </p>
        </IntroSectionText>
      )
    },
    {
      img: <IntroImg src={Sticker2} alt='sticker' />,
      text: (
        <IntroSectionText>
          <p className='second'>
            오토콜에서 제공하는 대표번호 이미지를 인쇄하여 차량 앞 유리에 부착하여 사용해보세요. <br />
            대표번호 부착 시, 1566-5382 번호가
            <br />
            주차된 차량 정면에서 보이도록 부착하세요.
            <BtnContainer>
              <StickerPrintBtn />
            </BtnContainer>
          </p>
        </IntroSectionText>
      )
    },
    {
      img: <IntroImg src={Sticker3} alt='sticker' />,
      text: (
        <IntroSectionText>
          <p className='third'>
            가입자 외에 1개의 연락처를 추가로 등록할 수 있고 등록된 번호를 1차 연락처로 설정할 수 있습니다. &nbsp;
            마이페이지에서 차량에 매칭된 연락처를 변경하여 사용할 수 있습니다. <br />
            <BtnContainer>
              <button onClick={() => navigate('/mypage')}>
                <span>마이페이지</span>
              </button>
            </BtnContainer>
          </p>
        </IntroSectionText>
      )
    },
    {
      img: <IntroImg src={Sticker4} alt='sticker' />,
      text: (
        <IntroSectionText>
          <p className="four">
            피싱 사기 피해(보이스피싱, 스미싱 등)가 발생한 경우 사고 입증자료(경찰신고서 등)를 제출 시 서비스 이용자에게 1인 1사고당 보험한도액 최대 70만원까지 보상해드립니다.<br /><br />
            ※ 보험금 지급 시 자기부담금(14만원)이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.
          </p>
          {/* <p className='four'>
            피싱 사기 피해(보이스피싱, 스미싱 등)가 발생한 경우 사고 입증자료(경찰신고서 등)를 제출 시 서비스 이용자에게 1인 1사고당 보험한도액 최대 70만원까지 보상해드립니다.<br /><br />
            ※ 보험금 지급 시 자기부담금이 발생하며, 증권상 최대보상한도액 초과 시 보험금이 지급되지 않을 수 있습니다.
          </p> */}
        </IntroSectionText>
      )
    }
  ]
  return (
    <IntroduceContainer>
      {data.map((obj, index) => (
        <UsageSectionContainer key={'intro' + index}>
          {obj.img}
          {obj.text}
        </UsageSectionContainer>
      ))}
    </IntroduceContainer>
  )
}

export default Introduce
