import { AutocallForm, AutocallFormItem, CertPart } from 'components/Common/form'
import TelecomSelector from 'components/Common/form/TelecomSelector'
import { useModal, useSendOTP, useCertOTP, useValidate } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpAuthType, otpPNumber, otpTelecom } from 'recoil/otp/atom'
import { pNumAndCertNumWithValidate } from 'recoil/validate/withFormItem'
import {useEffect, useRef} from 'react'
import STYLE_GUIDE from '../../service/constants/styleGuide'
import styled from 'styled-components'
import {cancelerrTxtActive, isLoading} from '../../recoil/common/atom'
import { validateTypeFam } from '../../recoil/validate/atom'

export const CancelMyPageForm = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      width: 800px;
      margin: 109px -150px 150px auto;
      > h1 {
        margin-bottom: 61px;
        font-weight: 800;
        font-size: 30px;
        line-height: 42px;
      }

      > section {
        width: 400px;
        margin-bottom: 30px;
      }

      .cancel_submit {
        cursor: pointer;
        background-color: ${STYLE_GUIDE.COLOR.main01};
        border: none;
        border-radius: 8px;
        color: white;
        width: 50%;
        height: 50px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-family: GmarketSansBold, serif;
      }

      .cancel_notice-text {
        margin: 30px 0 0 -10px;
        width: 54%;
        color: ${STYLE_GUIDE.COLOR.warning};
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      .mypage_guide {
        width: 50%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: ${STYLE_GUIDE.COLOR.grey02};
        margin: 30px 0;

        > span {
          cursor: pointer;
          padding-left: 7px;
          text-align: center;
          text-decoration-line: underline;
          font-weight: 500;
          line-height: 20px;
          font-family: GmarketSansMedium, serif;
          color: ${STYLE_GUIDE.COLOR.main01};
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin-top: -75px;
      width: 320px;
      display: inline-block;
      > h1 {
        font-weight: 800;
        font-size: 29px;
        line-height: 42px;
        color: ${STYLE_GUIDE.COLOR.grey01};
        margin-bottom: 40px;
        width: 100%;
        display: inline-block;
      }

      > section {
        margin-bottom: 18px;
      }

      .cancel_submit {
        cursor: pointer;
        background-color: ${STYLE_GUIDE.COLOR.main01};
        color: #fff;
        border-radius: 8px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        border: none;
        height: 50px;
        font-family: GmarketSansBold, serif;
      }

      .cancel_notice-text {
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #ff3f33;
      }

      .mypage_guide {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: ${STYLE_GUIDE.COLOR.grey02};

        > span {
          cursor: pointer;
          padding-left: 10px;
          color: ${STYLE_GUIDE.COLOR.main01};
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          font-family: GmarketSansBold, serif;
          text-decoration: underline;
        }
      }
    }
  }
`

const Cancel = () => {
  const [pTelecom, setPTelecom] = useRecoilState(otpTelecom)
  const navigate = useNavigate()
  const send = useSendOTP()
  const { join } = useValidate()
  const { certNumber } = join
  const cert = useCertOTP()
  const setAuthType = useSetRecoilState(otpAuthType)
  const modal = useModal()
  const pNumber = useRecoilValue(otpPNumber)
  const pNumAndCertNumValid = useRecoilValue(pNumAndCertNumWithValidate)
  const certNumValidState = useRecoilState(validateTypeFam('certNumber'))
  const pNumberValidState = useRecoilState(validateTypeFam('phoneNumber'))
  const setErrtxt = useSetRecoilState(cancelerrTxtActive)
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const certRef = useRef<HTMLInputElement>(null)
  const visible = useRecoilValue(isLoading)
  const CancelSumbit = async () => {
    const buttonEle = cancelButtonRef.current
    const inputEle = certRef.current
    if(buttonEle) buttonEle.blur()
    if(inputEle) inputEle.blur()

    setErrtxt(true)
    certNumber.recoilState[1]({ valid: true, msg: '030009' })
    if (pNumber.length < 11) {
      certNumber.recoilState[1]({ valid: true, msg: '200' })
    } else {
      if (await cert.cancel()) {
        await modal.alert({
          title: '정상적으로 해지 되었습니다.',
          contents:
            '가입 당일 해지하신 고객님은 요금이 청구되지 않습니다. 서비스 이용요금은 매월 사용일 수 만큼 계산되어 청구됩니다.',
          onCancel: () => {
            navigate('/')
            window.location.reload()
          },
          onConfirm: () => {
            navigate('/')
            window.location.reload()
          }
        })
      }
    }
  }

  useEffect(() => {
    setErrtxt(false)
    setAuthType('delete')
    pNumberValidState[1]({ valid: false, msg: '' })
    certNumValidState[1]({ valid: false, msg: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrtxt, setAuthType])

  /*20231024 lgu+ 가입/해지 불가 모달*/
  /*useServiceModal(dayjs(SERVICE_DATE.CANCEL_START_DT), dayjs(SERVICE_DATE.END_DT))*/
  return (
    <AutocallForm>
      <CancelMyPageForm>
        <h1>
          서비스 해지를 위해 <br /> 본인인증을 진행해주세요.
        </h1>
        <section>
          <TelecomSelector telecom={pTelecom} setTelecom={setPTelecom} />
        </section>
        <AutocallFormItem title='' validate={pNumAndCertNumValid} essential>
          <CertPart certRef={certRef} onSendOTP={async () => await send.request()} onCertOTP={CancelSumbit} />
        </AutocallFormItem>
        <button disabled={visible} ref={cancelButtonRef} className='cancel_submit' onClick={CancelSumbit}>
          서비스 이용해지
        </button>
        <p className='cancel_notice-text'>
          가입 당일 해지하신 고객님은 요금이 청구되지 않습니다.
          <br />
          서비스 이용요금은 매월 사용일 수 만큼 계산되어 청구됩니다.
        </p>
      </CancelMyPageForm>
    </AutocallForm>
  )
}

export default Cancel
