import { Apart3StepContents } from '../../../styles/Web/Apart/apart3step_styled'
import { StepData } from './Apart3StepContents'
import arrow from 'assets/img/apart/apart-arrow.png'
const ApartStepCard = () => {
  return (
    <Apart3StepContents>
      {StepData.content.map((option, index) => (
        <section key={index}>
          <div>
            <img src={option.img} alt='' />
          </div>
          <div>
            <span>
              <span>{option.title}</span>
              <img className={`${index === 3 && 'arrow_hide'}`} src={arrow} alt='' />
            </span>
            <p dangerouslySetInnerHTML={{ __html: option.text ?? '' }} />
          </div>
        </section>
      ))}
    </Apart3StepContents>
  )
}
export default ApartStepCard
