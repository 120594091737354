import { PropsWithChildren, ReactNode } from 'react'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { usagetoggle } from '../../recoil/common/atom'

const UsageTabContainer = styled.div``
const UasgeTabNavContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 36.2%;
    margin-bottom: 46px;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 0 auto;
    width: 320px;
  }
`

const UsageTabBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 49%;
    border-radius: 50px;
    height: 50px;
    font-family: "GmarketSansMedium", serif;
    border: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: center;
    background-color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub07}` : `${STYLE_GUIDE.COLOR.sub08}`)};
    color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.main01}` : `${STYLE_GUIDE.COLOR.sub05}`)};
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 149px;
    background-color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub07}` : `${STYLE_GUIDE.COLOR.sub08}`)};
    color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.main01}` : `${STYLE_GUIDE.COLOR.sub05}`)};
    border: none;
    border-radius: 50px;
    height: 40px;
    font-family: GmarketSansMedium, serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: -24px 6px 40px 0;
    letter-spacing: -0.025em;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      opacity: 0.7;
    }
  }
`

const TabContentsContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
`

interface UsageTabPropsType extends PropsWithChildren {
  children: ReactNode[]
}

const UsageTab = ({ children }: UsageTabPropsType) => {
  const [tabIdx, setTabIdx] = useRecoilState(usagetoggle)
  const handleTabIdx = (index: number) => {
    setTabIdx(index)
  }
  return (
    <UsageTabContainer>
      <UasgeTabNavContainer>
        <UsageTabBtn color={tabIdx === 0 ? 'choice' : ''} onClick={() => handleTabIdx(0)}>
          오토콜 알아보기
        </UsageTabBtn>
        <UsageTabBtn color={tabIdx === 1 ? 'choice' : ''} onClick={() => handleTabIdx(1)}>
          ARS 호출 방법
        </UsageTabBtn>
      </UasgeTabNavContainer>
      <TabContentsContainer>{children[tabIdx]}</TabContentsContainer>
    </UsageTabContainer>
  )
}

export default UsageTab
