import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
export const Pagenation = styled.div`
  width: fit-content;
  margin: 40px auto 0;
`
export const NextBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    cursor: pointer;
    background: none;
    letter-spacing: -0.025em;
    border: 1px solid #ced6d8;
    border-radius: 100px;
    text-align: center;
   
    > p {
      margin: 0;
      color: ${STYLE_GUIDE.COLOR.grey01}
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 23px;
      &:after{
        content: " +";
        font-size: 20px;
        line-height: 24px;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border: 1px solid #CED6D8;
    border-radius: 100px;
    background:none;
    width: 118px;
    height: 44px;
    cursor: pointer;
    > p {
      margin: 0;
      color: ${STYLE_GUIDE.COLOR.grey01}
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      &:after{
        content: " +";
        font-size: 20px;
        line-height: 24px;
      }
    &:hover {
      opacity: 0.7;
    }
  }
`
interface PaginationPropsType {
  total: number
  pageSize?: number
  currentPages: number
  handlePageSize: () => void
}
const ListPagenation = ({ total = 0, pageSize = 5, currentPages = 5, handlePageSize }: PaginationPropsType) => {
  return (
    <Pagenation>
      {currentPages >= total
        ? (
            ''
          )
        : (
          <NextBtn onClick={handlePageSize}>
            <p>더보기</p>
          </NextBtn>
          )}
    </Pagenation>
  )
}

export default ListPagenation
