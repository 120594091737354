import { selector } from 'recoil'
import { btnDisabled, DisabledType, inputDisabled, ValidateType, validateTypeFam } from './atom'

export const pNumAndCertNumWithValidate = selector<ValidateType>({
  key: 'pNumAndCertNumWithValidate',
  get: ({ get }) => {
    return {
      valid: get(validateTypeFam('phoneNumber')).valid || get(validateTypeFam('certNumber')).valid,
      msg:
        get(validateTypeFam('phoneNumber')).msg === ''
          ? get(validateTypeFam('certNumber')).msg
          : get(validateTypeFam('phoneNumber')).msg
    }
  }
})
export const carNumWithValidate = selector<ValidateType>({
  key: 'carNumWithValidate',
  get: ({ get }) => {
    return {
      valid: get(validateTypeFam('carNumber')).valid || get(validateTypeFam('certCarNumber')).valid,
      msg:
        get(validateTypeFam('carNumber')).msg === ''
          ? get(validateTypeFam('certCarNumber')).msg
          : get(validateTypeFam('carNumber')).msg
    }
  }
})
interface SubmitFormValidType {
  [index: string]: boolean
}
interface CertFormValidType extends SubmitFormValidType {
  vPhoneNumber: boolean
  vCertNumber: boolean
}
interface JoinFormValidType extends CertFormValidType {
  vCarNumber: boolean
  vCouponNumber: boolean
  vTerms: boolean
}
export const joinFormValidate = selector<JoinFormValidType>({
  key: 'joinFormValidate',
  get: ({ get }) => {
    return {
      vPhoneNumber: get(validateTypeFam('phoneNumber')).valid,
      vCertNumber: get(validateTypeFam('certNumber')).valid,
      vCarNumber: get(validateTypeFam('carNumber')).valid,
      vCouponNumber: get(validateTypeFam('couponNumber')).valid,
      vTerms: get(validateTypeFam('terms')).valid
    }
  }
})
interface DisabledSelector {
  [index: string]: DisabledType
  sendBtn: DisabledType
  certBtn: DisabledType
  phoneNumberInput: DisabledType
  certNumberInput: DisabledType
}
export const formDisabled = selector<DisabledSelector>({
  key: 'formDisabled',
  get: ({ get }) => {
    return {
      sendBtn: get(inputDisabled('sendBtn')),
      certBtn: get(btnDisabled('certBtn')),
      phoneNumberInput: get(inputDisabled('phoneInput')),
      certNumberInput: get(inputDisabled('certInput'))
    }
  }
})
