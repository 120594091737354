import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import STYLE_GUIDE from '../../service/constants/styleGuide'

const ContentHeader = ({ children }: PropsWithChildren) => {
  const ContentHeader = styled.div`
    box-sizing: unset;
    height: 29px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: -0.025em;
    text-align: center;
    border-bottom: 1px solid ${STYLE_GUIDE.COLOR.grey01};
  `
  return <ContentHeader>{children}</ContentHeader>
}

export default ContentHeader
