import autocallLogo from 'assets/img/footer/autocall_logo.png'
import { FooterContainer, FooterContainerMobile } from '../../../styles/Common/footer_styled'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
      <FooterContainer>
        <div className='footer_wrap'>
          <section className='logo-section'>
            <img src={autocallLogo} alt='footer autocall logo' />
            <div className='footer_link'>
              <a href='http://datauniverse.co.kr' target='_blank' rel='noreferrer'>
                회사소개
              </a>
              <a href='https://blog.naver.com/datau' target='_blank' rel='noreferrer'>
                서비스블로그
              </a>
              <Link to='terms/0/1'>서비스 이용약관</Link>
              <Link to='terms/0/2'>개인정보처리방침</Link>
            </div>
            <span>Copyright ⓒ Datauniverse. All rights reserved.</span>
          </section>
          <section className='cs-section'>
            <p>1566-7531</p>
            <span className='text1'>평일 09:00 ~ 18:00(주말, 공휴일 휴무)</span>
            <p className='text2'>
              (주)데이터유니버스 | 대표 : 강원석 | 사업자등록번호 : 382-86-01230 <br />
              서울시 영등포구 국제금융로2길 32, 여의도파이낸스타워 7층
            </p>
          </section>
        </div>
      </FooterContainer>
      <FooterContainerMobile>
        <div>
          <p>1566-7531</p>
          <span>평일 09:00 - 18:00 (주말, 공휴일 휴무)</span>
        </div>
        <section>
          <p>
            <a href='http://datauniverse.co.kr' target='_blank' rel='noreferrer'>
              회사소개
            </a>
            <br />
            <a href='https://blog.naver.com/datau' target='_blank' rel='noreferrer'>
              서비스블로그
            </a>
            <br />
            <Link to='terms/0/1'>서비스 이용약관</Link>
            <br />
            <Link to='terms/0/2'>개인정보처리방침</Link>
            <br />
          </p>
          <div>
            (주)데이터유니버스
            <br />
            사업자등록번호 : 382-86-01230 <br />
            대표 : 강원석 <br />
            서울시 영등포구 국제금융로2길 32,
            <br />
            여의도파이낸스타워 7층
          </div>
        </section>
        <span>
          <img src={autocallLogo} alt='footer autocall logo' />
        </span>
      </FooterContainerMobile>
    </>
  )
}

export default Footer
