import { useLocation } from 'react-router-dom'
import menuRoutes from 'router/menuRoutes'

const useGetPageBanner = () => {
  const locate = useLocation()
  function getPageInfo () {
    return menuRoutes.filter((route) => locate.pathname.includes(route.path))[0].meta
  }
  return {
    getPageInfo
  }
}

export default useGetPageBanner
