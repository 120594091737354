import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'
import sidefloatimg from '../../../assets/img/apart/floatingbtn_side.png'

export const ApartLayout = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: ${STYLE_GUIDE.SIZE.maxMainPageSize};
    margin: 0 auto;
    height: 100%;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 360px;
    margin: 0 auto;
    height: 100%;
  }
}
`
export const BackGroundGradient = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    //display: none;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 360px;
    margin: 0 auto;
    background: linear-gradient(to bottom, rgba(239, 248, 251, 0.5) 0%, #ffffff 100%);
  }
}
`
export const ApartHeaderFloatBtn = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    z-index: 2;
    display: flex;
    width: 1100px;
    height: 100px;
    background: white;
    position: fixed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 40px 40px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    .apart-header-btn {
      .nav_home {
        cursor: pointer;
        color: ${STYLE_GUIDE.COLOR.main01};
        background-color: #fff;
        border: 2px solid #60e2ff;
        box-shadow: 0 4px 14px rgba(25, 198, 237, 0.3);
      }

      .nav_bottom {
        cursor: pointer;
        color: #fff;
        border: none;
        background-color: ${STYLE_GUIDE.COLOR.main01};
      }

      padding-left: 200px;

      > button {
        font-family: GmarketSansMedium, serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        width: 180px;
        height: 60px;
        margin: 20px 16px 20px 0;
        border-radius: 30px;
      }
    }

    > div {
      width: 50%;
      display: flex;

      > img {
        width: 184px;
        height: 44px;
        margin: 28px 14px 28px 40px;
      }

      > p {
        font-family: GmarketSansMedium, serif;
        font-weight: 400;
        color: #6C777A;
        font-size: 18px;
        margin: 0;
        line-height: 100px;
        //margin: 38px 0;
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    height: 60px;
    background: white;
    position: fixed;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    .apart-header-btn {
      margin: 17px 20px 17px 20px;
      gap: 10px;

      .nav_home {
        padding: 0;
        cursor: pointer;
        color: ${STYLE_GUIDE.COLOR.main01};
        background-color: #fff;
        border: 2px solid #60e2ff;
      }

      .nav_bottom {
        padding: 0;
        cursor: pointer;
        color: #fff;
        border: none;
        background-color: ${STYLE_GUIDE.COLOR.main01};
      }

      > button {
        font-family: GmarketSansMedium, serif;
        font-weight: 700;
        font-size: 12px;
        box-shadow: 0 2px 8px rgba(16, 168, 202, 0.15);
        width: 68px;
        height: 26px;
        border-radius: 30px;
      }
    }

    > div {
      display: flex;

      > img {
        width: 118px;
        height: 28px;
        margin: 16px 0 16px 20px;
      }

      > p {
        display: none;
      }
    }
  }
}
`
export const ApartSideFloatBtn = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    z-index: 2;
    display: flex;
    width: 80px;
    height: 80px;
    position: fixed;
    border-radius: 40px;
    bottom: 7%;
    background-image: url(${sidefloatimg});
    background-repeat: no-repeat;
    background-size: cover;
    right: 3%;
  }
}
`
export const ApartTermsSection = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    height: auto;
    padding-top: 100px;
    margin: 0 80px;
    .mo {
      display: none;
    }

    .pc {
      margin: 60px 0;
      font-family: GmarketSansBold, serif;
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }

    > div {
      display: block;

      > section {
        > button {
          cursor: pointer;
          padding: 10px 20px;
          margin-right: 12px;
          height: 50px;
          background: ${STYLE_GUIDE.COLOR.sub08};
          border-radius: 50px;
          border: none;
          color: ${STYLE_GUIDE.COLOR.sub05};
        }

        > pre {
          border-top: 1px solid ${STYLE_GUIDE.COLOR.grey01};
          border-bottom: 1px solid #ced6d8;
          padding: 20px 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          white-space: pre-line;
          letter-spacing: normal;
        }
      }
    }

    .back-apart {
      display: flex;
      margin: 40px auto 150px auto;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid #ced6d8;
      border-radius: 100px;
      color: ${STYLE_GUIDE.COLOR.grey01};
      background-color: #fff;
    }

    .terms_change_btn {
      > button {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
      }

      height: 50px;
      margin-bottom: 40px;

      .active {
        color: ${STYLE_GUIDE.COLOR.main01};
        background: ${STYLE_GUIDE.COLOR.sub07};
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    height: auto;
    padding-top: 24px;
    margin: 0 auto;
    width: 320px;
    .pc {
      display: none;
    }

    .mo {
      margin: 60px 0 24px 0;
      width: 100%;
      font-family: GmarketSansBold, serif;
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }

    > div {
      display: block;

      > section {
        > button {
          cursor: pointer;
          padding: 0 20px;
          margin-right: 12px;
          line-height: 40px;
          background: ${STYLE_GUIDE.COLOR.sub08};
          border-radius: 50px;
          border: none;
          color: ${STYLE_GUIDE.COLOR.sub05};
        }

        > pre {
          border-top: 1px solid ${STYLE_GUIDE.COLOR.grey01};
          border-bottom: 1px solid #ced6d8;
          padding: 20px 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          white-space: pre-line;
          letter-spacing: normal;
        }
      }
    }

    .back-apart {
      display: flex;
      margin: 30px auto 90px auto;
      font-weight: 400;
      width: 175px;
      padding: 0 28px;
      height: 44px;
      font-size: 16px;
      line-height: 44px;
      border: 1px solid #ced6d8;
      border-radius: 100px;
      color: ${STYLE_GUIDE.COLOR.grey01};
      background-color: #fff;
    }

    .terms_change_btn {
      margin-bottom: 40px;

      .active {
        color: ${STYLE_GUIDE.COLOR.main01};
        background: ${STYLE_GUIDE.COLOR.sub07};
      }

      > button {
        height: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
`
