import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'

export const Apart4Inquiry = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 967px;
      background: linear-gradient(180deg, rgba(239, 248, 251, 0.5) 0%, #ffffff 100%);
      padding-top: 160px;
      > div {
        display: flex;
        > h1 {
          margin-top: 150px;
          width: 50%;
          font-weight: 500;
          font-size: 50px;
          line-height: 62px;
          font-family: GmarketSansBold, serif;
          color: #131b59;
        }
      }
      .inquiry_form {
        padding-top: 40px;
        margin: 150px 0 150px 100px;
        width: 450px;
        background: #ffffff;
        box-shadow: 0 4px 20px rgba(16, 168, 202, 0.15);
        border-radius: 40px;
        height: 512px;
        > p {
          margin: 11px 0 30px 0;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }
        .mo_under_img {
          display: none;
        }
        .pc_under_img {
          width: 342px;
          height: 54px;
          margin: 15px 55px 0 55px;
        }
        .terms_agree {
          width: 239px;
          margin: 0 0 0 70px;
          justify-content: center;
          display: flex;
          > img {
            width: 26px;
            height: 26px;
          }
          > span {
            padding: 0 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: ${STYLE_GUIDE.COLOR.grey01};
            text-align: center;
          }
          > i {
            line-height: 26px;
            margin: 1px -10px 0 0;
            cursor: pointer;
          }
        }
        > div {
          display: flex;
          justify-content: space-between;
          width: 370px;
          margin: 0 40px 14px 40px;
          > p {
            margin: 11px 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: ${STYLE_GUIDE.COLOR.grey01};
            .textcount {
              color: ${STYLE_GUIDE.COLOR.grey02};
              font-size: 14px;
              line-height: 14px;
            }
          }
          .point {
            &:after {
              color: ${STYLE_GUIDE.COLOR.main01};
              font-size: 22px;
              content: " ·";
            }
          }
          > input {
            padding: 0 10px;
            width: 266px;
            height: 46px;
            background: #ffffff;
            border: 1px solid #ced6d8;
            border-radius: 8px;
          }
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .textarea {
            height: 80px;
            width: 266px;
            padding: 10px;
            border: 1px solid #ced6d8;
            resize: none;
            border-radius: 8px;
            ::placeholder {
              display: inline-block;
              position: fixed;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
              padding-top: 10px;
              top: 0;
            }
          }
        }
        .apart_submit_btn {
          cursor: pointer;
          border: none;
          width: 370px;
          height: 60px;
          background: ${STYLE_GUIDE.COLOR.main01};
          box-shadow: 0 4px 14px #bad8de;
          border-radius: 50px;
          font-family: GmarketSansBold, serif;
          color: #fff;
          margin: 0 40px;
          font-weight: 500;
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
    //
    //모바일
    //
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 967px;
      > div {
        display: block;
        > h1 {
          font-weight: 500;
          font-size: 30px;
          line-height: 38px;
          width: 320px;
          margin: 0 auto;
          font-family: GmarketSansBold, serif;
          color: #131b59;
        }
      }
      .inquiry_form {
        padding-top: 30px;
        margin: 20px auto 104px auto;
        width: 320px;
        background: #ffffff;
        box-shadow: 0 4px 20px rgba(16, 168, 202, 0.15);
        border-radius: 40px;
        height: 656px;
        > p {
          margin: 11px 0 30px 0;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }
        .pc_under_img {
          display: none;
        }
        .mo_under_img {
          width: 192px;
          height: 80px;
          margin: 0 64px;
        }
        .terms_agree {
          width: 239px;
          margin: 10px 0 0 -8px;
          justify-content: center;
          display: flex;
          > img {
            width: 26px;
            height: 26px;
          }
          > span {
            padding: 0 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: ${STYLE_GUIDE.COLOR.grey01};
            text-align: center;
          }
          > i {
            line-height: 30px;
            margin: 1px -10px 0 0;
            cursor: pointer;
          }
        }
        > div {
          display: block;
          justify-content: space-between;
          width: 260px;
          margin: 0 auto;
          > p {
            display: flex;
            margin: 0 0 4px 0;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: ${STYLE_GUIDE.COLOR.grey01};
            .textcount {
              margin: 6px 0 0 5px;
              color: ${STYLE_GUIDE.COLOR.grey02};
              font-size: 14px;
              line-height: 14px;
            }
          }
          .point {
            &:after {
              color: ${STYLE_GUIDE.COLOR.main01};
              font-size: 22px;
              content: " ·";
            }
          }
          > input {
            padding-left: 10px;
            width: 260px;
            height: 46px;
            margin: 0 auto 14px auto;
            background: #ffffff;
            border: 1px solid #ced6d8;
            border-radius: 8px;
          }
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .textarea {
            height: 140px;
            width: 260px;
            border: 1px solid #ced6d8;
            border-radius: 8px;
            padding: 10px;
            resize: none;
            ::placeholder {
              display: inline-block;
              position: fixed;
              resize: none;
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
        .apart_submit_btn {
          cursor: pointer;
          border: none;
          width: 260px;
          height: 60px;
          margin: 0 30px;
          border-radius: 50px;
          background: ${STYLE_GUIDE.COLOR.main01};
          box-shadow: 0 4px 14px #bad8de;
          font-family: GmarketSansBold, serif;
          color: #fff;
          font-weight: 500;
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
`
