import { NavLink, useLocation } from 'react-router-dom'
import { MenuRoute } from 'router/router'

const SupportMenuTab = () => {
  const { pathname } = useLocation()
  const supportRoute: MenuRoute[] = [
    {
      path: 'notice',
      meta: {
        auth: false,
        title: '공지사항',
        sub: '주요 공지사항을 안내드립니다.',
        icon: 'notice'
      }
    },
    {
      path: 'news',
      meta: {
        auth: false,
        title: '오토콜 새소식',
        sub: '오토콜의 새소식을 전해드립니다.',
        icon: 'notice'
      }
    },
    {
      path: 'faq',
      meta: {
        auth: false,
        title: '자주 묻는 질문',
        icon: 'notice'
      }
    },
    {
      path: 'qna',
      meta: {
        display: ['floating'],
        auth: false,
        title: '1:1 문의',
        sub: '오토콜에 더 궁금하신 점이 있으신가요?',
        icon: 'notice'
      }
    }
  ]
  return (
    <div className='support-menu-tab'>
      {supportRoute.map((route, index) => (
        <NavLink
          key={index}
          className={pathname.includes(route.path) ? 'menu-item active' : 'menu-item'}
          to={route.path}
        >
          <span>{route.meta.title}</span>
        </NavLink>
      ))}
    </div>
  )
}

export default SupportMenuTab
