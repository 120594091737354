import SupportMenuTab from 'components/5_Support/SupportMenuTab'
import { Outlet } from 'react-router-dom'

const Support = () => {
  return (
    <div>
      <SupportMenuTab />
      <Outlet />
    </div>
  )
}

export default Support
