import { useNavigate } from 'react-router-dom'
import { MainLayout } from '../../styles/Web/Main/styled'
import { Main2Menu } from '../../styles/Web/Main/main2menu_styled'
const Main2MenuSection = () => {
  const navigate = useNavigate()
  const handleClick = (to: string) => {
    navigate(to)
  }
  return (
    <MainLayout>
      <Main2Menu>
        <div className='menu-list'>
          <button className='service_join' onClick={() => handleClick('join')} />
          <button className='use_info' onClick={() => handleClick('usage')} />
          <button className='contact' onClick={() => handleClick('mypage')} />
          <button className='sticker' onClick={() => handleClick('sticker')} />
        </div>
      </Main2Menu>
    </MainLayout>
  )
}

export default Main2MenuSection
