import { selector } from 'recoil'
import { userAddress1, userAddress2, userPostCode, userRecvNumber, userUName } from './atom'

export const userWithRequestSticker = selector<Sticker>({
  key: 'userWithStickerHistoryInquiry',
  get: ({ get }) => {
    return {
      RecvNumber: get(userRecvNumber),
      Address1: get(userAddress1),
      Address2: get(userAddress2),
      Postcode: get(userPostCode),
      UName: get(userUName)
    }
  }
})
