import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { homeData } from 'recoil/common/atom'
import { otpTelecom } from 'recoil/otp/atom'
import { APIs } from 'service'
import { CMD_TYPE } from 'service/constants/common'
import useAPI from './useAPI.hook'
import useCookies from './useCookies.hook'

export const useGetFAQs = (group: GroupType) => {
  const data: RequestFaqListBody = {
    Group: group,
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_FAQ_LIST, async () => await APIs.api.requestSRS<FAQ[]>(CMD_TYPE.GET_FAQ_LIST, data), {
    staleTime: 10 * 1000
  })
}

export const useGetNotices = () => {
  const data: RequestNoticeBody = {
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_NOTICE_LIST, async () => await APIs.api.requestSRS<Notice[]>(CMD_TYPE.GET_NOTICE_LIST, data), {
    staleTime: 10 * 1000
  })
}
export const useGetNoticeDetail = (noticeIdx: string) => {
  const api = useAPI()
  const data: RequestNoticeDetailBody = {
    Idx: noticeIdx
  }
  return useQuery(CMD_TYPE.GET_NOTICE_DETAIL, async () => await api.requestSRS<Notice>(CMD_TYPE.GET_NOTICE_DETAIL, data), {
    staleTime: 10 * 1000
  })
}
export const useGetNews = () => {
  const data: RequestNewsBody = {
    Length: 100,
    Offset: 0
  }
  return useQuery(CMD_TYPE.GET_NEWS_LIST, async () => await APIs.api.requestSRS<News[]>(CMD_TYPE.GET_NEWS_LIST, data), {
    staleTime: 10 * 1000
  })
}

export const useGetTerms = () => {
  const data: HttpBody = {}
  return useQuery(CMD_TYPE.GET_TERMS, async () => await APIs.api.requestSRS<Terms>(CMD_TYPE.GET_TERMS, data), {
    // staleTime: 10 * 1000,
  })
}

export const useSelectTerms = (
  data: Terms | undefined,
  telecom: string | undefined,
  termsIdx: string | undefined
): string => {
  const termsData: TermsData | undefined = (data != null)
    ? {
        0: {
          1: data.SKTTerms1Msg ?? '',
          2: data.SKTTerms2Msg ?? '',
          3: data.SKTTerms3Msg ?? '',
          4: data.SKTTerms4Msg ?? '',
          5: data.SKTTerms5Msg ?? '',
          6: data.SKTTerms6Msg ?? ''
        },
        1: {
          1: data.KTTerms1Msg ?? '',
          2: data.KTTerms2Msg ?? '',
          3: data.KTTerms3Msg ?? '',
          4: data.KTTerms4Msg ?? '',
          5: data.KTTerms5Msg ?? '',
          6: data.KTTerms6Msg ?? ''
        },
        2: {
          1: data.LGUPTerms1Msg ?? '',
          2: data.LGUPTerms2Msg ?? '',
          3: data.LGUPTerms3Msg ?? '',
          4: data.LGUPTerms4Msg ?? '',
          5: data.LGUPTerms5Msg ?? '',
          6: data.LGUPTerms6Msg ?? ''
        }
      }
    : undefined

  const terms = (telecom: string | undefined, termsIdx: string | undefined): string => {
    if(termsIdx === '1') {
      return termsData ? termsData[Number(telecom)][Number(termsIdx)] : ''
    } else {
      return termsData ? termsData[Number(telecom)][Number(telecom === '0' ? '4' : '6')] : ''
    }
  }
  return terms(telecom, termsIdx)
  // return (terms != null) ? terms[Number(telecom)][Number(termsIdx)] : ''
}
export const useTermsListByTelecom = (): TermsTelecom | undefined => {
  const telecom = useRecoilValue(otpTelecom)
  const { data } = useGetTerms()
  if (data != null) {
    const terms: TermsData = {
      0: {
        1: data.SKTTerms1Msg ?? '',
        2: data.SKTTerms2Msg ?? '',
        3: data.SKTTerms3Msg ?? '',
        4: data.SKTTerms4Msg ?? '',
        5: data.SKTTerms5Msg ?? '',
        6: data.SKTTerms6Msg ?? ''
      },
      1: {
        1: data.KTTerms1Msg ?? '',
        2: data.KTTerms2Msg ?? '',
        3: data.KTTerms3Msg ?? '',
        4: data.KTTerms4Msg ?? '',
        5: data.KTTerms5Msg ?? '',
        6: data.KTTerms6Msg ?? ''
      },
      2: {
        1: data.LGUPTerms1Msg ?? '',
        2: data.LGUPTerms2Msg ?? '',
        3: data.LGUPTerms3Msg ?? '',
        4: data.LGUPTerms4Msg ?? '',
        5: data.LGUPTerms5Msg ?? '',
        6: data.LGUPTerms6Msg ?? ''
      }
    }
    return terms[Number(telecom)]
  }
}

export const useGetHomeDatas = () => {
  const datas = useRecoilValue(homeData)
  const data: HttpBody = {}
  const result = useQuery(CMD_TYPE.GET_HOME_DATA, async () =>
    await APIs.api.requestSRS<ResponseHomeBody>(CMD_TYPE.GET_HOME_DATA, data)
  )
  function news () {
    if (datas != null) {
      return datas.News
    }
  }
  function notice () {
    if (datas != null) {
      return datas.Notice
    }
  }
  function popup () {
    if (datas != null) {
      return datas.Popup
    }
  }
  return {
    query: result,
    news,
    notice,
    popup
  }
}

export const useLoginData = () => {
  const cookie = useCookies()
  const { PNumber, SessionID } = cookie.getSessionCookie()
  // "console.log()
  // cookie.login(PNumber, result.)

  const data: RequestSessionCheckBody = {
    PNumber,
    SessionID
  }
  return useQuery(CMD_TYPE.SESSION_CHECK, async () => await APIs.api.requestSRS<ResponseLoginBody>(CMD_TYPE.SESSION_CHECK, data))
}
export const useStickerData = () => {
  const cookie = useCookies()
  const { PNumber, SessionID } = cookie.getSessionCookie()
  const data: RequestStickerHistoryInquiryBody = {
    PNumber,
    SessionID
  }
  return useQuery(
    CMD_TYPE.STICKER_HISTORY_INQUIRY,
    async () => await APIs.api.requestSRS<ResponseStickerHistoryInquiryBody>(CMD_TYPE.STICKER_HISTORY_INQUIRY, data)
    // { staleTime: 10 * 1000 },
  )
}
