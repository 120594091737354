export const URL = {
  DEV: {
    REST: 'https://dev-srs.autocall.ai'
  },
  PRO: {
    REST: 'https://srs.autocall.ai',
    PDF: '',
    WORK_TOGATHER: '',
    IMG: 'https://images.autocall.ai'
  }
}

export interface TelecomInterface {
  SKT: Telecoms.SKT
  KT: Telecoms.KT
  LGU: Telecoms.LGU
}
export const TELECOM: TelecomInterface = {
  SKT: 0,
  KT: 1,
  LGU: 2
}
interface CMD_TYPE_Interface {
  [index: string]: CmdType
}
export const CMD_TYPE: CMD_TYPE_Interface = {
  SEND_OTP: '030100',
  CERT_OTP: '030101',
  CHANGE_CAR_NUMBER: '030102',
  CHANGE_USER: '030103',
  ADDITONAL_USER: '030104',
  CREATE_CAPTCHA: '030105',
  PAGE_COUNT: '030106',
  PAGE_COUNT_WITH_CHANGE_CAR_NUMBER: '030107',
  STICKER_HISTORY_INQUIRY: '030109',
  SELECT_AND_SEND_STICKER: '030110',
  SESSION_CHECK: '030111',
  SUBSCRIPTION_THROUGH_PAYLETTER: '030112',
  CHECK_CAR_NUMBER: '030113',
  UPDATE_CAR_NUMBER: '030114',
  ADD_CAR_NUMBER: '030115',
  DELETE_CAR_NUMBER: '030116',
  CHANGE_RINKED_CAR_NUMBER: '030117',
  GET_FAQ_LIST: '030200',
  GET_FAQ_DETAIL: '030201',
  GET_NOTICE_LIST: '030202',
  GET_NOTICE_DETAIL: '030203',
  GET_HOME_DATA: '030208',
  GET_NEWS_LIST: '030209',
  REGIST_QUESTION: '030204',
  GET_TERMS: '030205',
  GET_PARTNER_LIST: '030206',
  GET_PARTNER_DETAIL: '030207',
  GET_APART_INQUIRY: '030210',
  CHECK_MVNO_MEMBER: '030119',
}
export const AuthType = {
  join: 'join',
  delete: 'delete',
  login: 'login'
}
export const CallType = {
  web: 'WEB',
  app: 'APP'
}
export const SWITCH_TYPE = {
  USER: 'P',
  ADDITIONAL_USER: 'F'
}
export const AGREE = {
  NO: 0,
  YES: 1
}
interface FAQ_GROUP_TYPE {
  [index: string]: GroupType
}
export const FAQ_GROUP: FAQ_GROUP_TYPE = {
  COMMON: 0,
  CALLER: 1,
  DRIVER: 2,
  STICKER: 3
}
interface FAQ_GROUP_NAME_TYPE {
  [index: string]: string
}
export const FAQ_GROUP_NAME: FAQ_GROUP_NAME_TYPE = {
  COMMON: '공통',
  CALLER: '호출자',
  DRIVER: '운전자',
  STICKER: '스티커'
}
export const Default_Length: number = 5
export const QNA_CONTENT_TYPE = {
  join: 1,
  cancel: 2,
  apart: 3,
  usage: 4,
  partner: 5,
  etc: 6
}
/*20231024 lgu+ 가입/해지 불가 모달*/
/*
export const SERVICE_DATE = {
  MAIN_START_DT: '2023-10-16 00:00',
  CANCEL_START_DT: '2023-10-16 00:00',
  END_DT: '2023-10-23 08:00',
  LINK: '200'
}*/
