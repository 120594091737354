import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'
import apart_bg_img from '../../../assets/img/apart/apart-top-bg.png'
import mo_apart_bg_img from '../../../assets/img/apart/mo_apart_bg_img.png'
export const Apart1Top = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      width: 100%;
      height: 990px;
      background-position: 45% 50%;
      background-image: url(${apart_bg_img}), linear-gradient(to right, #171c5c, #242b79);
      background-repeat: no-repeat no-repeat;
      background-size: cover;
      .banner-top {
        height: 100%;
        width: 100%;
        margin: auto 0;
        .banner_contents {
          width: 50%;
          padding: 272px 0 382px 0;
          .part1 {
            display: flex;
            > span {
              font-weight: 400;
              font-size: 30px;
              line-height: 46px;
              font-family: GmarketSansMedium, serif;
              color: #fff;
              margin-right: 8px;
            }
            > img {
              width: 161px;
              height: 40px;
            }
          }
        }
        .part2 {
          margin-top: 30px;
          > p {
            font-family: "GmarketSansBold", serif;
            font-weight: 500;
            font-size: 70px;
            line-height: 82px;
            color: #fff;
            &:before {
              content: "아파트, 오피스텔";
              color: #60e2ff;
            }
            margin: 0 0 26px 0;
          }
          > span {
            font-family: GmarketSansMedium, serif;
            font-weight: 400;
            font-size: 26px;
            line-height: 34px;
            color: #a3abc5;
          }
        }
      }
      .apart_video {
        margin: -253px auto 0 auto;
        width: 900px;
        border: none;
        height: 506px;

        > iframe {
          border: none;
          border-radius: 20px;
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 60px auto 0 auto;
      width: 100%;
      .banner-top {
        width: 360px;
        height: 600px;
        display: inline-block;
        margin: 0 auto;
        background-position: 50% 50%;
        background-image: url(${mo_apart_bg_img}), linear-gradient(to right, #171c5c, #242b79);
        background-repeat: no-repeat;
        background-size: contain;
        .banner_contents {
          display: none;
        }
      }
      .apart_video {
        margin: -50px 20px 0 20px;
        display: flex;
        height: 180px;
        width: 320px;
        > iframe {
          border: none;
          border-radius: 20px;
        }
      }
    }
  }
`
