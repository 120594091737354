import { Main3Desc } from '../../../styles/Web/Main/main3desc_styled'
import red_light from '../../../assets/img/mainbanner/red_light.png'
import pc_red_light_img from '../../../assets/img/mainbanner/pc_main3_first.gif'
import mo_red_light_img from '../../../assets/img/mainbanner/mo_main3_first.gif'
const MainService2Section = () => {
  return (
    <Main3Desc>
      <div className='main-service_content service2'>
        <section>
          <div className='section_desc service2'>
            <div>
              <img className='color_light' src={red_light} alt='red_light' />
              <p>
                내 개인정보에 <br />
                <span className='underline'>빨간불</span>이 켜졌다?
              </p>
            </div>
          </div>
          <div>
            <span>365일 24시간 노출되고 있는 개인전화번호</span>
            <p>
              매너로 생각하고 차량 위에 올려둔 내 개인전화번호 <br />
              보이스피싱, 불법광고, 스토킹 등 범죄에 악용될 수 있습니다.
            </p>
          </div>
        </section>
        <section>
          <img className='pc' src={pc_red_light_img} alt='red_light_img' />
          <img className='mo' src={mo_red_light_img} alt='red_light_img' />
        </section>
      </div>
    </Main3Desc>
  )
}

export default MainService2Section
