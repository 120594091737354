import {useRoutes} from 'react-router-dom'
import autocallRoutes from 'router'
import React, {useEffect} from 'react'
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {RecapAuthToken, RefreshRecapAuthToken} from "./recoil/common/atom";

const App = () => {
    const element = useRoutes(autocallRoutes)
    const setAuthToken = useSetRecoilState(RecapAuthToken)
    const refreshReCaptcha = useRecoilValue(RefreshRecapAuthToken)
    function handleVerify(value:any) {
        setAuthToken(value)
    }

    if (process.env.NODE_ENV === 'production') {
        console.log = function no_console() {
        }
        console.warn = function no_console() {
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (<div className='app'>{element}
        <GoogleReCaptcha onVerify={handleVerify} refreshReCaptcha={refreshReCaptcha}/></div>
    )
}

export default App
