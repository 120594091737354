import { useRecoilValue } from 'recoil'
import { otpCryptoNumber, otpPNumber } from '../../recoil/otp/atom'
import { CertCompleteState } from '../../recoil/common/atom'
import {RefObject} from "react";

type AutocallBtnType = 'nomal' | 'nomal2' | 'round' | 'cancel' | 'round-cancel' | 'default' | 'inlineButton'
export interface AutocallBtnProps {
  title: string
  type: AutocallBtnType
  onClickFunc: () => void
  disabled?: boolean
  buttonRef?: RefObject<HTMLButtonElement>
}
const AutocallBtn = ({ title, type, disabled = false, onClickFunc, buttonRef }: AutocallBtnProps) => {
  const pNumber = useRecoilValue(otpPNumber)
  const crypto = useRecoilValue(otpCryptoNumber)
  const certcomplete = useRecoilValue(CertCompleteState)
  return (
    <>
      {title === '인증번호' && (
        <>
          {certcomplete
            ? (
              <button className={`autocall-btn ${type} complete`} disabled={disabled}>
                {title}
              </button>
              )
            : (
              <button
                ref={buttonRef}
                className={`autocall-btn ${type} ${pNumber.length > 9 ? 'active' : 'disabled'}`}
                disabled={disabled}
                onClick={onClickFunc}
              >
                {title}
              </button>
              )}
        </>
      )}
      {title === '확인' && type === 'nomal' && (
        <button
          className={`autocall-btn ${type} ${(crypto.length > 3 && !disabled) ? 'active' : 'disabled'}`}
          disabled={disabled}
          onClick={onClickFunc}
        >
          {title}
        </button>
      )}
      {title === '인증완료' && (
        <button className={`autocall-btn ${type} disabled`} disabled={disabled} onClick={onClickFunc}>
          {title}
        </button>
      )}
      {title === '우편번호' && (
        <button className={`autocall-btn ${type} active`} onClick={onClickFunc}>
          {title}
        </button>
      )}
      {type === 'default' && (
        <button ref={buttonRef} className={`autocall-btn ${type}`} onClick={onClickFunc}>
          {title}
        </button>
      )}
      {type === 'nomal2' && (
        <>
          <button className={`autocall-btn ${type}`} onClick={onClickFunc}>
            {title}
          </button>
        </>
      )}
      {type === 'cancel' && (
        <>
          <button className={`autocall-btn ${type}`} onClick={onClickFunc}>
            {title}
          </button>
        </>
      )}
      {type === 'inlineButton' && (
          <>
            <button className={`autocall-btn ${type}`} onClick={onClickFunc}>
              {title}
            </button>
          </>
      )}
    </>
  )
}

export default AutocallBtn
