import { ReturnValidate } from 'hooks/useValidate'
import { SelectHTMLAttributes } from 'react'
import { SetterOrUpdater } from 'recoil'
import { ValidateType } from 'recoil/validate/atom'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import arrow from 'assets/img/support/faq_arrow.png'
const AutocallSelectorComponent = styled.select<{ url: string, valid: boolean }>`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    letter-spacing: -0.05em;
    text-align: left;
    border: 1px solid blue;
    padding: 19px 10px;
    border: ${(props) =>
      props.valid ? `2px solid ${STYLE_GUIDE.COLOR.grey02}` : `2px solid ${STYLE_GUIDE.COLOR.main01}`};
    -webkit-appearance: none; /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    background: url(${(props) => props.url}) no-repeat 95% 50%;
    background-size: 3%;
    &:focus {
      padding: 17px 10px;
      border: 2px solid ${STYLE_GUIDE.COLOR.main01};
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    letter-spacing: -0.05em;
    text-align: left;
    padding: 17px 16px;
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
    line-height: 24px;
    color: ${STYLE_GUIDE.COLOR.grey01};
    border: ${(props) =>
        props.valid ? `2px solid ${STYLE_GUIDE.COLOR.grey04}` : `2px solid ${STYLE_GUIDE.COLOR.main01}`};
    -webkit-appearance: none; /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    background: url(${(props) => props.url}) no-repeat 95% 50%;
    background-size: 16px 8px;
    &:focus {
      border: 2px solid ${STYLE_GUIDE.COLOR.main01};
    }
  }
`
const AutocallOption = styled.option`

`
export interface SelectboxOptionType {
  label: string
  value: string | number
}
interface AutocallSelectboxPropsType extends SelectHTMLAttributes<string> {
  state: number
  setState: SetterOrUpdater<number>
  validate: ReturnValidate<ValidateType>
  options: SelectboxOptionType[]
  placeholder: string
}
const AutocallSelector = ({ state, setState, options, placeholder, validate }: AutocallSelectboxPropsType) => {
  const { recoilState, validFunc } = validate
  const [valid] = recoilState
  const handleBlur = () => {
    if (validFunc(`${state}`)) {
      setState(state)
    }
  }
  const handleChange = (value: string) => {
    setState(Number(value))
  }
  return (
    <AutocallSelectorComponent
      url={arrow}
      valid={valid.valid}
      disabled={false}
      onBlur={handleBlur}
      onChange={(e) => handleChange(e.target.value)}
    >
      {placeholder && <AutocallOption label={placeholder} value={0} />}
      {options.map((option) => (
        <AutocallOption key={option.label} label={option.label} value={option.value}>
          {option.label}
        </AutocallOption>
      ))}
    </AutocallSelectorComponent>
  )
}

export default AutocallSelector
