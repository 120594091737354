import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'

export const Main4How = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      display: flex;
      background-color: ${STYLE_GUIDE.COLOR.sub08};
      > section {
        .division {
          padding: 93px 0;
        }
        width: 1100px;
        margin: 0 auto;
        display: flex;
        > div {
          width: 50%;
        }
        .mo_text {
          display: none;
        }
        .main_howto_content {
          height: 500px;
          padding-top: 190px;
          position: sticky;
          top: 0;
          display: block;
          .pc_text {
            > span {
              font-weight: 700;
              font-size: 32px;
              line-height: 32px;
              color: ${STYLE_GUIDE.COLOR.grey02};
            }
            > p {
              font-weight: 500;
              font-size: 50px;
              line-height: 62px;
              margin: 18px 0 60px 0;
              font-family: "GmarketSansBold", serif;
              color: ${STYLE_GUIDE.COLOR.grey01};
              .underline {
                font-family: "GmarketSansBold", serif;
                font-weight: 500;
                font-size: 50px;
                line-height: 62px;
                background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
              }
            }
          }
          .button_toggle {
            width: 320px;
            height: 80px;
            background-color: ${STYLE_GUIDE.COLOR.sub06};
            border-radius: 20px;
            .tab-nav-item {
              cursor: pointer;
              font-weight: 500;
              background-color: ${STYLE_GUIDE.COLOR.sub06};
              color: ${STYLE_GUIDE.COLOR.sub05};
              height: 80px;
              width: 160px;
              border: none;
              border-radius: 20px;
              > span {
                font-size: 26px;
                line-height: 30px;
                font-family: GmarketSansBold, serif;
              }
              &.active {
                transition: all 0.2s ease-out;
                background-color: ${STYLE_GUIDE.COLOR.main01};
                color: #fff;
                &.caller {
                  background-color: ${STYLE_GUIDE.COLOR.sub02};
                }
                > span {
                  font-size: 26px;
                  line-height: 30px;
                  font-family: GmarketSansBold, serif;
                }
              }
            }
          }
        }
      }
      .img_container {
        position: sticky;
        padding-top: 190px;
        padding-bottom: 140px;
        display: block;
        .img_option {
          > section {
            background: #ffffff;
            border-radius: 40px;
            width: 500px;
            height: 600px;
            margin: auto auto 30px auto;
            > div {
              margin: 0 0 0 50px;
              padding-top: 60px;
              display: flex;
            }
            .round_idx {
              &.caller {
                background-color: #1556d4;
              }
              font-weight: 500;
              font-size: 18px;
              line-height: 32px;
              text-align: center;
              height: 30px;
              width: 30px;
              color: #fff;
              background-color: ${STYLE_GUIDE.COLOR.main01};
              border-radius: 100px;
              font-family: GmarketSansBold, serif;
            }
            .option_title {
              &.caller {
                color: #1556d4;
              }
              font-weight: 500;
              font-size: 26px;
              line-height: 30px;
              margin: 2px 0 0 8px;
              color: ${STYLE_GUIDE.COLOR.main01};
              font-family: GmarketSansBold, serif;
            }
            .option_desc_pc {
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              margin: 14px 0 0 85px;
              color: #7d898b;
            }
            .option_desc_mobile {
              display: none;
            }
            > img {
              display: flex;
              margin: 36px auto 50px auto;
              width: 350px;
              height: 350px;
            }
          }
        }
      }
    }
    //////////////////////////////모바일//////////////////////////
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      background-color: ${STYLE_GUIDE.COLOR.sub08};
      padding-bottom: 70px;
      > section {
        .division {
          padding: 60px 0;
        }
        margin: 0 auto;
        width: 320px;
        .mo_text {
          padding-top: 100px;
          text-align: center;
          > span {
            font-weight: 700;
            font-size: 20px;
            line-height: 16px;
            color: #627292;
          }
          > p {
            margin: 16px 0 30px 0;
            font-family: GmarketSansBold, serif;
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            text-align: center;
            color: #2b3339;
            .underline {
              font-family: "GmarketSansBold", serif;
              background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
            }
          }
        }
        .main_howto_content {
          position: -webkit-sticky;
          position: sticky;
          top: 30px;

          display: block;
          text-align: center;
          .pc_text {
            display: none;
          }
        }
        .button_toggle {
          margin: 0 auto 50px auto;
          width: 260px;
          height: 60px;
          background-color: ${STYLE_GUIDE.COLOR.sub06};
          border-radius: 20px;
          .tab-nav-item {
            cursor: pointer;
            background-color: ${STYLE_GUIDE.COLOR.sub06};
            color: ${STYLE_GUIDE.COLOR.sub05};
            height: 60px;
            width: 130px;
            border: none;
            border-radius: 20px;
            > span {
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              font-family: GmarketSansBold, serif;
            }
            &.active {
              transition: all 0.2s ease-out;
              background-color: ${STYLE_GUIDE.COLOR.main01};
              color: #fff;
              &.caller {
                background-color: ${STYLE_GUIDE.COLOR.sub02};
              }
              > span {
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                font-family: GmarketSansBold, serif;
              }
            }
          }
        }
        .img_container {
          .img_option {
            > section {
              margin: 0 auto 30px auto;
              width: 290px;
              height: 400px;
              background-color: #fff;
              border-radius: 30px;
              > div {
                margin: 0 0 0 30px;
                padding-top: 30px;
                display: flex;
              }
              .round_idx {
                &.caller {
                  background-color: #1556d4;
                }
                font-weight: 500;
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                height: 26px;
                margin-top: 3px;
                width: 26px;
                color: #fff;
                background-color: ${STYLE_GUIDE.COLOR.main01};
                border-radius: 100px;
                font-family: GmarketSansBold, serif;
              }
              .option_title {
                &.caller {
                  color: #1556d4;
                }
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                margin: 2px 0 0 8px;
                color: ${STYLE_GUIDE.COLOR.main01};
                font-family: GmarketSansBold, serif;
              }
              .option_desc_mobile {
                width: 200px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                margin: 8px 0 14px 67px;
                color: #7d898b;
              }
              .option_desc_pc {
                display: none;
              }
              > img {
                margin: 0 auto;
                display: flex;
                width: 220px;
                height: 220px;
              }
            }
          }
        }
      }
    }
  }
`
