import axios, { AxiosError } from 'axios'
import { URL } from 'service/constants/common'
import Utils from 'utils'

class BaseApiService {
  hostURL: string = window.location.host
  private readonly url: string = this.hostURL.includes('dev-www.autocall') ? URL.DEV.REST : this.hostURL.includes('localhost') ? URL.DEV.REST : URL.PRO.REST
  async requestServer<T>(requestData: RequestHttp<RequestBodys>): Promise<AxiosCustomResponseType<T>> {
    return await axios.post(this.url, JSON.stringify(requestData), { withCredentials: true }).catch((error: AxiosError) => {
      if (error.response != null) {
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
      }
      if (error.request) {
        // console.log(error.request)
      } else {
        console.error('Error', error.message)
      }
      // console.log(error.config)
    })
  }

  responseErrorHandler<T>(result: AxiosCustomResponseType<T>): T {
    const { data } = result
    // console.log(data)
    const { Header, Body } = data
    switch (Header.ErrCode) {
      default:
        return Body
    }
  }
}

class AutocallApiService extends BaseApiService {
  setHttpData (CmdType: CmdType, TransactionID: string, Body: RequestBodys): RequestHttp<RequestBodys> {
    return {
      Header: {
        CmdType,
        TransactionID,
        Browser: 'chrome',
        CallApp: 'www',
        LocalIP: '192.168.15.199'
      },
      Body
    }
  }

  async requestSRS<T>(CmdType: CmdType, Body: RequestBodys) {
    try {
      return this.responseErrorHandler<T>(
        await this.requestServer<T>(this.setHttpData(CmdType, Utils.common.getGUID(), Body))
      )
    } catch (e) {
      console.error(e)
    }
  }
}

export default AutocallApiService

export function httpData (CmdType: CmdType, Body?: RequestBodys) {
  return {
    Header: {
      CmdType,
      TransactionID: Utils.common.getGUID(),
      Browser: 'chrome',
      CallApp: 'www',
      LocalIP: '192.168.15.199'
    },
    Body: (Body != null) && Body
  }
}

export async function restServer (httpData: any) {
  const hostServer = window.location.host.includes('dev') || window.location.host.includes('local') ?
      'https://dev-srs.autocall.ai' : 'https://srs.autocall.ai'
  return await axios.post(hostServer, JSON.stringify(httpData), { withCredentials: true })
}

export const hostServer = () => {
  if (window.location.host.includes('dev') || window.location.host.includes('local')) {
    return 'https://srs.autocall.ai'
  } else {
    return 'https://srs.autocall.ai'
  }
}
