import loading from 'assets/img/loading.gif'
interface LoadingProps {
  visible: boolean
}
const Loading = ({ visible }: LoadingProps) => {
  return (
    <>
      {visible && (
        <div className='loading-container'>
          <div className='inner-loading'>
            <img src={loading} alt='loading gif' />
          </div>
        </div>
      )}
    </>
  )
}

export default Loading
