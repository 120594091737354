import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'

export const Apart2Desc = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      padding-top: 170px;
      margin-top: 253px;
      > h1 {
        font-family: GmarketSansBold, serif;
        font-weight: 500;
        font-size: 50px;
        line-height: 62px;
        color: #131b59;
        margin: 0 0 90px 0;
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin-top: 70px;

      > h1 {
        width: 340px;
        margin: 0 auto;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #131b59;
        font-family: GmarketSansBold, serif;
      }
    }
  }
`
export const Apart2DescContents = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      .service_section {
        width: 100%;
        display: flex;
        &.reverse {
          flex-direction: row-reverse;
        }
        > div {
          width: 50%;
          > p {
            margin: 0;
          }
        }
        .text-area {
          margin: auto 0;
          &.style0 {
            height: 280px;
          }
          &.style1 {
            height: 280px;
          }
          &.style2 {
            height: 370px;
          }
          &.style3 {
            height: 266px;
          }
          &.style4 {
            height: 520px;
          }
          .title {
            &.margin {
              margin-left: 80px;
            }
            font-weight: 800;
            font-size: 36px;
            line-height: 46px;
            margin: 20px 0;
          }
          .text {
            &.margin {
              margin-left: 80px;
            }
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: #6c777a;
          }
        }
        .img-area {
          display: flex;
          margin-bottom: 180px;
          > div {
            display: flex;
            > div {
              display: block;
              > p {
                width: 100px;
                margin: 10px 0 0 80px;
                font-weight: 500;
                font-size: 26px;
                line-height: 22px;
                font-family: GmarketSansBold, serif;
                color: ${STYLE_GUIDE.COLOR.main01};
              }
              > img {
                border-radius: 40px;
                width: 230px;
                height: 230px;
              }
            }
          }
          .img-size {
            &.style0 {
              width: 550px;
              height: 280px;
            }
            &.style1 {
              width: 320px;
              height: 280px;
            }
            &.style2 {
              width: 550px;
              height: 370px;
            }
            &.style3 {
              width: 550px;
              height: 266px;
            }
            &.style4 {
              width: 550px;
              height: 520px;
            }
          }
        }
      }
    }
    //
    //모바일
    //
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      .service_section {
        margin: auto 10px;
        width: 340px;
        display: block;
        &.reverse {
          flex-direction: row-reverse;
        }
        > div {
          > p {
            margin: 0;
          }
        }
        .text-area {
          width: 340px;
          .title {
            text-align: left;

            font-weight: 800;
            font-size: 26px;
            line-height: 38px;
            margin: 30px 0 8px 0;
            color: ${STYLE_GUIDE.COLOR.grey01};
          }
          .text {
            margin: auto 0 24px 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #6c777a;
          }
        }
        .img-area {
          display: flex;
          margin: 0 0 70px 0;
          > div {
            display: flex;
            > div {
              display: block;
              > p {
                width: 100px;
                margin: 5px 0 0 50px;
                font-weight: 500;
                font-size: 15px;
                line-height: 15px;
                font-family: GmarketSansBold, serif;
                color: ${STYLE_GUIDE.COLOR.main01};
              }
              > img {
                border-radius: 20px;
                width: 135px;
                height: 135px;
              }
            }
          }
          .img-size {
            &.style0 {
              width: 320px;
              height: 162px;
            }
            &.style1 {
              margin-left: 2px;
              width: 187px;
              height: 163px;
            }
            &.style2 {
              width: 320px;
              height: 215px;
            }
            &.style3 {
              width: 320px;
              height: 155px;
            }
            &.style4 {
              width: 320px;
              height: 303px;
            }
          }
        }
      }
    }
  }
`
