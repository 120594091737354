import { ApartHeaderFloatBtn } from '../../../styles/Web/Apart/styled'
import autocallLogo from 'assets/img/header/autocall.png'
import { useLocation, useNavigate } from 'react-router-dom'
const ApartHeader = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const ApartHeadNav = (route: string) => {
    // console.log(pathname)
    if (pathname === route) {
      window.scrollTo({ top: 10000, left: 0, behavior: 'smooth' })
      // console.log("1")
    } else {
      navigate(route)
    }
  }
  return (
    <ApartHeaderFloatBtn>
      <div>
        <img src={autocallLogo} alt='autocall Logo' />
        <p>입주민의 개인정보를 보호하세요.</p>
      </div>
      <div className='apart-header-btn'>
        <button className='nav_home' onClick={() => ApartHeadNav('/home')}>
          홈페이지
        </button>
        <button className='nav_bottom' onClick={() => ApartHeadNav('/b2b')}>
          제휴문의
        </button>
      </div>
    </ApartHeaderFloatBtn>
  )
}
export default ApartHeader
