interface CatchScrollUnderBottomProps {
  element: HTMLElement | null
  trigerDiff: number
}

const useScroll = () => {
  function handleTop () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  const isElementUnderBottom = ({ element, trigerDiff }: CatchScrollUnderBottomProps) => {
    if (element != null) {
      const { top } = element.getBoundingClientRect()
      const { innerHeight } = window
      return top > innerHeight + (trigerDiff || 0)
    }
  }

  return {
    handleTop,
    isElementUnderBottom
  }
}
export default useScroll
