import { Layout } from 'layout'
import { Navigate, RouteObject } from 'react-router-dom'
import { Home } from 'views'
import pageRoutes from './pageRoutes'

const autocallRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      pageRoutes,
      // 리뉴얼 이전 오토콜 홈페이지  => 현재 오토콜 홈페이지 path redirect
      { path: '/usage/0', element: <Navigate to='/usage' replace /> },
      { path: '/usage/1', element: <Navigate to='/usage' replace /> },
      { path: '/usage/2', element: <Navigate to='/usage' replace /> },
      { path: '/notice/1', element: <Navigate to='/notice' replace /> },
      { path: '/news/1', element: <Navigate to='/news' replace /> },
      { path: '/center', element: <Navigate to='/qna' replace /> },
      { path: '*', element: <Navigate to='/' replace /> }
    ]
  }
]
export default autocallRoutes
