import AutocallJoin from 'components/2_Join/2_1/AutocallJoin'
import { useBeforeunload } from 'react-beforeunload'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Join = () => {
  const navigate = useNavigate()

  useBeforeunload((event) => {
    event.returnValue = false
    event.preventDefault()
  })
  const preventGoBack = useCallback(() => {
    if (window.confirm('변경사항이 저장되지 않을 수 있습니다.')) {
      navigate(-1)
      window.removeEventListener('popstate', preventGoBack)
      navigate(-1)
    } else {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, '', location.href)
      console.log('취소')
    }
  }, [navigate])

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, '', location.href)
    window.addEventListener('popstate', preventGoBack)

    return () => {
      window.removeEventListener('popstate', preventGoBack)
    }
  }, [preventGoBack])

  return (
    <div className='join-container'>
      <AutocallJoin />
    </div>
  )
}

export default Join
