import { Apart2DescContents } from '../../../styles/Web/Apart/apart2desc_styled'
import { ServiceData } from './Aapart2DescContents'

const Apart2DescCard = () => {
  return (
    <Apart2DescContents>
      {ServiceData.content.map((option, index) => (
        <section className={`service_section ${index % 2 === 1 && 'reverse'}`} key={index}>
          <div className={`text-area style${index}`}>
            <p
              className={`title ${index % 2 === 1 && 'margin'}`}
              dangerouslySetInnerHTML={{ __html: option.title ?? '' }}
            />
            <p
              className={`text ${index % 2 === 1 && 'margin'}`}
              dangerouslySetInnerHTML={{ __html: option.text ?? '' }}
            />
          </div>
          <div className='img-area'>
            {index === 1
              ? (
                <div>
                  <img className={`img-size style${index}`} src={option.img} alt='sticker img2' />
                  <div>
                    <img src={option.img2} alt='' />
                    <p>after</p>
                  </div>
                </div>
                )
              : (
                <img className={`img-size style${index}`} src={option.img} alt='sticker img' />
                )}
          </div>
        </section>
      ))}
    </Apart2DescContents>
  )
}
export default Apart2DescCard
