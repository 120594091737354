import { ApartLayout } from '../../../styles/Web/Apart/styled'
import { Apart6Footer } from '../../../styles/Web/Apart/apart6footer_styled'
import { NavLink } from 'react-router-dom'
import autocallLogo from '../../../assets/img/footer/autocall_logo.png'
const ApartFooter = () => {
  return (
    <Apart6Footer>
      <ApartLayout>
        <div className='apart_footer'>
          <section>
            <img src={autocallLogo} alt='footer autocall logo' />
            <div>
              <a href='http://datauniverse.co.kr'>회사소개</a>
              <NavLink to='b2b/terms/1'>서비스 이용약관</NavLink>
              <NavLink to='b2b/terms/2'>개인정보처리방침</NavLink>
            </div>
          </section>
          <p>제휴문의 : biz@datau.co.kr</p>
        </div>
      </ApartLayout>
    </Apart6Footer>
  )
}
export default ApartFooter
