import Apart1TopSection from '../../components/7_Apart/Apart1TopSection'
import Apart2DescSection from '../../components/7_Apart/Apart2DescSection'
import Apart3StepSection from '../../components/7_Apart/Apart3StepSection'
import Apart4InquirySection from '../../components/7_Apart/Apart4InquirySection'
import ApartFooter from '../../components/7_Apart/Common/ApartFooter'
import ApartHeader from '../../components/7_Apart/Common/ApartHeader'
import ApartSideFloat from '../../components/7_Apart/Common/ApartSideFloat'
import { useLocation } from 'react-router-dom'
import ApartTerms from '../../components/7_Apart/Common/ApartTerms'
import { BackGroundGradient } from '../../styles/Web/Apart/styled'
import { useSetRecoilState } from 'recoil'
import { modalControl } from '../../recoil/common/atom'
import { useEffect } from 'react'

const Apart = () => {
  const { pathname } = useLocation()
  const setMypagemodal = useSetRecoilState(modalControl)

  useEffect(() => {
    setMypagemodal(false)
  }, [setMypagemodal])
  return (
    <>
      <ApartHeader />
      {pathname.indexOf('b2b/terms') > 0 ? (
        <ApartTerms />
      ) : (
        <>
          <Apart1TopSection />
          <Apart2DescSection />
          <BackGroundGradient>
            <Apart3StepSection />
            <Apart4InquirySection />
          </BackGroundGradient>
          {/* <Apart5AgencySection /> */}
          <ApartSideFloat />
        </>
      )}
      <ApartFooter />
    </>
  )
}
export default Apart
