import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import main_apart_bg from '../../../assets/img/mainbanner/main_apart_bg.png'
import mo_apart_bg from '../../../assets/img/mainbanner/mo_main_apart_bg.png'

export const Main5Apart = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    height: 658px;
    width: 100%;
    background-image: url(${main_apart_bg});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    > div {
      margin: 0 auto;
      display: block;

      .main-apart-text {
        padding-top: 130px;
        display: block;
        text-align: center;

        > span {
          font-weight: 500;
          font-size: 50px;
          line-height: 62px;
          font-family: "GmarketSansBold", serif;

          .underline {
            font-family: "GmarketSansBold", serif;
            background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
          }
        }

        .pc_text {
          margin: 12px 0 70px 0;
          font-weight: 400;
          font-size: 22px;
          line-height: 32px;
          color: ${STYLE_GUIDE.COLOR.grey01};
        }

        .mo_text {
          display: none;
        }
      }

      .main-apart-nav {
        display: flex;
        justify-content: space-between;
        width: 95.5%;
        margin: 0 auto 140px auto;

        > div:hover {
          background: #0089a8;
          box-shadow: 0 6px 30px #86d8eb;

          > section {
            > p {
              color: white;
            }

            > span {
              color: ${STYLE_GUIDE.COLOR.sub06};
            }

            > button {
              background-color: white;
            }
          }
        }

        > div {
          cursor: pointer;
          width: 50%;
          height: 180px;
          margin: 0 15px;
          background: white;
          border-radius: 30px;
          display: flex;
          justify-content: space-between;

          > section {
            display: inline-block;

            > p {
              margin: 36px 0 10px 30px;
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
              color: ${STYLE_GUIDE.COLOR.grey01};
            }

            > span {
              margin-left: 30px;
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
              color: ${STYLE_GUIDE.COLOR.grey02};
            }

            > img {
              margin: 40px 34px 32px auto;
              display: block;
              width: 40px;
              height: 40px;
            }

            > button {
              cursor: pointer;
              //margin-right: 28px;
              background: #def9ff;
              border-radius: 50px;
              width: 130px;
              height: 40px;
              //border: none;
              margin: 0 28px 0 0;

              > span {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                color: ${STYLE_GUIDE.COLOR.main01};

                > img {
                  line-height: 16px;
                  width: 12px;
                  height: 7px;
                  box-sizing: unset;
                  transform: rotate(90deg);
                  padding-bottom: 6px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .underline {
      background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
      font-family: "GmarketSansBold", serif;
    }

    background-image: url(${mo_apart_bg});
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    height: 840px;
    width: 360px;
    background-color: #EBF3F7;
    margin: 0 auto;
    .main-apart-text {
      padding-top: 135px;
      text-align: center;

      > span {
        width: 270px;
        display: inline-block;
        font-family: GmarketSansBold, serif;
        font-weight: 500;
        font-size: 36px;
        line-height: 48px;
      }

      .pc_text {
        display: none;
      }

      .mo_text {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        margin: 20px 0 50px 0;
        display: inline-block;
      }
    }
  }

  .main-apart-nav {
    display: block;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 140px auto;

    > div:hover {
      background: #0089a8;
      box-shadow: 0 6px 30px #86d8eb;

      > section {
        > p {
          color: white;
        }

        > span {
          color: ${STYLE_GUIDE.COLOR.sub06};
        }

        > button {
          background-color: white;
        }
      }
    }

    > div {
      cursor: pointer;
      width: 320px;
      height: 160px;
      background: white;
      border-radius: 30px;
      display: flex;
      margin: 0 auto 30px auto;

      > section {
        display: inline-block;

        > p {
          margin: 26px 0 8px 26px;
          font-weight: 700;
          font-size: 20px;
          line-height: 20px;
          color: ${STYLE_GUIDE.COLOR.grey01};
        }

        > span {
          display: inline-block;
          width: 200px;
          margin-left: 25px;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }

        > img {
          margin: 30px 8px 0 40px;
          display: block;
          width: 30px;
          height: 30px;
        }

        > button {
          cursor: pointer;
          margin: 38px 0 0 -45px;
          background: #def9ff;
          border-radius: 50px;
          width: 120px;
          height: 40px;
          border: none;

          > span {
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
            color: ${STYLE_GUIDE.COLOR.main01};

            > img {
              line-height: 16px;
              width: 12px;
              height: 7px;
              box-sizing: unset;
              transform: rotate(90deg);
              padding-bottom: 6px;
            }
          }
        }
      }
    }
  }
}
`
