import React from 'react'
import ReactDOM from 'react-dom/client'
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import 'styles/index.scss'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
)
