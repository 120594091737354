import React, { useState } from 'react'
import { MainLayout } from '../../styles/Web/Main/styled'
import { Main6Video } from '../../styles/Web/Main/main7video_styled'
const Main7VideoSection = () => {
  const [tabIdx, setTabIdx] = useState<number>(0)
  const tabData = [
    {
      title: '오토콜의 다양한 소식을 만나보세요.',
      sub: '1분 20초 ver.',
      url: 'https://www.youtube.com/embed/qsvBMTkUq1w'
    },
    {
      title: '오토콜, 당신의 전화번호를 지키세요.',
      sub: '1분 20초 ver.',
      url: 'https://youtube.com/embed/Rq9mtB-Pwhk'
    },
    {
      title: 'SBS Biz 생생경제톡톡',
      sub: '[내 정보를 지키는 쉬운 방법]',
      url: 'https://youtube.com/embed/Ep0tdZijR9M'
    }
  ]
  const handleTabMove = (idx: number) => {
    if (idx !== tabIdx) {
      setTabIdx(idx)
    }
  }
  return (
    <MainLayout>
      <Main6Video>
        <div>
          <span>오토콜 홍보영상</span>
          <p>오토콜을 영상으로 만나보세요.</p>
          <section>
            <div className='video-container'>
              <iframe
                title='autocall video'
                src={tabData[tabIdx].url}
                width='100%'
                height='100%'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            </div>
            <div className='video-tab-nav'>
              {tabData.map((tab, index) => (
                <div key={index} className='video_wrap' onClick={() => handleTabMove(index)}>
                  <button className={`video_list ${index === tabIdx && 'active'} bg${index}`} />
                </div>
              ))}
            </div>
          </section>
        </div>
      </Main6Video>
    </MainLayout>
  )
}

export default Main7VideoSection
