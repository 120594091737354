import {Apart1Top} from '../../styles/Web/Apart/apart1top_styled'
import {ApartLayout} from '../../styles/Web/Apart/styled'

const Apart1TopSection = () => {
    return (
        <Apart1Top>
            <ApartLayout>
                <div className='banner-top'>
                    <div className='banner_contents'>
                        <section className='part1'>
                            <span>운전자 안심호출 서비스 오토콜</span>
                        </section>
                        <section className='part2'>
                            <p>
                                <br/> 도입실시
                            </p>
                            <span>
                오토콜 도입으로 입주민의 <br/>
                개인정보를 보호하세요
              </span>
                        </section>
                    </div>
                </div>
                <div className='apart_video'>
                    <iframe
                        title='autocall video'
                        src='https://www.youtube.com/embed/ffAxmjZB_AY'
                        width='100%'
                        height='100%'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                    />
                </div>
            </ApartLayout>
        </Apart1Top>
    )
}
export default Apart1TopSection
