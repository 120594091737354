import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'

export const Apart6Footer = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 240px;
      background-color: ${STYLE_GUIDE.COLOR.footer};
      .apart_footer {
        width: 100%;
        height: 240px;
        display: flex;
        justify-content: space-between;
        > section {
          display: block;
          height: 240px;
          width: 50%;
          > div {
            display: flex;
            > a {
              font-weight: 400;
              font-size: 18px;
              line-height: 30px;
              margin: 0 24px 50px 0;
              color: #a6a7b4;
            }
          }
          > img {
            margin: 80px 0 25px 0;
            width: 145px;
            height: 31px;
          }
        }
        > p {
          float: right;
          margin: 130px 0 180px 0;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #a6a7b4;
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 255px;
      background-color: ${STYLE_GUIDE.COLOR.footer};
      .apart_footer {
        width: 320px;
        margin: auto 10px;
        height: 255px;
        display: block;
        > section {
          display: block;
          width: 50%;
          > div {
            display: flex;
            width: 360px;
            > a {
              font-weight: 500;
              font-size: 14px;
              margin: 0 10px 0 0;
              color: #a6a7b4;
            }
          }
          > img {
            margin: 70px 0 20px 0;
            width: 106px;
            height: 23px;
          }
        }
        > p {
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          margin: 30px 0;
          color: #a6a7b4;
        }
      }
    }
  }
`
