import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import thumbnail1 from '../../../assets/img/mainbanner/video_thumbnail1.png'
import thumbnail2 from '../../../assets/img/mainbanner/video_thumbnail2.png'
import thumbnail3 from '../../../assets/img/mainbanner/video_thumbnail3.png'

export const Main6Video = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 886px;
      > div {
        margin: 90px auto 170px auto;
        display: inline-block;
        width: 100%;
        text-align: center;
        > span {
          font-weight: 700;
          font-size: 32px;
          line-height: 32px;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }
        > p {
          font-family: GmarketSansBold, serif;
          font-weight: 500;
          font-size: 50px;
          line-height: 62px;
          color: ${STYLE_GUIDE.COLOR.grey01};
          margin: 18px 0 60px 0;
        }
        > section {
          display: flex;
          height: 451px;
          .video-container {
            width: 800px;
            margin-right: 20px;
            > iframe {
              //border: none;
              border-radius: 20px;
            }
          }
          .video-tab-nav {
            width: 276px;
            height: 454px;
            overflow: auto;
            //background: linear-gradient(to bottom, blue, red);

            -ms-overflow-style: none; /* IE and Edge */
            ::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
            .video_wrap {
              border-radius: 20px;
            }
            .video_list {
              cursor: pointer;
              &.active {
                padding: 0;
                border: 6px solid ${STYLE_GUIDE.COLOR.sub01};
                &.bg0 {
                  border-radius: 20px;
                  background-image: url(${thumbnail1});
                }
                &.bg1 {
                  background-image: url(${thumbnail2});
                }
                &.bg2 {
                  background-image: url(${thumbnail3});
                }
              }
              &.bg0 {
                border-radius: 20px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail1});
              }
              &.bg1 {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail2});
              }
              &.bg2 {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail3});
              }
              background-position: 50% 50%;
              background-size: cover;
              border-radius: 20px;
              background-repeat: no-repeat;
              width: 100%;
              height: 156px;
              margin-bottom: 14px;
              border: none;
            }
          }
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 556px;
      margin: 0 auto;
      width: 320px;
      > div {
        margin: 58px auto 43px auto;
        display: inline-block;
        width: 100%;
        text-align: left;
        > span {
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }
        > p {
          font-family: GmarketSansBold, serif;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;
          color: ${STYLE_GUIDE.COLOR.grey01};
          margin: 18px 0 30px 0;
        }
        > section {
          display: inline-block;
          height: auto;
          .video-container {
            height: 202px;
            width: 100%;
          }
          .video-tab-nav {
            width: 320px;
            height: 72px;
            overflow: auto;
            display: flex;
            -ms-overflow-style: none; /* IE and Edge */
            ::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
            .video_list {
              cursor: pointer;
              margin-top: 10px;
              width: 128px;
              height: 72px;
              margin-bottom: 14px;
              background-position: 50% 50%;
              background-size: cover;
              border-radius: 20px;
              background-repeat: no-repeat;
              border: none;
              margin-left: 8px;
              &.active {
                padding: 0;
                border: 4px solid ${STYLE_GUIDE.COLOR.sub01};
                &.bg0 {
                  border-radius: 20px;
                  background-image: url(${thumbnail1});
                }
                &.bg1 {
                  background-image: url(${thumbnail2});
                }
                &.bg2 {
                  background-image: url(${thumbnail3});
                }
              }
              &.bg0 {
                border-radius: 20px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail1});
              }
              &.bg1 {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail2});
              }
              &.bg2 {
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${thumbnail3});
              }
            }
          }
        }
      }
    }
  }
`
