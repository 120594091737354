import STYLE_GUIDE from '../../../service/constants/styleGuide'
import bg_car from '../../../assets/img/mypage/bg_car-num.png'
import bg_phone from '../../../assets/img/mypage/bg_phone-num.png'
import styled from 'styled-components'

export const Option1Contents = styled.div`
  .option1 {
    width: 100%;
    display: block;
    .telecom-btn {
      margin: 0 auto 20px auto;
      width: 100%;
      height: 40px;
      border-radius: 8px;

      .skt {
        border-radius: 8px 0 0 8px;

        &.active {
          background-color: ${STYLE_GUIDE.COLOR.main01};
          color: #fff;
        }
      }

      .lgu {
        border-radius: 0 8px 8px 0;

        &.active {
          background-color: ${STYLE_GUIDE.COLOR.main01};
          color: #fff;
        }
      }

      .kt {
        &.active {
          background-color: ${STYLE_GUIDE.COLOR.main01};
          color: #fff;
        }
      }

      > button {
        border: 1px solid ${STYLE_GUIDE.COLOR.main01};
        width: 33.3%;
        height: 40px;
        background: #fff;
        font-family: GmarketSansBold, serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: ${STYLE_GUIDE.COLOR.main01};
      }
    }
  }

  .input_part {
    margin: 0 auto;
    width: 100%;
    text-align: left;

    .mypage_modal_undertext {
      &.green {
        color: ${STYLE_GUIDE.COLOR.main01};
      }

      &.option3 {
        margin-top: -15px;

        &.green {
          color: ${STYLE_GUIDE.COLOR.main01};
        }
      }

      color: red;
      display: inline-block;
      margin: 15px 0 15px 5px;
      font-size: 15px;
    }

    .submit_btn {
      cursor: pointer;
      width: 70px;
      height: 40px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: ${STYLE_GUIDE.COLOR.sub05};
      background: #eff8fb;
      border-radius: 10px;
      padding: 0;
      border: none;
      float: right;
      margin: 8px 8px 0 0;
    }

    > p {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: ${STYLE_GUIDE.COLOR.grey01};
      margin: 10px 0 10px 0;
    }

    .option3_section {
      margin: 10px 0 20px 0;
    }

    > section {
      width: 100%;
      border: 1px solid #ced6d8;
      border-radius: 10px;
      height: 56px;

      .option3_input {
        width: 230px;
      }

      .default {
        //margin-left: 20px;
      }

      > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 56px;
        text-align: right;
        color: ${STYLE_GUIDE.COLOR.grey02};
        margin-right: 8px;
        float: right;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      > input {
        width: 162px;
        border-radius: 10px;
        background: #ffffff;
        height: 54px;
        padding: 0;
        padding-left: 10px;
        border: none;
      }

      > input::placeholder {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
      }

      > input:focus {
        -webkit-appearance: none;
        margin: 0;
        outline: none;
      }
    }
  }

  .option2,
  .option4,
  .option5 {
    .option4div,
    .option5div {
      background-image: url(${bg_car});
    }

    .option2div {
      background-image: url(${bg_phone});
    }

    > div {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      background-image: url(${bg_phone});
      height: 60px;
      width: 260px;
      margin: 0 auto;
      > p {
        display: inline-block;
        width: 210px;
        margin: 0 0 0 26px;
        font-weight: 700;
        font-size: 24px;
        line-height: 58px;
        text-align: center;
        color: ${STYLE_GUIDE.COLOR.grey01};
      }
    }

    > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }
  }

  .option5 {
    > h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${STYLE_GUIDE.COLOR.warning};
      margin-bottom: 30px;
      white-space: nowrap;
    }

    > section {
      margin: 30px auto 20px auto;
      display: block;
      width: 100%;
      cursor: pointer;

      .active {
        background-color: ${STYLE_GUIDE.COLOR.sub07};

        > div {
          > span {
            color: #056d85;
          }
        }
      }

      > div {
        background: #eff8fb;
        border-radius: 8px;
        text-align: left;
        padding-left: 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        > span {
          line-height: 60px;

          > img {
            width: 40px;
            margin: 10px 20px;
          }
        }

        > div {
          display: block;

          > p {
            font-weight: 500;
            font-size: 14px;
            display: flex;
            line-height: 18px;
            color: #056d85;
            margin: 9px 0 0 0;
          }

          > span {
            color: #7fc6da;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
`
