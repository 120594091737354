// import MyPageContent from "components/6_MyPage/6_2_myPage/MyPageContent"
import LoginPage from '../../components/6_MyPage/6_1_login/LoginPage'
import useCookies from '../../hooks/useCookies.hook'
import MyPageContent from '../../components/6_MyPage/6_2_myPage/MyPageContent'
// import { useRecoilValue } from "recoil"
// import { resLoginUser } from "../../recoil/user/atom"

const MyPage = () => {
  const { getSessionCookie } = useCookies()
  // const login = useRecoilValue(resLoginUser)
  const session = getSessionCookie()
  // const user = useRecoilValue(resLoginUser)
  // console.log("session", session)
  if (!(session.PNumber !== '' && session.SessionID !== '')) {
    return <LoginPage />
  } else {
    return <MyPageContent />
  }
}

export default MyPage
