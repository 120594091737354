import styled from 'styled-components'
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {
  carchoice,
  carnum,
  fcertnum,
  fphonenum,
  fTransactionId, inputFocusState,
  modalControl,
  modalOrder,
  mypagemodalundertext,
  recarnum,
  telecomType,
  userphonenumchange,
  userphoneteltypechange
} from '../../../recoil/common/atom'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import MypageModalContent from './MypageModalContent'
import { httpData, restServer } from '../../../service/apis/api'
import { CMD_TYPE } from '../../../service/constants/common'
import { useCookies, useLoginData, useModal } from '../../../hooks'
import { resLoginUser } from '../../../recoil/user/atom'
import { useEffect } from 'react'
import { otpTimeOut } from 'recoil/otp/atom'

const MyPageModalWrap = styled.div`
  box-sizing: border-box;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${(props) => (props.color === '-1' ? '' : 'rgba(0, 0, 0, 0.7)')};
  backdrop-filter: ${(props) => (props.color === '-1' ? '' : 'blur(4px)')};
  -webkit-backdrop-filter: ${(props) => (props.color === '-1' ? '' : 'blur(4px)')};
  z-index: ${(props) => props.color};
`
const ModalInner = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    box-sizing: border-box;
    position: fixed;
    background-color: #fff;
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 410px;
    height: auto;
    max-height: 534px;
    margin: 0 auto;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    box-sizing: border-box;
    position: fixed;
    background-color: #fff;
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
    //here
    top: ${(props) => (props.color === 'true' ? '-10%' : '10%')};
    width: 320px;
    height: auto;
    //max-height: 600px;
    margin: 0 auto;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
`
const ModalContents = styled.div`
  margin: 30px;
  text-align: center;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }

  > h2 {
    //display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    font-weight: 800;
    font-size: 23px;
    line-height: 34px;
    color: ${STYLE_GUIDE.COLOR.grey01};
  }

  .terms {
    background-color: rgb(247, 247, 247);
    height: 350px;
    overflow-y: auto;
  }

  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 20px 0;
    color: ${STYLE_GUIDE.COLOR.grey02};
  }

  .bottom_btn {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;

    .apartinquiry_submit {
      margin: 0 auto;
      display: flex;

      > button {
        cursor: pointer;
        background-color: ${STYLE_GUIDE.COLOR.main01};
        border: none;
        color: #fff;
        font-family: GmarketSansBold, serif;
        border-radius: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        width: 260px;
        height: 50px;
      }
    }

    > button {
      @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 48.6%;
        height: 50px;
        border-radius: 10px;
        border: none;
        font-family: GmarketSansBold, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
      @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 48%;
        height: 50px;
        border-radius: 10px;
        border: none;
        font-family: GmarketSansBold, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }

    .leftbtn {
      cursor: pointer;
      background-color: ${STYLE_GUIDE.COLOR.sub06};
      color: ${STYLE_GUIDE.COLOR.sub05};
    }

    .rightbtn {
      cursor: pointer;
      background-color: ${STYLE_GUIDE.COLOR.main01};
      color: #fff;
    }
  }
`
const MyPageModal = (props: { title: string, desc: any, leftbtn: string, rightbtn: string, content: string }) => {
  const { title, desc, leftbtn, rightbtn, content } = props
  const setMypagemodal = useSetRecoilState(modalControl)
  const modalorderstate = useRecoilValue(modalOrder)
  const { getSessionCookie } = useCookies()
  const chagneNum = useRecoilValue(userphonenumchange)
  const changeteltype = useRecoilValue(userphoneteltypechange)
  const session = getSessionCookie()
  const addcarnum = useRecoilValue(carnum)
  const readdcarnum = useRecoilValue(recarnum)
  const teltype = useRecoilValue(telecomType)
  const fnum = useRecoilValue(fphonenum)
  const modal = useModal()
  const ftrans = useRecoilValue(fTransactionId)
  const fcert = useRecoilValue(fcertnum)
  const cartype = useRecoilValue(carchoice)
  const resetCartype = useResetRecoilState(carchoice)
  const user = useRecoilValue(resLoginUser)
  const { refetch } = useLoginData()
  const [timeLeft, setTimeLeft] = useRecoilState(otpTimeOut)
  const setUndertext = useSetRecoilState(mypagemodalundertext)
  const focusInput = useRecoilValue(inputFocusState)
  // console.log(window.innerHeight)
  useEffect(() => {
    return () => {
      setUndertext('')
      refetch()
    }
  }, [setUndertext, refetch])

  // console.log(timeReset)
  const PhoneType = navigator.userAgent.toLowerCase()
  const HandleBtn = async (option: string) => {
    if (option === 'cancel') {
      if ((content === '' && desc === '') || desc === '담당자 확인 후 답변 드리겠습니다.') {
        window.location.reload()
      }
      setMypagemodal((prev) => !prev)
    } else if (option === 'submit') {
      if (title === '추가 연락처 삭제') {
        try {
          const data = await restServer(
            httpData(CMD_TYPE.ADDITONAL_USER, {
              SessionID: session.SessionID,
              AuthType: 'delete',
              FTelecom: teltype,
              FNumber: user.FNumber,
              PNumber: user.PNumber
            })
          )
          if (data.data.Header.ErrCode === '000000') {
            setMypagemodal(false)
            await modal.alert({
              title: '추가 연락처가 삭제되었습니다.',
              contents: ''
            })
          }
          // console.log(data, "삭제")
        } catch (err) {
        }
      } else if (title === '추가 연락처 등록') {
        if (timeLeft === 0) {
          setUndertext('인증번호 발송 내역이 없습니다.')
        } else {
          try {
            const data = await restServer(
              httpData(CMD_TYPE.CERT_OTP, {
                SessionID: session.SessionID,
                AuthType: 'fadd',
                FTelecom: teltype,
                FNumber: fnum,
                PNumber: user.PNumber,
                Telecom: user.Telecom,
                TransactionID: ftrans,
                CryptoNumber: fcert,
                PCode: 'autocall_homepage',
                CallType: 'web'
              })
            )
            if (data.data.Header.ErrCode === '000000') {
              setMypagemodal(false)
              await modal.alert({
                title: '추가 연락처 등록이 완료되었습니다.',
                contents: ''
              })
            } else {
              if (data.data.Header.ErrMsg === '인증번호 오류 횟수를 3회 초과하였습니다.') {
                setUndertext('인증번호오류가 3회 발생하였습니다. 인증번호를 다시 받아주세요.')
                setTimeLeft(0)
              } else {
                setUndertext(data.data.Header.ErrMsg)
              }
            }
            // console.log(data, "test23")
          } catch (err) {
          }
        }
      } else if (title === '차량번호 추가') {
        if (addcarnum.length === 0) {
          setUndertext('올바른 차량번호 형식으로 입력해 주세요.')
        } else if (addcarnum !== readdcarnum) {
          setUndertext('차량번호가 일치하지 않습니다.')
        } else {
          try {
            const data = await restServer(
              httpData(CMD_TYPE.ADD_CAR_NUMBER, {
                SessionID: session.SessionID,
                PNumber: user.PNumber,
                CarNum: addcarnum,
                LinkNumber: user.PNumber,
                LinkTelecom: user.Telecom
              })
            )
            if (data.data.Header.ErrCode === '000000') {
              setMypagemodal(false)
              await modal.alert({
                title: '차량 번호가 추가되었습니다.',
                contents: ''
              })
            } else if (data.data.Header.ErrCode === '030003') {
              setUndertext('이미 등록된 차량입니다. 고객센터 1566-7531로 연락주시면 신속히 해결 해드리겠습니다. ')
            }
            // console.log(data, "추가 차량번호등록")
          } catch (err) {
          }
        }
      } else if (title === '차량번호 삭제') {
        try {
          const data = await restServer(
            httpData(CMD_TYPE.DELETE_CAR_NUMBER, {
              SessionID: session.SessionID,
              PNumber: user?.PNumber,
              CarNum: ((user?.CarList) != null) && user.CarList[cartype].CarNum
            })
          )
          if (data.data.Header.ErrCode === '000000') {
            setMypagemodal(false)
            resetCartype()
            await modal.alert({
              title: '차량 번호가 삭제되었습니다.',
              contents: ''
            })
          }
        } catch (err) {
        }
      } else if (title === '연락받을 번호 변경') {
        try {
          const data = await restServer(
            httpData(CMD_TYPE.CHANGE_RINKED_CAR_NUMBER, {
              SessionID: session.SessionID,
              PNumber: user.PNumber,
              CarNum: ((user?.CarList) != null) && user.CarList[cartype].CarNum,
              LinkNumber: chagneNum,
              LinkTelecom: changeteltype
            })
          )
          if (data.data.Header.ErrCode === '000000') {
            setMypagemodal(false)
            await modal.alert({
              title: '차량 호출 시 연락받을 전화번호가 변경되었습니다.',
              contents: ''
            })
          }
        } catch (err) {
        }
      }
    }
  }

  return (
    <MyPageModalWrap color={`${modalorderstate}`}>
      <ModalInner color={`${PhoneType.includes('android') && focusInput}`}>
        <ModalContents>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          {title === '개인정보 수집 및 이용안내'
            ? (
              <p className='terms' dangerouslySetInnerHTML={{ __html: desc }} />
              )
            : (
              <p dangerouslySetInnerHTML={{ __html: desc }} />
              )}
          <MypageModalContent option={content} rightFunc={async () => await HandleBtn('submit')} />
          <div className='bottom_btn'>
            {content !== ''
              ? (
                <>
                  <button className='leftbtn' onClick={async () => await HandleBtn('cancel')}>
                    {leftbtn}
                  </button>
                  <button className='rightbtn' onClick={async () => await HandleBtn('submit')}>
                    {rightbtn}
                  </button>
                </>
                )
              : (
                <div className='apartinquiry_submit'>
                  <button onClick={async () => await HandleBtn('cancel')}>확인</button>
                </div>
                )}
          </div>
        </ModalContents>
      </ModalInner>
    </MyPageModalWrap>
  )
}
export default MyPageModal
