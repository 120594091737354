import { ApartLayout } from '../../styles/Web/Apart/styled'
import { Apart4Inquiry } from '../../styles/Web/Apart/apart4inquiry_styled'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import React, { useCallback, useState } from 'react'
import check_on from '../../assets/img/apart/check_on.png'
import check_off from '../../assets/img/apart/check_off.png'
import pc_under_img from '../../assets/img/apart/apart_submit_btn_under.png'
import mo_under_img from 'assets/img/apart/mo_apart_submit_btn_under.png'
import { ReactComponent as RArrow } from '../../assets/img/form/termsDetailRightArrow.svg'
import { httpData, restServer } from '../../service/apis/api'
import { CMD_TYPE } from '../../service/constants/common'
import MyPageModal from '../6_MyPage/6_2_myPage/MyPageModal'
import { useRecoilState } from 'recoil'
import { modalControl } from '../../recoil/common/atom'
import { apartTerms } from '../../service/constants/apartTerms'

const Apart4InquirySection = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [name, setName] = useState<string>('')
  const [phonenumber, setPhonenumber] = useState('')
  const [email, setEmail] = useState('')
  const [inquirytext, setInquirytext] = useState('')
  const [termsagree, setTermsagree] = useState(false)
  const [mypagemodal, setMypagemodal] = useRecoilState(modalControl)
  const [apartmodaltype, setApartmodaltype] = useState(0)
  const [validmodaltitle, setValidmodaltitle] = useState('')
  // console.log(termsagree)
  const InquirySubmit = async () => {
    if (name === '' || phonenumber === '' || phonenumber.length < 11) {
      setMypagemodal(true)
      setApartmodaltype(2)
      setValidmodaltitle('이름 및 전화번호를<br/> 확인해 주세요.')
    } else if (!termsagree) {
      setTermsagree(true)
      setMypagemodal(true)
      setApartmodaltype(2)
      setValidmodaltitle('개인정보 수집약관에<br/> 동의해 주세요.')
    } else {
      try {
        if (executeRecaptcha != null) {
          const token = await executeRecaptcha('login')
          const data = await restServer(
            httpData(CMD_TYPE.GET_APART_INQUIRY, {
              Name: name,
              TelNumber: phonenumber,
              EMail: email,
              Content: inquirytext,
              AuthToken: token
            })
          )
          // console.log(data.data.Header.ErrCode)
          if (data.data.Header.ErrCode === '000000') {
            setApartmodaltype(0)
            setMypagemodal(true)
          }
        }
      } catch (err) {}
    }
  }
  const NameValid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regExp = /[ \\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+┼<>@\\#$%&\\'\\"\\\\(\\=0123456789]/gi
    if (regExp.test(e.target.value)) {
      e.target.value = e.target.value.substring(0, e.target.value.length - 1)
    } else {
      setName(e.target.value)
    }
  }
  const EmailValid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    if (email.match(regExp) !== null) {
      // console.log("email_check")
    } else {
      setEmail('오입력')
    }
  }

  const handleKeyDown = useCallback((event: any) => {
    if (
      event.key === '-' ||
      event.key === '+' ||
      event.key === '.' ||
      event.keyCode === 69 ||
      event.keyCode === 32 ||
      event.keyCode === 37 ||
      event.keyCode === 38 ||
      event.keyCode === 39 ||
      event.keyCode === 40
    ) { event.preventDefault() }
  }, [])

  return (
    <Apart4Inquiry>
      <ApartLayout>
        <h1>
          오토콜 도입으로
          <br /> 입주민의 개인정보를 <br /> 보호하세요.
        </h1>
        <section className='inquiry_form'>
          <div>
            <p className='point'>이름</p>
            <input
              type='text'
              maxLength={20}
              onChange={NameValid}
              onInput={(e: any) => {
                if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
              }}
            />
          </div>
          <div>
            <p className='point'>전화번호</p>
            <input
              type='number'
              onChange={(e) => setPhonenumber(e.target.value)}
              maxLength={11}
              onKeyDown={handleKeyDown}
              onInput={(e: any) => {
                if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
              }}
            />
          </div>
          <div>
            <p>이메일</p>
            <input
              type='text'
              maxLength={30}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={EmailValid}
              onInput={(e: any) => {
                if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
              }}
            />
          </div>
          <div>
            <p>
              문의내용
              <br />
              <span className='textcount'>({inquirytext.length} / 500)</span>
            </p>
            <textarea
              maxLength={500}
              className='textarea'
              placeholder='OO 아파트 도입 상담을 받고싶어요.'
              onChange={(e) => setInquirytext(e.target.value)}
            />
          </div>
          <div className='terms_agree'>
            <img
              src={!termsagree ? `${check_off}` : `${check_on}`}
              alt=''
              onClick={() => setTermsagree((prev) => !prev)}
            />
            <span>개인정보 수집 동의</span>
            <i>
              <RArrow
                onClick={() => {
                  setApartmodaltype(1)
                  setMypagemodal(true)
                }}
              />
            </i>
          </div>
          <p>문의 응대 외의 용도로는 사용되지 않습니다.</p>
          <button className='apart_submit_btn' onClick={InquirySubmit}>
            아파트 제휴 문의
          </button>
          <img className='pc_under_img' src={pc_under_img} alt='' />
          <img className='mo_under_img' src={mo_under_img} alt='' />
        </section>
      </ApartLayout>
      {mypagemodal && (
        <>
          {apartmodaltype === 0 && (
            <MyPageModal
              title='문의해 주셔서 감사합니다.'
              desc='담당자 확인 후 답변 드리겠습니다.'
              leftbtn=''
              rightbtn=''
              content=''
            />
          )}
          {apartmodaltype === 1 && (
            <MyPageModal
              title='개인정보 수집 및 이용안내'
              desc={apartTerms.USETERMS}
              leftbtn=''
              rightbtn=''
              content=''
            />
          )}
          {apartmodaltype === 2 && (
            <MyPageModal title={`${validmodaltitle}`} desc='' leftbtn='' rightbtn='' content='' />
          )}
        </>
      )}
    </Apart4Inquiry>
  )
}
export default Apart4InquirySection
