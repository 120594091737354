import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { modaltypeoption, resLoginUser } from 'recoil/user/atom'
import user_icon from '../../../assets/img/mypage/user_icon.png'
import car_icon from '../../../assets/img/mypage/car_icon.png'
import plus_icon from '../../../assets/img/mypage/plus_icon.png'
import { carchoice, modalControl } from '../../../recoil/common/atom'
import MyPageModal from './MyPageModal'

const MyPageCarInfoContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: block;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
  }
`

const UserInfoNum = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50%;
    height: 50px;
    margin: 30px 30px 30px 0;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-radius: 30px;
    width: 100%;
    height: auto;
    padding-bottom: 78px;
    border: 1px solid #dfe8ea;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 4px 14px rgba(227, 244, 248, 0.7);
  }
`
const UserInfoBtn = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50%;
    height: 50px;
    margin: 30px -10px 30px 30px;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: -73px 30px 0 30px;
  }
`

const UserIcon = styled.img`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50px;
    height: 50px;
    margin: 0 10px 0 20px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 30px 0 10px 30px;
    width: 50px;
    height: 50px;
  }
`
const UserCar = styled.img`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50px;
    margin: 0 10px 0 20px;
    height: 50px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 10px 0 10px 30px;
    width: 50px;
    height: 50px;
  }
`

const UserCarNumberPart = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: auto;
    > p {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
    > span {
      display: flex;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 30px 0 0 10px;
    width: 200px;
    height: 50px;

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
    > p {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      color: #2b3639;
    }
  }
`
const UserPNumberPart = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 154px;
    > p {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
      margin-left: -50px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 10px 0 0 10px;
    width: 200px;

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
    > p {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      color: #2b3639;
    }
  }
`
const StickerBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-right: 10px;
    display: inline-block;
    border: none;
    background: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub06}` : `${STYLE_GUIDE.COLOR.main01}`)};
    border-radius: 8px;
    width: 195px;
    height: 50px;
    font-family: GmarketSansMedium, serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    line-height: 16px;
    color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub05}` : '#FFF')};
    letter-spacing: -0.05em;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    cursor: pointer;
    width: 125px;
    height: 50px;
    background: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub06}` : `${STYLE_GUIDE.COLOR.main01}`)};
    border: none;
    color: ${(props) => (props.color ? `${STYLE_GUIDE.COLOR.sub05}` : '#FFF')};
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    font-family: GmarketSansBold, serif;
    margin-right: ${(props) => (props.color ? '8px' : '0px')};
    border-radius: 10px;
    padding: 0;
  }
`
const MyPageUserInfoCard = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    .event_card {
      width: 230px;
      height: 55px;
      float: right;
      margin: -30px 0 0 0;
    }
    width: 100%;
    height: 110px;
    border: 1px solid #dfe8ea;
    box-shadow: 0 4px 14px rgba(227, 244, 248, 0.7);
    border-radius: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    // border: 1px solid ${STYLE_GUIDE.COLOR.grey06};
    display: inline-block;
    margin-bottom: 50px;
  }
`
const MyPageUserInfoCardEmpty = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    background: #f6f8f9;
    border-radius: 20px;
    width: 100%;
    height: 110px;
    margin-bottom: 70px;
    border: none;
    > p {
      margin: 42px 10px;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }
    .contact_add_text {
      margin: 45px 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
    > img {
      width: 36px;
      height: 36px;
      display: flex;
      margin: 37px 0;
    }
    justify-content: center;
    cursor: pointer;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border: ${STYLE_GUIDE.COLOR.grey06};
    width: 100%;
    height: 240px;
    border-radius: 30px;
    display: flex;

    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    > img {
      width: 36px;
      height: 36px;
      display: flex;
      margin: 0 auto 0 auto;
    }
    > p {
      margin: 10px auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }
    .contact_add_text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0 auto;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
  }
`
const MyPageCarInfomation = () => {
  const user = useRecoilValue(resLoginUser)
  const [mypagemodal, setMypagemodal] = useRecoilState(modalControl)
  const setCartype = useSetRecoilState(carchoice)
  const [modaltype, setModaltype] = useRecoilState(modaltypeoption)

  const pNumMask = (pNumber: string): string =>
    `${pNumber?.slice(0, 3)}-${pNumber?.slice(3, 7)}-${pNumber?.slice(7, 11)}`

  const CarEvent = (option: number) => {
    setMypagemodal(true)
    setModaltype(option)
  }

  return (
    <MyPageCarInfoContainer>
      {user.CarList?.map((car, index) => (
        <MyPageUserInfoCard key={car.CarNum}>
          <UserInfoNum>
            <UserIcon src={user_icon} alt='avatar img' />
            <UserCarNumberPart>
              <span>차량번호</span>
              <p>{car.CarNum}</p>
            </UserCarNumberPart>
            <UserCar src={car_icon} alt='avatar img' />
            <UserPNumberPart>
              <span>연락받을 번호</span>
              <p>{pNumMask(car.LinkNumber)}</p>
            </UserPNumberPart>
          </UserInfoNum>
          <UserInfoBtn>
            <StickerBtn
              color='default'
              onClick={() => {
                CarEvent(4)
                setCartype(index)
              }}
            >
              차량삭제
            </StickerBtn>
            <StickerBtn
              onClick={() => {
                CarEvent(5)
                setCartype(index)
              }}
            >
              연락처변경
            </StickerBtn>
          </UserInfoBtn>
        </MyPageUserInfoCard>
      ))}
      {user.CarList === undefined && (
        <MyPageUserInfoCardEmpty onClick={() => CarEvent(3)}>
          <img src={plus_icon} alt='plus icon' />
          <p>차량번호 추가</p>
          <p className='contact_add_text'>본인 소유 차량 2대까지 등록 가능합니다.</p>
        </MyPageUserInfoCardEmpty>
      )}
      {Number(user.CarList?.length) < 2 && (
        <MyPageUserInfoCardEmpty onClick={() => CarEvent(3)}>
          <img src={plus_icon} alt='plus icon' />
          <p>차량번호 추가</p>
          <p className='contact_add_text'>본인 소유 차량 2대까지 등록 가능합니다.</p>
        </MyPageUserInfoCardEmpty>
      )}
      {mypagemodal && modaltype === 3 && (
        <MyPageModal
          title='차량번호 추가'
          desc='정상적인 호출을 위해<br/>정확하게 입력해 주세요.'
          leftbtn='취소'
          rightbtn='등록'
          content='option3'
        />
      )}
      {mypagemodal && modaltype === 4 && (
        <MyPageModal
          title='차량번호 삭제'
          desc='차량번호 삭제 후 <br/>다시 등록할 수 있습니다.'
          leftbtn='취소'
          rightbtn='삭제'
          content='option4'
        />
      )}
      {mypagemodal && modaltype === 5 && (
        <MyPageModal
          title='연락받을 번호 변경'
          desc='이 차량에 호출 요청이 오면<br/>연락받을 번호를 선택해 주세요.'
          leftbtn='취소'
          rightbtn='선택완료'
          content='option5'
        />
      )}
    </MyPageCarInfoContainer>
  )
}

export default MyPageCarInfomation
