import { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { qnaContents } from 'recoil/user/atom'
import { ServerErrMsg, ValidateType } from 'recoil/validate/atom'

interface FormItem {
  title?: string
  subTitle?: string
  currentLength?: number
  maxLength?: number
  essential?: boolean
  validate?: ValidateType
}

const AutocallFormItem = ({
  title,
  subTitle,
  currentLength,
  maxLength = 500,
  children,
  essential = false,
  validate = { valid: false, msg: '' }
}: PropsWithChildren<FormItem>) => {
  const ErrMsg = ServerErrMsg

  const isGreen = () => {
    if (Number(validate.msg) < 200) {
      return 'green'
    } else {
      return ''
    }
  }
  const value = useRecoilValue(qnaContents)
  return (
    <div className='form-item'>
      {title && (
        <div className='form-info-container' id={title}>
          <span className={`info-title ${essential ? 'active' : ''}`}>{title}</span>
          {currentLength !== undefined
            ? (
              <p className='max-length'>
                ({value.length}/{maxLength})
              </p>
              )
            : (
                ''
              )}
          <p className='info-subtitle' dangerouslySetInnerHTML={{ __html: subTitle ?? '' }} />
          {subTitle === '정상적인 호출을 위해<br/> 정확하게 입력해 주세요.' && (
            <p className='info-subtitle_mo'>정상적인 호출을 위해 정확하게 입력해 주세요.</p>
          )}
        </div>
      )}
      <div className='form-input-container'>
        {children}
        <div className='valid-text-container'>
          {validate.msg.split('인증번호를 잘못 입력 하였습니다.').length - 1 > 0 && (
            <span className={`warning-icon ${isGreen()}`} />
          )}
          {validate.msg.split('미성년자는 가입 할 수 없는 서비스입니다.').length - 1 > 0 && (
            <span className={`warning-icon ${isGreen()}`} />
          )}
          {ErrMsg[validate.msg] && <span className={`warning-icon ${isGreen()}`} />}
          <div className={`warning-text ${isGreen()}`}>{ErrMsg[validate.msg] ?? validate.msg}</div>
        </div>
      </div>
    </div>
  )
}

export default AutocallFormItem
