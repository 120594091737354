import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from 'recoil'
import { inputDisabled } from 'recoil/validate/atom'
import { otpTermsAgrees } from '../../../recoil/otp/atom'
import {mvnoData} from "../../../recoil/common/atom";
interface TelecomSelectorPropsType {
  telecom: Telecom
  setTelecom: SetterOrUpdater<Telecom>
}
const TelecomSelector = ({ telecom, setTelecom }: TelecomSelectorPropsType) => {
  const setTerms = useSetRecoilState(otpTermsAgrees)
  const setIsMvnoConfirm = useSetRecoilState(mvnoData)
  const { isDisabled } = useRecoilValue(inputDisabled('telecom'))
  const handleChange = (value: string) => {
    if (isDisabled) {
      return false
    }
    setIsMvnoConfirm(false)
    setTerms({
      Terms1Agree: 0,
      Terms2Agree: 0,
      Terms3Agree: 0,
      Terms4Agree: 0,
      Terms5Agree: 0,
      Terms6Agree: 0
    })
    switch (value) {
      case 'kt':
        setTelecom(1)
        break
      case 'lgu':
        setTelecom(2)
        break
      default:
        setTelecom(0)
    }
  }

  return (
    <>
      <form className='telecom-selector'>
        <input
          className='telecom-radio skt'
          type='radio'
          name='telecom'
          id='sk'
          value='skt'
          checked={telecom === 0}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isDisabled}
        />
        <label className='telecom-label skt' htmlFor='sk'>
          <span className='label-text'>SKT</span>
        </label>
        <input
          className='telecom-radio kt'
          type='radio'
          name='telecom'
          id='kt'
          value='kt'
          checked={telecom === 1}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isDisabled}
        />
        <label className='telecom-label kt' htmlFor='kt'>
          <span className='label-text'>KT</span>
        </label>
        <input
          className='telecom-radio lgu'
          type='radio'
          name='telecom'
          id='lgu'
          value='lgu'
          checked={telecom === 2}
          onChange={(e) => handleChange(e.target.value)}
          disabled={isDisabled}
        />
        <label className='telecom-label lgu' htmlFor='lgu'>
          <span className='label-text'>LG U+</span>
        </label>
      </form>
    </>
  )
}

export default TelecomSelector
