import dayjs from 'dayjs'
import { useGetNotices } from 'hooks'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'

import ListPagenation from '../ListPagenation'
export const NoticeListContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 0 auto 110px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-top: 1px solid ${STYLE_GUIDE.COLOR.grey01};
  }
`
export const NoticeListUl = styled.ul`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-top: 1px solid ${STYLE_GUIDE.COLOR.grey01};
    padding: 0;
    margin: 0;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    padding: 0;
    margin: 0;
  }
`
export const NoticeListLi = styled.li`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    align-items: center;
    height: 86px;
    border-bottom: 1px solid #ced6d8;
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    border-bottom: 1px solid #ced6d8;
    &:hover {
      opacity: 0.7;
    }
  }
`
export const NoticeTitle = styled(NavLink)`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.025em;
    text-align: left;
    padding-inline-start: 5px;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 210px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.025em;
    text-align: left;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
export const NoticeListLiDate = styled.span`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    padding-inline-end: 5px;
    color: #a8b1b3;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${STYLE_GUIDE.COLOR.grey03};
  }
`
const NoticeList = () => {
  const { data } = useGetNotices()
  const pageSize = 5
  const [total, setTotal] = useState(0)
  const [currentPages, setCurrentPages] = useState(5)
  const handlePageSize = () => {
    if (currentPages < total) {
      setCurrentPages((cur) => (cur += pageSize))
    }
  }
  useEffect(() => {
    if (data != null) {
      setTotal(data.length)
    }
  }, [data])
  return (
    <NoticeListContainer>
      <NoticeListUl>
        {(data != null)
          ? data.slice(0, currentPages).map((notice) => (
            <NoticeListLi key={notice.Idx}>
              <NoticeTitle to={`/noticeDetail/${notice.Idx}`}>
                {notice.Title}
              </NoticeTitle>
              <NoticeListLiDate>{dayjs(notice.RegDT).format('YYYY.MM.DD')}</NoticeListLiDate>
            </NoticeListLi>
          ))
          : ''}
      </NoticeListUl>
      <ListPagenation currentPages={currentPages} total={total} handlePageSize={handlePageSize} />
    </NoticeListContainer>
  )
}

export default NoticeList
