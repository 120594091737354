import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  carchoice,
  carnum,
  fcertnum,
  fphonenum,
  fTransactionId, inputFocusState,
  modalOrder,
  mypagemodalundertext,
  recarnum,
  telecomType,
  userphonenumchange,
  userphoneteltypechange
} from '../../../recoil/common/atom'
import { httpData, restServer } from '../../../service/apis/api'
import { CMD_TYPE } from '../../../service/constants/common'

import { resLoginUser } from '../../../recoil/user/atom'
import check_icon from 'assets/img/mypage/check_icon.png'
import { useEffect, useRef, useState } from 'react'
import { otpTimeOut } from '../../../recoil/otp/atom'
import Timer from '../../Common/Timer'
import { Option1Contents } from './ModalContent_styled'

const MypageModalContent = (props: { option: string, rightFunc: any }) => {
  const { option, rightFunc } = props
  const [teltype, setTeltype] = useRecoilState(telecomType)
  const setModalorderstate = useSetRecoilState(modalOrder)
  const [fnum, setFnum] = useRecoilState(fphonenum)
  const setFcert = useSetRecoilState(fcertnum)
  const [chagnenum, setChangeNum] = useRecoilState(userphonenumchange)
  const setChangeteltype = useSetRecoilState(userphoneteltypechange)
  const [timeLeft, setTimeLeft] = useRecoilState(otpTimeOut)
  const [carconfirm, setCarconfirm] = useRecoilState(recarnum)
  const setFtrans = useSetRecoilState(fTransactionId)
  const [addcarnum, setaddCarnum] = useRecoilState(carnum)
  const [undertext, setUndertext] = useRecoilState(mypagemodalundertext)
  const focusinputsizeup = useRef(null)
  const cartype = useRecoilValue(carchoice)
  const setFocusInput = useSetRecoilState(inputFocusState)
  const pNumMask = (pNumber: any): any => `${pNumber?.slice(0, 3)}-${pNumber?.slice(3, 7)}-${pNumber?.slice(7, 11)}`
  const user = useRecoilValue(resLoginUser)
  const TelChoice = (element: number) => {
    setTeltype(element)
  }
  const [choicephoneNum, setChociephoneNum] = useState(
    !!((user.CarList != null) && user?.CarList[cartype].LinkNumber === user?.PNumber)
  )
  const onInputClick = (e: any) => {
    e.preventDefault()
  }
  // console.log("user", user)
  useEffect(() => {
    setCarconfirm('')
    setaddCarnum('')
    ;(user.CarList != null) && setChangeNum(`${user.CarList[cartype].LinkNumber}`)
    return () => {
      setTimeLeft(0)
      setFnum('')
      setTeltype(0)
    }
  }, [cartype, setCarconfirm, setChangeNum, setFnum, setTeltype, setTimeLeft, setaddCarnum, user.CarList])
  const AddSubmit = async () => {
    if (fnum.length < 11) {
      setTimeLeft(0)
      setUndertext('전화 번호 및 통신사를 다시 확인해 주세요.')
    } else {
      setModalorderstate(-1)
      setModalorderstate(10)
      if (fnum.length > 10) {
        try {
          const data = await restServer(
            httpData(CMD_TYPE.SEND_OTP, {
              Telecom: user.Telecom,
              PNumber: user.PNumber,
              AuthType: 'fadd',
              PCode: 'autocall_homepage',
              CallType: 'web',
              FTelecom: teltype,
              FNumber: fnum
            })
          )
          if (data.data.Header.ErrCode === '030021') {
            setUndertext('전화번호 및 통신사를 다시 확인해 주세요.')
            setTimeLeft(0)
          } else if (data.data.Header.ErrCode === '030018') {
            setUndertext('가입자 번호와 동일한 번호를 추가 등록할 수 없습니다.')
            setTimeLeft(0)
          } else if (data.data.Header.ErrCode === '030005') {
            setUndertext('3분 내 동일번호 발송 내역이 있습니다. 잠시 후 다시 시도해 주세요.')
          } else {
            setTimeLeft(0)
            setTimeout(() => {
              setUndertext('인증번호가 전송되었습니다.')
              setTimeLeft(data.data.Body.TimeOut)
              setFtrans(data.data.Body.TransactionID)
            }, 50)
          }
        } catch (err) {}
      }
    }
  }
  const regex = /^[0-9]{2,3}[가-힣]{1}[0-9]{4}$|^[가-힣]{2,3}[0-9]{1,2}[가-힣]{1}[0-9]{4}$|^[가-힣]{2}[0-9]{6}$/
  const carvalid = (option: string) => {
    if (regex.test(option)) {
      setUndertext('')
      setaddCarnum(option)
    } else {
      if (option.length !== 0) setUndertext('올바른 차량번호 형식으로 입력해 주세요.')
    }
  }
  const onBlurcarVaild = (option: string) => {
    if (carconfirm.length !== 0 && carconfirm === addcarnum) {
      setUndertext('차량번호 입력이 완료되었습니다.')
    } else {
      if (!regex.test(option)) {
        setUndertext('올바른 차량번호 형식으로 입력해 주세요.')
      } else {
        if (carconfirm.length !== 0) setUndertext('차량번호가 일치하지 않습니다.')
      }
    }
  }
  return (
    <>
      {/* 추가연락처등록 */}
      <Option1Contents>
        {option === 'option1' && (
          <div className='option1'>
            <div className='telecom-btn'>
              <button className={`skt ${teltype === 0 && 'active'}`} onClick={() => TelChoice(0)}>
                SKT{}
              </button>
              <button className={`kt ${teltype === 1 && 'active'}`} onClick={() => TelChoice(1)}>
                KT
              </button>
              <button className={`lgu ${teltype === 2 && 'active'}`} onClick={() => TelChoice(2)}>
                LG U+
              </button>
            </div>
            <div className='input_part'>
              <p>휴대폰 번호</p>
              <section>
                <input
                  type='number'
                  placeholder='숫자만 입력하세요.'
                  onChange={(e) => setFnum(e.target.value)}
                  maxLength={11}
                  onInput={(e: any) => {
                    if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
                  }}
                  onKeyUp={(e) => {
                    e.key === 'Enter' && AddSubmit()
                  }}

                />
                <button className='submit_btn' onClick={AddSubmit}>
                  인증번호
                </button>
              </section>
            </div>
            <div className='input_part cert'>
              <p>인증번호</p>
              <section>
                <input
                  onClick={onInputClick}
                  ref={focusinputsizeup}
                  type='number'
                  placeholder='인증번호 4자리 입력'
                  onChange={(e) => setFcert(e.target.value)}
                  maxLength={4}
                  onInput={(e: any) => {
                    if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
                  }}
                  onKeyUp={(e) => {
                    e.key === 'Enter' && rightFunc()
                  }}
                  onBlur={() => setFocusInput(false)}
                  onFocus={() => { setFocusInput(true) }}
                />
                {timeLeft > 0 ? <Timer /> : <span className='default'>03:00</span>}
              </section>
              <span
                dangerouslySetInnerHTML={{ __html: undertext }}
                className={`mypage_modal_undertext ${undertext === '인증번호가 전송되었습니다.' && 'green'}`}
              />
            </div>
          </div>
        )}
        {option === 'option2' && (
          <div className='option2'>
            <div className='option2div'>
              <p>{pNumMask(user.FNumber)}</p>
            </div>
            <p>등록된 연락처를 삭제하시겠어요?</p>
          </div>
        )}
        {option === 'option3' && (
          <div className='option3'>
            <div className='input_part'>
              <section className='option3_section'>
                <input
                  className='option3_input'
                  type='text'
                  placeholder='예) 123가1234, 서울12가3456'
                  onChange={(e) => carvalid(e.target.value)}
                  onBlur={(e) => carvalid(e.target.value)}
                  maxLength={9}
                />
              </section>
            </div>
            <div className='input_part'>
              <section className='option3_section'>
                <input
                  className='option3_input'
                  type='text'
                  placeholder='차량번호 재입력'
                  onChange={(e) => setCarconfirm(e.target.value)}
                  onBlur={(e) => onBlurcarVaild(e.target.value)}
                  maxLength={9}
                  onKeyUp={(e) => {
                    e.key === 'Enter' && rightFunc()
                  }}
                />
              </section>
              <span
                className={`mypage_modal_undertext option3 ${
                  undertext === '차량번호 입력이 완료되었습니다.' && 'green'
                }`}
              >
                {undertext}
              </span>
            </div>
          </div>
        )}
        {option === 'option4' && (
          <div className='option4'>
            <div className='option4div'>{((user?.CarList) != null) && <p>{user.CarList[cartype].CarNum}</p>}</div>
            <p>등록된 차량번호를 삭제하시겠어요?</p>
          </div>
        )}
        {option === 'option5' && (
          <div className='option5'>
            <div className='option5div'>{((user?.CarList) != null) && <p>{user.CarList[cartype].CarNum}</p>}</div>
            <section>
              <div
                className={`${choicephoneNum && 'active'}`}
                onClick={() => {
                  (user.CarList != null) && setChangeteltype(user.CarList[cartype].LinkTelecom)
                  ;(user.CarList != null) && setChangeNum(user?.PNumber)
                  setChociephoneNum(true)
                }}
              >
                <div>
                  <p>가입번호</p>
                  <span>{user?.PNumber}</span>
                </div>
                {chagnenum === user?.PNumber && (
                  <span>
                    <img src={check_icon} alt='check' />
                  </span>
                )}
              </div>
              {user?.FNumber && (
                <div
                  className={`${!choicephoneNum && 'active'}`}
                  onClick={() => {
                    user.FNumber && setChangeNum(user.FNumber)
                    user.FTelecom && setChangeteltype(user.FTelecom)
                    setChociephoneNum(false)
                  }}
                >
                  <div>
                    <p>추가연락처</p>
                    {((user?.CarList) != null) && <span>{user?.FNumber}</span>}
                  </div>
                  {chagnenum === user?.FNumber && (
                    <span>
                      <img src={check_icon} alt='check' />
                    </span>
                  )}
                </div>
              )}
            </section>
            <h3>
              선택하신 번호로 연락이 3회 실패 할 경우, <br />
              저장된 다른 번호로 연락드립니다.
            </h3>
          </div>
        )}
      </Option1Contents>
    </>
  )
}
export default MypageModalContent
