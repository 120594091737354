import styled from 'styled-components'
import STYLE_GUIDE from '../../service/constants/styleGuide'

export const FooterContainer = styled.footer`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    height: 320px;
    color: ${STYLE_GUIDE.COLOR.main01};
    background: ${STYLE_GUIDE.COLOR.footer};
    .footer_wrap {
      display: flex;
      width: ${STYLE_GUIDE.SIZE.maxMainPageSize};
      margin: 0 auto;
    }
    .logo-section {
      display: block;
      width: 50%;
      height: 320px;
      align-self: center;
      > img {
        margin: 80px 0 25px 0;
        width: 145px;
      }
      .footer_link {
        color: ${STYLE_GUIDE.COLOR.footer3};
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        margin: 0 0 50px 0;
        > a {
          margin-right: 24px;
        }
      }
      > span {
        color: ${STYLE_GUIDE.COLOR.footer4};
        font-size: 16px;
        line-height: 22px;
      }
    }
    .cs-section {
      width: 50%;
      height: 320px;
      background: ${STYLE_GUIDE.COLOR.footer2};
      color: ${STYLE_GUIDE.COLOR.footer4};
      > p {
        color: ${STYLE_GUIDE.COLOR.footer3};
        font-weight: 700;
        font-size: 40px;
        line-height: 30px;
        margin: 80px 0 26px 40px;
      }
      .text1 {
        margin-left: 40px;
        font-size: 18px;
        line-height: 30px;
      }
      .text2 {
        color: ${STYLE_GUIDE.COLOR.footer4};
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 50px 0 56px 40px;
      }
    }
  }
  //모바일
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: none;
  }
`

export const FooterContainerMobile = styled.footer`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: none;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-top: 90px;
    background: ${STYLE_GUIDE.COLOR.footer};
    width: 100%;
    height: 420px;
    color: #878798;
    padding-top: 20px;
    display: block;
    margin-bottom: 60px;
    > div {
      margin: 0 20px;
      > p {
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 3px;
        &:before {
          content: "고객센터";
          font-size: 16px;
          line-height: 28px;
          color: #878798;
          font-weight: 700;
          padding-right: 10px;
        }
      }
      > span {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #878798;
      }
    }
    > section {
      color: #878798;
      margin: 30px 20px 0 20px;
      display: flex;
      > p {
        > a {
          color: #878798;
        }
        margin: 0;
        width: 97px;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
      }
      > div {
        width: 200px;
        margin-left: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
       > span {
        > img {
           margin: 50px 0 10px 20px ;
           width: 106px;
           display: flex;
       }
         &:after{
           content: "Copyright ⓒ Datauniverse. All rights reserved.";
           margin-left: 20px;
           font-weight: 400;
           font-size: 12px;
           line-height: 24px;
         }
      }
    }
  }
`
