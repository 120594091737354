import { Main3Desc } from '../../../styles/Web/Main/main3desc_styled'
import pc_carimg from '../../../assets/img/mainbanner/pc_main_service_car.gif'
import mobile_carimg from '../../../assets/img/mainbanner/mo_main_service_car.gif'
const MainService1Section = () => {
  return (
    <>
      <Main3Desc>
        <p className='section_title'>우리나라에만 있는 독특한 주차문화</p>
        <div className='section_desc service1_pc'>
          이제 주차 연락 시
          <br />
          <span className='underline'>전화번호 노출</span> 걱정 끝!
        </div>
        <div className='section_desc service1_mo'>
          이제 주차 연락 시
          <br />
          <span className='underline'>전화번호 노출</span> <br />
          걱정 끝!
        </div>
        <img className='pc-car' src={pc_carimg} alt='car_img' />
        <img className='mobile-car' src={mobile_carimg} alt='car_img' />
      </Main3Desc>
    </>
  )
}
export default MainService1Section
