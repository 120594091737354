import { Header, Footer, FloatingBtn, Loading, Modal } from 'components'
// import HomePopup from "components/1_Home/Home_components/HomePopup"
import { Outlet, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { isLoading } from 'recoil/common/atom'
import { Apart } from '../views'

const Layout = () => {
  const visible = useRecoilValue(isLoading)
  const { pathname } = useLocation()
  return (
    <>
      {pathname.indexOf('b2b') > 0 ? (
        <>
          <Apart />
        </>
      ) : (
        <div className='base-layout'>
          <Loading visible={visible} />
          {/* <HomePopup /> */}
          <Modal />
          <Header />
          <FloatingBtn />
          <Outlet />
          <Footer />
        </div>
      )}
    </>
  )
}
export default Layout
