import { ModalProps } from 'components/Common/commonComponent'
import { atom } from 'recoil'
import { APIs } from 'service'
import { CMD_TYPE } from 'service/constants/common'

export const isLoading = atom<boolean>({
  key: 'isLoading',
  default: false
})
export const RecapAuthToken = atom({
  key:'RecapAuthToken',
  default: ''
})
export const RefreshRecapAuthToken = atom({
  key:'RefreshRecapAuthToken',
  default: false
})
export const modal = atom<ModalProps>({
  key: 'modalProps',
  default: {
    visible: false,
    title: '모달',
    contents: '컨텐츠',
    onCancel: () => {},
    onConfirm: () => {},
    type: 'alert'
  }
})
export const homeData = atom<ResponseHomeBody | undefined>({
  key: 'homePopupData',
  default: APIs.api.requestSRS<ResponseHomeBody>(CMD_TYPE.GET_HOME_DATA, {})
})
export const homePopup = atom<boolean>({
  key: 'homePopup',
  default: true
})
export const timeOut = atom<number>({
  key: 'timeOut',
  default: 180
})

// 마이페이지
export const modalControl = atom<boolean>({
  key: 'modalControl',
  default: false
})

export const telecomType = atom<number>({
  key: 'telecomType',
  default: 0
})
export const modalOrder = atom<number>({
  key: 'modalOrder',
  default: 10
})
export const fphonenum = atom<string>({
  key: 'fphonenum',
  default: ''
})
export const fcertnum = atom<string>({
  key: 'fcertnum',
  default: ''
})
export const ftimeout = atom<number>({
  key: 'ftimeout',
  default: 0
})
export const fTransactionId = atom<string>({
  key: 'fTransactionId',
  default: ''
})

export const carnum = atom<string>({
  key: 'carnum',
  default: ''
})
export const recarnum = atom<string>({
  key: 'recarnum',
  default: ''
})
export const carchoice = atom<number>({
  key: 'carchoice',
  default: 0
})
export const userphonenumchange = atom<string>({
  key: 'userphonenumchange',
  default: ''
})
export const userphoneteltypechange = atom<number>({
  key: 'userphoneteltypechange',
  default: 0
})
export const usagetoggle = atom<number>({
  key: 'usagetoggle',
  default: 0
})
export const mypagemodalundertext = atom<string>({
  key: 'mypagemodalundertext',
  default: ''
})
export const cancelerrTxtActive = atom<boolean>({
  key: 'cancelerrTxtActive',
  default: false
})
export const ErrtimerReset = atom<boolean>({
  key: 'ErrtimerRest',
  default: false
})
export const mofloatState = atom<boolean>({
  key: 'mofloatState',
  default: false
})
export const CertCompleteState = atom<boolean>({
  key: 'CertCompleteState',
  default: false
})
export const popupopen = atom({
  key: 'popupopen',
  default: true
})

export const inputFocusState = atom({
  key: 'inputFocusState',
  default: false
})
export const noticeDetailData = atom<Notice>({
  key: 'noticeDetailData',
  default: {
    Idx: 0,
    Title: '타이틀',
    Contents: '내용',
    ContentsType: 0,
    /**
     * 시작 시간
     */
    StartTime: '2023-09-18 16:00',
    /**
     * 종료 시간
     */
    EndTime: '2023-09-18 18:00',
    EventType: 0,
    /**
     * 등록일 ex) "YYYY-MM-DD HH:mm:ss"
     */
    RegDT: '2023-09-18 10:00',
    /**
     * 등록일 ex) "YYYY-MM-DD"
     */
    RegDTString: '2023-09-18'
  }
})

export const mvnoData = atom<boolean>({
  key: 'mvnoData',
  default: false
})