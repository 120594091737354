import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'
import apart_step_bg from '../../../assets/img/apart/apart_step_bg.png'
import mo_step_bg from '../../../assets/img/apart/mo_step-bg.png'
export const Apart3Step = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      margin: 0 auto;
      width: 100%;
      height: 460px;
      background-position: 45% 50%;
      background-image: url(${apart_step_bg});
      background-repeat: no-repeat;
      background-size: cover;
      > div {
        .title {
          > h1 {
            font-family: GmarketSansBold, serif;
            font-weight: 500;
            font-size: 50px;
            color: #131b59;
            line-height: 62px;
            padding: 101px 0 3px 0;
            &:after {
              content: "이렇게 진행됩니다.";
              color: #fff;
            }
          }
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      width: 340px;
      background: none;
      > div {
        .title {
          height: 200px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 45% 50%;
          background-image: url(${mo_step_bg});
          > h1 {
            width: 320px;
            margin: 0 auto;
            padding: 40px 0 20px 0;
            font-family: GmarketSansBold, serif;
            font-weight: 500;
            font-size: 30px;
            line-height: 38px;
            color: #131b59;
            &:after {
              content: "이렇게 진행됩니다.";
              color: #fff;
            }
          }
        }
      }
    }
  }
`

export const Apart3StepContents = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      height: 340px;
      display: flex;
      justify-content: space-between;
      > section {
        display: block;
        width: 260px;
        height: 100%;
        box-shadow: 0 4px 20px rgba(16, 168, 202, 0.15);
        border-radius: 40px;
        background-color: #fff;
        > div {
          text-align: center;
          width: 180px;
          height: 50%;
          margin: 0 auto;
          > img {
            margin: 56px 40px 0 40px;
            width: 100px;
            height: 100px;
          }
          > span {
            display: flex;
            > span {
              width: 180px;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              color: #131b59;
              font-family: GmarketSansBold, serif;
              margin: 0 50px 0 25px;
            }
            .arrow_hide {
              visibility: hidden;
            }
            > img {
              width: 50px;
              height: 50px;
              margin-right: -75px;
              z-index: 1;
            }
          }
          > p {
            margin: 10px auto;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: ${STYLE_GUIDE.COLOR.grey01};
          }
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      display: block;
      width: 360px;
      margin: -60px auto 70px auto;

      > section {
        width: 320px;
        height: 120px;
        box-shadow: 0 4px 20px rgba(16, 168, 202, 0.15);
        border-radius: 40px;
        background-color: #fff;
        margin: 0 auto 20px auto;
        display: flex;
        > div {
          text-align: center;
          > img {
            margin: 30px 0 30px 24px;
            width: 60px;
            height: 60px;
          }
          > span {
            display: flex;
            > span {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #131b59;
              font-family: GmarketSansBold, serif;
              margin: 24px 0 0 24px;
            }
            > img {
              position: absolute;
              width: 36px;
              height: 36px;
              transform: rotate(90deg);
              margin: 110px 0 0 60px;
              z-index: 1;
            }
          }
          > p {
            margin: 6px 0 24px 24px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: ${STYLE_GUIDE.COLOR.grey01};
          }
        }
      }
    }
  }
`
