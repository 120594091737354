import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { noticeDetailData } from '../../../recoil/common/atom'
import { useGetNotices } from '../../../hooks'
import { useEffect } from 'react'

export const NoticeDatailDiv = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
`
export const NoticeTitleContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: auto 0;
  }
`
export const NoticeTitle = styled.h1`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    color: ${STYLE_GUIDE.COLOR.grey01};
    font-weight: 800;
    font-size: 26px;
    line-height: 28px;
    letter-spacing: -0.025em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    margin: 0;
    align-items: center;
    color: ${STYLE_GUIDE.COLOR.grey01};
    letter-spacing: -0.05em;
    text-align: left;
  }
`
export const NoticeDate = styled.h3`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin: 0 0 30px;
    letter-spacing: -0.025em;
    text-align: left;
    color: ${STYLE_GUIDE.COLOR.grey02};
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    margin: 20px 0;
    align-items: center;
    color: #A8B1B3;

  }
`
export const NoticeContent = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    color: ${STYLE_GUIDE.COLOR.grey01};
    border-top: 1px solid #ced6d8;
    border-bottom: 1px solid #ced6d8;
    padding: 40px 0;
    letter-spacing: -0.025em;
    text-align: left;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-top: 1px solid ${STYLE_GUIDE.COLOR.grey04};
    border-bottom: 1px solid ${STYLE_GUIDE.COLOR.grey04};
    padding: 30px 0;
    margin: 20px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${STYLE_GUIDE.COLOR.grey01};

    letter-spacing: -0.025em;
    text-align: left;
  }
`
export const NoticeFooter = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: fit-content;
    margin: 40px auto;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: fit-content;
    margin: 40px auto;
  }
`
export const NoticeListBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    > p {
      margin: 0;
      color: ${STYLE_GUIDE.COLOR.grey01}
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 10px 23px;
    }

    border: 1px solid #ced6d8;
    border-radius: 100px;
    width: fit-content;
    background: none;
    letter-spacing: -0.025em;
    text-align: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 88px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #CED6D8;
    border-radius: 100px;
    letter-spacing: -0.025em;
    > p {
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 44px;
      display: inline-block;
      margin: 0;
      align-items: center;
      color: black;

    }

    &:hover {
      opacity: 0.7;
    }
  }
`

const NoticeDetail = () => {
  const navigate = useNavigate()
  const [noticeData, setNoticeData ] = useRecoilState(noticeDetailData)
  const { data, isSuccess } = useGetNotices()
  const { detailIdx } = useParams()
  useEffect(() => {
    console.log(detailIdx)
    if (data) {
      setNoticeData(data.find((item) => item.Idx === Number(detailIdx)) ?? noticeData)
    }
    // eslint-disable-next-line
  }, [isSuccess])
  const handleListBtnClick = () => {
    navigate('/notice')
  }

  const replaceHTML = (str: string) => str.replaceAll('\n', '<br />')
  return (
    <NoticeDatailDiv>
      <NoticeTitleContainer>
        <NoticeTitle>{noticeData.Title}</NoticeTitle>
        <NoticeDate>{dayjs(noticeData.RegDT).format('YYYY.MM.DD')}</NoticeDate>
      </NoticeTitleContainer>
      <NoticeContent dangerouslySetInnerHTML={{ __html: replaceHTML(noticeData.Contents) }} />
      <NoticeFooter>
        <NoticeListBtn onClick={handleListBtnClick}>
          <p>목록</p>
        </NoticeListBtn>
      </NoticeFooter>
    </NoticeDatailDiv>
  )
}

export default NoticeDetail
