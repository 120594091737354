import styled from "styled-components"

import icoCheck from 'assets/img/form/ico_check.png'
import icoCheckOff from 'assets/img/form/ico_check_off.png'

export const MVNOTitle = styled.div`
  .description {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
    line-height: 34px;
    text-align: center;
    margin-top: 0;
  }
`

export const MVNOContents = styled.div`
  border-radius: 10px;
  height: 136px;
  overflow: hidden;
  border: 1px solid #b3b3b3;
  margin-bottom: 20px;
  display: flex;
        
    .terms-title {
        display: inline-block;
        width: 71px;
        height: 100%;
        margin: 29px 20px 0 20px;
        background: url('${icoCheckOff}') no-repeat center 55px;
        background-size: 24px 24px;
    }
    
    input[type="checkbox"]:checked + .terms-title {
        background: url('${icoCheck}') no-repeat center 55px;
        background-size: 24px 24px;
    }
    
    input[type="checkbox"] {
        display: none;
    }

  .terms-contents {
    text-align: left;
    padding: 12px;
    overflow-y: scroll;
    background: #fafafa;
  }
`
