import { Apart3Step } from '../../styles/Web/Apart/apart3step_styled'
import { ApartLayout } from '../../styles/Web/Apart/styled'
import ApartStepCard from './Apart3StepSection/Apart3StepCard'
const Apart3StepSection = () => {
  return (
    <Apart3Step>
      <ApartLayout>
        <div className='title'>
          <h1>
            오토콜 도입 문의 후, <br />
          </h1>
        </div>
        <ApartStepCard />
      </ApartLayout>
    </Apart3Step>
  )
}
export default Apart3StepSection
