import { useGetFAQs } from 'hooks'
import { useEffect, useState } from 'react'
import { FAQ_GROUP, FAQ_GROUP_NAME } from 'service/constants/common'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import ContentHeader from '../ContentHeader'
import underArrow from 'assets/img/support/faq_arrow.png'
import upArrow from 'assets/img/support/faq_arrow_active.png'
import ListPagenation from '../ListPagenation'
const FAQUl = styled.ul`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
`
const FAQLi = styled.li`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-bottom: 01px solid ${STYLE_GUIDE.COLOR.grey02};
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-bottom: 1px solid ${STYLE_GUIDE.COLOR.grey02};
  }
`
const FAQTitleHeader = styled.div`
  display: flex;
  align-items: center;
  .question {
    color: ${STYLE_GUIDE.COLOR.main01};
    font-weight: 700;
    font-size: 18px;
    line-height: 78px;
    margin-right: 30px;
    font-family: GmarketSansMedium, serif;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .question {
      color: ${STYLE_GUIDE.COLOR.main01};
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
      font-family: GmarketSansMedium, serif;
      margin-right: 12px;
    }
  }
`
const FAQTitle = styled.p<{ style_chagne: boolean }>`
  cursor: pointer;
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    cursor: pointer;
    font-weight: 700;
    margin: 0;
    font-size: 18px;
    line-height: 78px;
    letter-spacing: -0.025em;
    text-align: left;
    padding-inline-start: 0;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    margin-right: 25px;
    font-weight: 700;
    font-size: 18px;
    line-height: 40px;
    color: black;
    letter-spacing: -0.025em;
    text-align: left;
    overflow: ${(props) => (props.style_chagne ? 'unset' : 'hidden')};
    text-overflow: ${(props) => (props.style_chagne ? 'unset' : 'ellipsis')};
    white-space: ${(props) => (props.style_chagne ? 'unset' : 'nowrap')};
    &:hover {
      opacity: 0.7;
    }
  }
`
const Arrow = styled.img`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-right: 20px;
    width: 17.61px;
    height: 10px;
    transition: 0.5s;
    cursor: pointer;
    &.active {
      transition: 0.5s;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-right: 10px;
    width: 17.61px;
    height: 10px;
    transition: 0.5s;
    &.active {
      transition: 0.5s;
    }
  }
`
const FAQContent = styled.section`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    &:before {
      content: "A";
      margin: 10px 0 0 -50px;
      font-weight: 400;
      padding-right: 30px;
      font-size: 20px;
      color: black;
      line-height: 28px;
      font-family: GmarketSansMedium, serif;
    }
    padding: 26px 101px;
    display: none;
    background-color: #f7fdfe;
    transition: 0.5s;
    color: ${STYLE_GUIDE.COLOR.grey02};
    &.active {
      display: block;
      transition: 0.5s;
    }
    > a {
      color: ${STYLE_GUIDE.COLOR.grey02};
      word-wrap: break-word;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    &:before {
      content: "A";
      margin: 0 0 6px 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: ${STYLE_GUIDE.COLOR.grey01};
      padding-right: 30px;
      font-family: GmarketSansMedium, serif;
    }
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${STYLE_GUIDE.COLOR.grey02};
    padding: 24px 30px;
    display: none;
    background-color: #f7fdfe;
    transition: 0.5s;
    > a {
      color: ${STYLE_GUIDE.COLOR.grey02};
      word-wrap: break-word;
    }
    &.active {
      display: block;
      transition: 0.5s;
    }
  }
`
const FAQContainer = styled.div`
  margin: 0 auto 100px;
`

const FAQList = () => {
  const [group, setGroup] = useState<GroupType>(FAQ_GROUP.COMMON)
  const { data, refetch } = useGetFAQs(group)
  const [open, setOpen] = useState<number>(-1)
  const handleClick = (group: GroupType) => {
    setGroup(group)
    setCurrentPages(5)
    setOpen(-1)
  }
  const replaceHTML = (str: string) => str.replaceAll('\r', '<br />')
  const handleFAQListClick = (index: number) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  const pageSize = 5
  const [currentPages, setCurrentPages] = useState(5)
  const [total, setTotal] = useState(0)
  const handlePageSize = () => {
    if (currentPages < total) {
      setCurrentPages((cur) => (cur += pageSize))
    }
  }
  useEffect(() => {
    refetch()
    if (data != null) {
      setTotal(data.length)
    }
  }, [data, group, refetch])
  return (
    <FAQContainer>
      <ContentHeader>
        <div className='faq-nav'>
          {Object.keys(FAQ_GROUP).map((key) => (
            <button
              className={`nav-item ${FAQ_GROUP[key] === group ? 'active' : ''}`}
              key={key}
              onClick={() => handleClick(FAQ_GROUP[key])}
            >
              {FAQ_GROUP_NAME[key]}
            </button>
          ))}
        </div>
      </ContentHeader>
      <FAQUl>
        {(data != null)
          ? data.slice(0, currentPages).map((faq, index) => (
            <FAQLi key={faq.Idx}>
              <FAQTitleHeader
                onClick={() => {
                  handleFAQListClick(index)
                }}
              >
                <span className='question'>Q</span>
                <FAQTitle style_chagne={open === index}>{faq.Title}</FAQTitle>
                <Arrow
                  className={open === index ? 'active' : ''}
                  src={open === index ? upArrow : underArrow}
                  alt='underArrow'
                />
              </FAQTitleHeader>
              <FAQContent
                className={open === index ? 'active' : ''}
                dangerouslySetInnerHTML={{ __html: replaceHTML(faq.Contents) }}
              />
            </FAQLi>
          ))
          : '데이터 없음'}
      </FAQUl>
      <ListPagenation currentPages={currentPages} total={total} handlePageSize={handlePageSize} />
    </FAQContainer>
  )
}

export default FAQList
