import { Main1Top } from '../../styles/Web/Main/main1top_styled'
import { Main1TopLayout } from '../../styles/Web/Main/styled'
import background_mo from '../../assets/img/mainbanner/background_mo_mp4.gif'
const Main1TopSection = () => {
  return (
    <Main1Top>
      <Main1TopLayout>
        <img className='banner-video-mo' src={background_mo} alt='background mp4 banner'/>
        <div className='banner-top'>
          <div className='banner_contents'>
            <span className='first-line'>자동차에 전화번호 남기지 마세요</span>
            <span className='second-line'>오토콜만 남기세요</span>
            {/*<img src={autocallLogo} alt='autocall logo img' />*/}
          </div>
          <div className='app_store'>
            <a
              href='https://play.google.com/store/apps/details?id=kr.co.datau.ai.autocall&hl=ko'
              target='_blank'
              rel='noreferrer'
            >
              <span className='googleplay'/>
            </a>
            {/* <Link to={"/"}>
                <span className={"appstore"}></span>
              </Link> */}
          </div>
        </div>
      </Main1TopLayout>
    </Main1Top>
  )
}

export default Main1TopSection
