import { selector } from 'recoil'
import { qnaAgreeQNA, qnaContents, qnaContentsType, qnaEMail, qnaPNumber, qnaTitle, qnaUName } from './atom'

export const withQNAForm = selector<RequestRegistQuestionBody>({
  key: 'withQNAForm',
  get: ({ get }) => {
    return {
      ContentsType: get(qnaContentsType),
      UName: get(qnaUName),
      PNumber: get(qnaPNumber),
      EMail: get(qnaEMail),
      Title: get(qnaTitle),
      Contents: get(qnaContents),
      AgreeQNA: get(qnaAgreeQNA)
    }
  }
})
