import {Main3Desc} from '../../../styles/Web/Main/main3desc_styled'
import insurance_img from '../../../assets/img/mainbanner/insurance_mp4.gif'

const MainInsuranceSection = () => {
  return (
    <Main3Desc>
      <div className='main-service_content_insurance serviceInsurance'>
        <section>
          <div className="section_desc_insurance serviceInsurance">
            <div className='pc-insurance-main-text'>
              <p><span className="underline">전화번호 노출</span>로 인한 피싱 피해,<br/>피싱해킹보상보험으로 보상</p>
            </div>
            <div className="mo-insurance-main-text">
              <p><span className="underline">전화번호 노출</span>로 인한<br/>피싱 피해, 피싱해킹<br/>보상보험으로 보상</p>
            </div>
          </div>
          <div className="pc-insurance-sub-text">
            <p>차량 위에 올려둔 내 전화번호로 인해 피싱 피해가 발생했나요?<br/>오토콜에서 보상해드립니다.</p>
            <span>피싱 사기 피해(보이스피싱, 스미싱 등)가 발생한 경우 사고 입증 자료(경찰신고서 등)를 제출하시면 서비스 가입자 1인 1사고 당 보험한도액
              *70만 원 내 보상받을 수 있습니다.
              <br/><br/>
              *자기부담금 별도(14만 원), 증권상 최대 보상한도액 초과 시 지급 불가</span>
          </div>
          <div className='mo-insurance-sub-text'>
            <p>차량 위에 올려둔 내 전화번호로 인해 피<br/>싱 피해가 발생했나요?오토콜에서 보상<br/>해드립니다.</p>
            <span>피싱 사기 피해(보이스피싱, 스미싱 등)가 발생<br/>한 경우 사고 입증 자료(경찰신고서 등)를 제출<br/>하시면
              서비스 가입자 1인 1사고 당 보험한도액<br/>
              *70만 원 내 보상받을 수 있습니다.
              <br/><br/>
              *자기부담금 별도(14만 원), 증권상 최대 보상<br/>한도액 초과 시 지급 불가</span>
          </div>
        </section>
        <section>
          <img src={insurance_img} alt='insurance banner mp4'/>
        </section>
      </div>
    </Main3Desc>
  )
}

export default MainInsuranceSection
