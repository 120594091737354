import { atom } from 'recoil'

export const otpTelecom = atom<Telecom>({
  key: 'otpTelecom',
  default: 0
})
export const otpPNumber = atom<string>({
  key: 'otpPNumber',
  default: ''
})
export const otpAuthType = atom<AuthType>({
  key: 'otpAuthType',
  default: 'join_ready'
})
export const otpCarNumber = atom<string>({
  key: 'otpCarNumber',
  default: ''
})
export const otpFTelecom = atom<Telecom>({
  key: 'otpFTelecom',
  default: 0
})
export const otpFNumber = atom<string>({
  key: 'otpFNumber',
  default: ''
})
export const otpCallType = atom<CallType>({
  key: 'otpCallType',
  default: 'WEB'
})
export const otpAuthToken = atom<string>({
  key: 'otpAuthToken',
  default: ''
})
export const otpjoinready = atom({
  key: 'otpjoinready',
  default: false
})
export const resSendOTP = atom<ResponseSendOTPBody>({
  key: 'resSendOTP',
  default: {
    TimeOut: 0,
    TransactionID: ''
  }
})
export const otpTimeOut = atom<number>({
  key: 'otpTimeOut',
  default: 0
})
export const otpTransctionID = atom<string>({
  key: 'otpTransactionID',
  default: ''
})
export const otpCryptoNumber = atom<string>({
  key: 'otpCryptoNumber',
  default: ''
})
export const otpCouponCode = atom<string>({
  key: 'otpCouponCode',
  default: ''
})
export const otpTermsAgrees = atom<TermsAgrees>({
  key: 'otpTermsAgrees',
  default: {
    Terms1Agree: 0,
    Terms2Agree: 0,
    Terms3Agree: 0,
    Terms4Agree: 0,
    Terms5Agree: 0,
    Terms6Agree: 0
  }
})

export const timer = atom({
  key: 'timer'
})
