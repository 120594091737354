import { AutocallForm, AutocallFormItem, CertPart } from 'components/Common/form'
import TelecomSelector from 'components/Common/form/TelecomSelector'
import { useSendOTP, useCertOTP, useUser } from 'hooks'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpAuthType, otpCryptoNumber, otpTelecom } from 'recoil/otp/atom'
import { pNumAndCertNumWithValidate } from 'recoil/validate/withFormItem'
import { useEffect } from 'react'
import { CancelMyPageForm } from '../../../views/7_Cancel'
import { useNavigate } from 'react-router-dom'

const LoginPage = () => {
  const navigate = useNavigate()
  const [pTelecom, setPTelecom] = useRecoilState(otpTelecom)
  const send = useSendOTP()
  const cert = useCertOTP()
  const { sticker } = useUser()
  const cryptoNumber = useRecoilValue(otpCryptoNumber)
  const pNumAndCertNumValid = useRecoilValue(pNumAndCertNumWithValidate)
  const setAuthType = useSetRecoilState(otpAuthType)
  useEffect(() => {
    setAuthType('login')
  }, [setAuthType])
  const login = async () => {
    if (cryptoNumber.length > 0) {
      await cert.login()
      await sticker.check()
    }
  }
  return (
    <AutocallForm>
      <CancelMyPageForm>
        <h1>
          로그인을 위해 <br /> 본인인증을 진행해주세요.
        </h1>
        <section>
          <TelecomSelector telecom={pTelecom} setTelecom={setPTelecom} />
        </section>
        <AutocallFormItem title='' validate={pNumAndCertNumValid} essential>
          <CertPart onSendOTP={async () => await send.request()} onCertOTP={async () => await login()} />
        </AutocallFormItem>
        <button className='cancel_submit' onClick={login}>
          인증 완료
        </button>
        <p className='mypage_guide'>
          오토콜 회원이 아니신가요?
          <span onClick={() => navigate('/join')}>회원가입</span>
        </p>
      </CancelMyPageForm>
    </AutocallForm>
  )
}

export default LoginPage
