import { MainLayout } from '../../styles/Web/Main/styled'
import { Main5Join } from '../../styles/Web/Main/main5join_styled'
import pc_join_img from '../../assets/img/mainbanner/pc_main_join.gif'
import mo_join_img from '../../assets/img/mainbanner/mo_main_join.gif'
import { NavLink } from 'react-router-dom'
const Main5JoinSection = () => {
  return (
    <MainLayout>
      <Main5Join>
        <div>
          <section className='main-join-text'>
            <span>지금도 많은 분들의 전화번호가</span>
            <p>
              <span className='underline'>오토콜 대표번호</span>로 <br /> 보호받고 있습니다.
            </p>
            <NavLink to='join'>서비스 가입</NavLink>
          </section>
          <section>
            <img className='pc' src={pc_join_img} alt='join_img' />
            <img className='mo' src={mo_join_img} alt='join_img' />
          </section>
        </div>
      </Main5Join>
    </MainLayout>
  )
}

export default Main5JoinSection
