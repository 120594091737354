import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { validateTypeFam } from 'recoil/validate/atom'
import { APIs } from 'service'
import Utils from 'utils'
import useModal from './useModal.hook'
import useValidate from './useValidate'
import { otpCouponCode, otpCryptoNumber, otpPNumber } from '../recoil/otp/atom'
import { ErrtimerReset } from 'recoil/common/atom'

enum ERR_CODE {
  SUCCESS = '000000',
  PNUM_TELECOM_ERR = '030021',
  PNUM_JOINED_USER = '030002',
  PNUM_NOT_USER = '100012',
  CERT_OVER_THREE_TIME = '030007',
  CERT_WRONG_NUMBER_COUNT = '030008',
  CERT_NOT_SENDED = '030009',
  CERT_SUBIMT_TIME_ERROR = '030005',
  ERROR_COUPON = '030024',
  ERROR_NOT_ADULT = '030029',
  ERROR_RECAPTCHA = '100007',
  ERROR_LGU = '030040'
}
const useAPI = () => {
  const location = useLocation()
  const modal = useModal()
  const { join } = useValidate()
  const pNumber = useRecoilValue(otpPNumber)
  const { certNumber } = join
  const couponNumberValidState = useRecoilState(validateTypeFam('couponNumber'))
  const pNum = useSetRecoilState(validateTypeFam('phoneNumber'))
  const navigate = useNavigate()
  const setCryptoNumber = useSetRecoilState(otpCryptoNumber)
  const setTimercon = useSetRecoilState(ErrtimerReset)
  const setCoupon = useSetRecoilState(otpCouponCode)

  const pNumberValidState = useRecoilState(validateTypeFam('phoneNumber'))
  function errorHandler<T> (response: AxiosCustomResponseType<T>): T | undefined {
    const { data } = response
    const { Body, Header } = data
    switch (Header.ErrCode) {
      case ERR_CODE.SUCCESS:
        return Body === '' ? true : Body
      case ERR_CODE.ERROR_RECAPTCHA:{
        certNumber.recoilState[1]({ valid: true, msg: '222' })
        break
      }
      case ERR_CODE.CERT_SUBIMT_TIME_ERROR: {
        certNumber.recoilState[1]({ valid: true, msg: '201' })
        return Header.ErrCode
      }
      case ERR_CODE.ERROR_NOT_ADULT: {
        pNumberValidState[1]({ valid: true, msg: Header.ErrMsg })
        break
      }
      case ERR_CODE.CERT_WRONG_NUMBER_COUNT: {
        pNum({ valid: true, msg: Header.ErrMsg })
        return Body
      }
      case ERR_CODE.ERROR_COUPON: {
        // console.log("error coupon")
        setCoupon('')
        couponNumberValidState[1]({ valid: true, msg: '209' })
        break
      }
      case ERR_CODE.PNUM_TELECOM_ERR:
        pNum({ valid: true, msg: '030021' })
        break
      case ERR_CODE.PNUM_JOINED_USER:
        modal.confirm({
          title: '이미 가입된 회원입니다.',
          contents: '마이페이지로 이동하시겠습니까?',
          onConfirm: () => {
            navigate('/mypage')
          }
        })
        break
      case ERR_CODE.CERT_OVER_THREE_TIME:
        setCryptoNumber('')
        pNum({ valid: true, msg: '204' })
        // setTimeLeft(0)
        setTimercon(true)
        break
      case ERR_CODE.PNUM_NOT_USER:
        if (pNumber.length === 0) {
          certNumber.recoilState[1]({ valid: true, msg: '200' })
        } else {
          certNumber.recoilState[1]({ valid: true, msg: '221' })
          if (location.pathname === '/mypage') {
            modal.confirm({
              title: '가입된 회원이 아닙니다.',
              contents: '가입 페이지로 이동 하시겠습니까?',
              onConfirm: () => {
                navigate('/join')
              }
            }).then(() => {})
          } else {
            console.log('non mypage')
          }
        }
        break
      case ERR_CODE.ERROR_LGU:
        alert(Header.ErrMsg)
        break
      default:
        modal.alert({
          title: Header.ErrCode, contents: Header.ErrMsg })
        break
    }
  }

  async function requestSRS<T> (CmdType: CmdType, Body: RequestBodys) {
    return errorHandler<T>(await APIs.api.requestServer<T>(APIs.api.setHttpData(CmdType, Utils.common.getGUID(), Body)))
  }

  return {
    requestSRS
  }
}
export default useAPI
