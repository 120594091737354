import { useStickerData } from 'hooks'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { resStickerHistory } from 'recoil/user/atom'
import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'

const StickerModalContainer = styled.div`
  height: 350px;
  overflow-y: auto;
  > div {
    margin: 30px auto;
    display: block;
    > p {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #ff6e65;
    }
    > div {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      width: 260px;
      margin: 0 auto 20px auto;
      background: ${STYLE_GUIDE.COLOR.sub08};
      border-radius: 8px;
      color: ${STYLE_GUIDE.COLOR.grey02};
      padding: 13px 0;
    }
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
    > section {
      display: block;
      width: 260px;
      margin: 0 auto;
      text-align: left;
      > span {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${STYLE_GUIDE.COLOR.grey01};
      }
      > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 56px;
        margin: 20px 0 28px 0;
        background: #fafafa;
        border: 1px solid #ced6d8;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 56px;
        color: #a8b1b3;
        padding-left: 10px;
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    height: 350px;
    overflow-x: hidden;
    > div {
      margin: 15px auto;
    }
  }
`
const MyPageStickerModal = () => {
  const { refetch } = useStickerData()
  const sticker = useRecoilValue(resStickerHistory)
  useEffect(() => {
    refetch()
  }, [refetch])
  return (
    <StickerModalContainer>
      <div>
        <span>신청일 : {sticker?.LastSendDT}</span>
      </div>
      <div>
        <section>
          <span>수령인</span>
          <p>{sticker?.UName}</p>
        </section>
        <section>
          <span>배송지</span>
          <p>{sticker?.Address1}</p>
          <p>{sticker?.Address2}</p>
        </section>
        <section>
          <span>수령인 연락처</span>
          <p>{sticker?.RecvNumber}</p>
        </section>
      </div>
      <div>
        <div>
          배송지 수정 및 관련 문의 <br />
          고객센터 1566-7531
        </div>
        <p>
          본 혜택은 신규 가입 후 최초 1회 <br />
          신청 가능하며, 이용 1개월 내 해지 <br /> 고객은 대상에서 제외됩니다.
        </p>
      </div>
    </StickerModalContainer>
  )
}

export default MyPageStickerModal
