import SuccessSticker from 'components/2_Join/2_2/SuccessSticker'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import pc_top_banner from 'assets/img/join/pc_success-top.png'
import mo_top_banner from 'assets/img/join/mo_success-top.png'
const Img = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    height: fit-content;
    margin: 20px auto 80px auto;
    > img {
      width: 940px;
      height: 140px;
    }
    .mo {
      display: none;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    height: fit-content;
    margin: 40px auto 0;
    .pc {
      display: none;
    }
    > img {
      display: flex;
      margin: -30px 0 60px 0;
      width: 320px;
      height: 250px;
    }
  }
`
const SuccessHeader = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    p {
      width: fit-content;
      letter-spacing: -0.025em;
      text-align: left;
      margin: 0 0 50px 0;
      font-weight: 800;
      font-size: 30px;
      line-height: 42px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    p {
      font-weight: 800;
      font-size: 28px;
      line-height: 42px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }
  }
`
const Success = () => {
  return (
    <>
      <Img>
        <img className='pc' src={pc_top_banner} alt='top_banner' />
        <img className='mo' src={mo_top_banner} alt='top_banner' />
      </Img>
      <SuccessHeader>
        <p>
          오토콜 스티커 배송을 위한 <br /> 정보를 입력해 주세요.
        </p>
      </SuccessHeader>
      <SuccessSticker />
    </>
  )
}
export default Success
