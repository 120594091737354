import { MainLayout } from '../../styles/Web/Main/styled'
import { Main5Apart } from '../../styles/Web/Main/main6apart_styled'
import apart_icon from '../../assets/img/mainbanner/apart_icon.png'
import message_icon from '../../assets/img/mainbanner/message_icon.png'
import arrow_icon from '../../assets/img/mainbanner/main_arrow_icon.png'
import { useNavigate } from 'react-router-dom'
import apart_pdf from 'assets/document/apartment.pdf'
const Main6ApartSection = () => {
  const navigate = useNavigate()

  return (
    <Main5Apart>
      <MainLayout>
        <div>
          <section className='main-apart-text'>
            <span>
              <span className='underline'>오토콜 도입</span>
              으로 더 쉬운 입주민 차량 관리
            </span>
            <p className='pc_text'>
              입주민 차량 스티커와 주차번호판 기능을 <br />
              오토콜 스티커 하나로, 더 간편한 관리가 가능합니다!
            </p>
            <p className='mo_text'>
              입주민 차량 스티커와 주차번호판
              <br />
              기능을 오토콜 스티커 하나로,
              <br />더 간편한 관리가 가능합니다!
            </p>
          </section>
          <section className='main-apart-nav'>
            <div onClick={() => window.open(`${apart_pdf}`)}>
              <section>
                <p>아파트 제휴 안내</p>
                <span>pdf 파일이 새 창으로 열립니다.</span>
              </section>
              <section>
                <img className='color_light' src={apart_icon} alt='red_light' />
                <button>
                  <span>
                    안내서 확인
                    <img src={arrow_icon} alt='' />
                  </span>
                </button>
              </section>
            </div>
            <div onClick={() => navigate('/b2b')}>
              <section>
                <p>아파트 제휴 문의</p>
                <span>제휴 문의 페이지로 이동합니다.</span>
              </section>
              <section>
                <img className='color_light' src={message_icon} alt='red_light' />
                <button>
                  <span>
                    문의서 작성
                    <img src={arrow_icon} alt='' />
                  </span>
                </button>
              </section>
            </div>
          </section>
        </div>
      </MainLayout>
    </Main5Apart>
  )
}

export default Main6ApartSection
