import { FAQList, NewsList, NoticeList, QNA } from 'components/5_Support'
import NoticeDetail from 'components/5_Support/5_1/NoticeDetail'
import { PageLayout } from 'layout'
import { Cancel, Join, MyPage, Sticker, Support, Terms, Usage, Success, Apart } from 'views'
import { RouteObject } from 'react-router-dom'

const pageRoutes: RouteObject = {
  element: <PageLayout />,
  children: [
    {
      path: 'join',
      element: <Join />,
    },
    {
      path: 'b2b/terms/:termsIdx',
      element: <Apart />,
    },
    {
      path: 'success',
      element: <Success />,
    },
    {
      path: 'usage',
      element: <Usage />,
    },
    {
      path: 'sticker',
      element: <Sticker />,
    },
    {
      element: <Support />,
      children: [
        {
          path: 'notice',
          element: <NoticeList />,
        },
        {
          path: 'noticeDetail/:detailIdx',
          element: <NoticeDetail />
        },
        {
          path: 'news',
          element: <NewsList />,
        },
        {
          path: 'faq',
          element: <FAQList />,
        },
        {
          path: 'qna',
          element: <QNA />,
        }
      ]
    },
    {
      path: 'mypage',
      element: <MyPage />,
    },
    {
      path: 'cancel',
      element: <Cancel />,
    },
    {
      path: 'terms/:telecom/:termsIdx',
      element: <Terms />,
    }
  ]
}
export default pageRoutes
