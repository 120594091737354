import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import user_icon from 'assets/img/mypage/user_icon.png'
import plus_icon from 'assets/img/mypage/plus_icon.png'
import event_text from 'assets/img/mypage/event_text.png'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { modaltypeoption, resLoginUser, resStickerHistory } from 'recoil/user/atom'
import { ReactNode, useEffect } from 'react'
import { useLoginData, useModal } from 'hooks'
import MyPageStickerModal from './MyPageStickerModal'

import { otpAuthType } from '../../../recoil/otp/atom'
import MyPageModal from './MyPageModal'
import { modalControl } from '../../../recoil/common/atom'

import { useNavigate } from 'react-router-dom'

const MyPageUserInfoContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: block;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  }
`
const MyPageUserInfoCard = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .event_card {
      width: 230px;
      height: 55px;
      float: right;
      margin: -30px 12px 0 0;
    }

    .event_card2 {
      display: flex;
      width: 260px;
      height: 70px;
      margin: -54px 25px 0 auto;

      &.default {
        margin: -84px 25px 0 auto;
      }

      > p {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #A8B1B3;
        margin-left: 33px;
        text-align: center;

      }

    }
  }
  width: 100%;
  height: 110px;
  border: 1px solid #dfe8ea;
  box-shadow: 0 4px 14px rgba(227, 244, 248, 0.7);
  border-radius: 20px;
  margin-bottom: 42px;

}

@media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
  border: 1px solid #dfe8ea;
  border-radius: 30px;
  height: ${(props) => (props.color ? '180px' : '216px')};
  box-shadow: 0px 4px 14px rgba(227, 244, 248, 0.7);
  .event_card {
    width: 180px;
    height: auto;
    float: right;
    margin: -30px 67.5px 0 0;
  }

  .event_card2 {
    display: flex;
    height: 60px;
    width: 100%;
    text-align: center;
  
    > p {
      display: flex;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin: 17px auto 0 auto;
      align-items: center;
      text-align: center;
      color: #A8B1B3;
    }
    &.default{
      margin: -60px 0 0 0;
    }
  }
}
`

const UserPNumber = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 100%;
    height: 50px;
    margin: 30px 30px 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .visibility {
      visibility: hidden;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    flex-wrap: wrap;
  }
`
const UserStickerInfo = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: ${(props) => (props.color ? '90px' : '162px')};
    background: #ffffff;
    color: ${STYLE_GUIDE.COLOR.grey02};
    border: 2px solid #c2e7f2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: -65px 0 0 250px;
    text-align: center;
    > span {
      width: 100%;
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      padding: 0 5px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: ${(props) => (props.color ? '88px' : '162px')};
    background: #ffffff;
    color: ${STYLE_GUIDE.COLOR.grey02};
    border: 2px solid #c2e7f2;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: ${(props) => (props.color ? '-80px 0 0 85px;' : '-130px 0 0 80px;')};
    text-align: center;
    > span {
      width: 100%;
      display: inline-block;
      font-weight: 700;
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }
  }
`
const UserAvatar = styled.img`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50px;
    margin: 0 10px 0 30px;
    height: 50px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 50px;
    margin: 30px 0 0 30px;
  }
`
const UserPNumberPart = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 154px;
    margin-right: 450px;
    > p {
      width: 150px;
      text-align: left;
      margin: 0 0 0 8px;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    }

    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
      display: inline-block;
      width: 140px;
      margin: 0 0 0 8px;
      text-align: left;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 220px;
    margin: 30px 0 0 10px;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }

    > p {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: ${STYLE_GUIDE.COLOR.grey01};
      margin: 0;
    }
  }
`

const StickerBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 0 30px 0 auto;
    display: inline-block;
    border: none;
    background: ${(props) => props.color};
    border-radius: 8px;
    width: 195px;
    height: 50px;
    font-family: GmarketSansMedium, serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => (props.color === '#C2E7F2' ? '#7FC6DA' : '#FFF')};
    letter-spacing: -0.05em;
    cursor: pointer;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    background: ${STYLE_GUIDE.COLOR.main01};
    cursor: pointer;
    border-radius: 8px;
    border: none;
    padding: 0 78.5px;
    font-weight: 500;
    background: ${(props) => props.color};
    font-size: 16px;
    line-height: 50px;
    color: ${(props) => (props.color === '#C2E7F2' ? '#7FC6DA' : '#FFF')};
    margin: ${(props) => (props.color === '#C2E7F2' ? '20px auto 30px auto' : '56px auto 30px auto')};
    display: flex;
    font-family: GmarketSansBold, serif;
  }
`
const MyPageUserInfoCardEmpty = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    background: ${STYLE_GUIDE.COLOR.grey06};
    border-radius: 20px;
    width: 100%;
    height: 110px;
    margin-bottom: 70px;
    border: none;
    > p {
      margin: 42px 10px;
      text-align: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }

    .contact_add_text {
      margin: 45px 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }

    > img {
      width: 36px;
      height: 36px;
      display: flex;
      margin: 37px 0;
    }

    justify-content: center;
    cursor: pointer;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border: ${STYLE_GUIDE.COLOR.grey06};
    width: 100%;
    height: 180px;
    border-radius: 30px;
    display: flex;
    margin: 42px 0 40px 0;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }

    > img {
      width: 36px;
      height: 36px;
      display: flex;
      margin: 0 auto 0 auto;
    }

    > p {
      margin: 10px auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey01};
    }

    .contact_add_text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 0 auto;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
  }
`

const MyPageUserInfomation = () => {
  const user = useRecoilValue(resLoginUser)
  const { refetch } = useLoginData()
  const stickerState = useRecoilValue(resStickerHistory)
  const setAuthType = useSetRecoilState(otpAuthType)
  const modal = useModal()
  const navigate = useNavigate()
  const [modaltype, setModaltype] = useRecoilState(modaltypeoption)
  const [mypagemodal, setMypagemodal] = useRecoilState(modalControl)
   // console.log(user)
  // console.log(stickerState)
  useEffect(() => {
    refetch()
    setAuthType('fadd')
  }, [refetch, setAuthType])
  const pNumMask = (pNumber: string): string => `${pNumber?.slice(0, 3)}-${pNumber?.slice(3, 7)}-${pNumber?.slice(7, 11)}`
  const benefitText = (): ReactNode => {
    if (user.GetCouponDT) {
      return '1개월 이용 혜택 신청 완료'
    }
    if (user.IsFreeMember) {
      return '쿠폰 사용중'
    }
    if (user.FreeExpireDT && !user.IsFreeMember) {
      return `${user.FreeExpireDT} ~ 이용중`
    }
    if (!user.FreeExpireDT && !user.IsFreeMember) {
      return ''
    }
    return '1개월 이용 시 스티커 무료 증정'
  }
  const stickerBtnText = (): string => {
    if (user?.GetCouponDT) {
      return '신청 내역 보기'
    } else if (!user.FreeExpireDT && !stickerState.Address1) {
      return '혜택 신청'
    } else if (!user.FreeExpireDT && !user.IsFreeMember) {
      return ''
    } else if (user.IsFreeMember) {
      return ''
    } else {
      return ''
    }
  }

  const clickStickerBtn = () => {
    if (user?.GetCouponDT) {
      modal.alert({
        title: '혜택 신청 내역',
        contents: <MyPageStickerModal />,
        isXBtn: false
      })
    } else if (!user.FreeExpireDT && !stickerState.Address1) {
      navigate('/success')
    } else if (!user.FreeExpireDT && !user.IsFreeMember) {
      return ''
    } else if (user.IsFreeMember) {
      return ''
    } else {
      return ''
    }
  }
  const addNewUser = (option: number) => {
    setMypagemodal(true)
    setModaltype(option)
  }
  return (
    <MyPageUserInfoContainer>
      <MyPageUserInfoCard>
        <UserPNumber>
          <UserAvatar src={user_icon} alt='avatar img' />
          <UserPNumberPart>
            <span>가입자 연락처</span>
            <p>{pNumMask(user?.PNumber)}</p>
          </UserPNumberPart>
          <StickerBtn
            color={STYLE_GUIDE.COLOR.main01}
            className={`${stickerBtnText() === '' && 'visibility'}`}
            onClick={clickStickerBtn}
          >
            {stickerBtnText()}
          </StickerBtn>
        </UserPNumber>
        {stickerBtnText() === '혜택 신청'
          ? (
            <img className='event_card' src={event_text} alt='1 month free' />
            )
          : (
            <>
              {stickerBtnText() === '신청 내역 보기'
                ? (
                  <>
                    <UserStickerInfo onClick={clickStickerBtn}>
                      <span>{benefitText()}</span>
                    </UserStickerInfo>
                  </>
                  )
                : (
                  <>
                    {benefitText() === '쿠폰 사용중'
                      ? (
                        <>
                          <UserStickerInfo color='sizeup'>
                  <span>{benefitText()}</span>
                </UserStickerInfo>
                          <div className='event_card2'>
                  <p>{user?.FreeExpireDT} 유료 전환 예정</p>
                </div>
                        </>
                        )
                      : (
                        <>
                          {stickerBtnText() === '' && (
                  <div className='event_card2 default'>
                            <p>서비스 가입일 : {user.RegDT}</p>
                          </div>
                )}
                        </>
                        )}
                  </>
                  )}
            </>
            )}
      </MyPageUserInfoCard>
      {user.FNumber
        ? (
          <MyPageUserInfoCard color='margin_top'>
            <UserPNumber>
              <UserAvatar src={user_icon} alt='avatar img' />
              <UserPNumberPart>
                <span>추가 운전자 연락처</span>
                <p>{pNumMask(user?.FNumber)}</p>
              </UserPNumberPart>
              <StickerBtn color={STYLE_GUIDE.COLOR.sub06} onClick={() => addNewUser(2)}>
                번호 삭제
              </StickerBtn>
            </UserPNumber>
          </MyPageUserInfoCard>
          )
        : (
          <MyPageUserInfoCardEmpty onClick={() => addNewUser(1)}>
            <img src={plus_icon} alt='plus icon' />
            <p>연락처 추가</p>
            <p className='contact_add_text'>최대 1개의 연락처를 추가할 수 있습니다.</p>
          </MyPageUserInfoCardEmpty>
          )}
      {mypagemodal && modaltype === 1 && (
        <MyPageModal
          title='추가 연락처 등록'
          desc='등록된 차량과 매칭하여 연락받을<br/> 전화번호를 추가하세요.'
          leftbtn='취소'
          rightbtn='등록'
          content='option1'
        />
      )}
      {mypagemodal && modaltype === 2 && (
        <MyPageModal
          title='추가 연락처 삭제'
          desc='등록된 추가 연락처는 삭제 후<br/>변경 하실 수 있습니다.'
          leftbtn='취소'
          rightbtn='삭제'
          content='option2'
        />
      )}
    </MyPageUserInfoContainer>
  )
}

export default MyPageUserInfomation
