import { ApartLayout, ApartTermsSection } from '../../../styles/Web/Apart/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { apartTerms } from '../../../service/constants/apartTerms'

const ApartTerms = () => {
  // const [btnstyle, setBtnstyle] = useState(1)
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const ChangeTerms = (option: number) => {
    navigate(`/b2b/terms/${option}`)
    // setBtnstyle(option)
  }
  return (
    <ApartLayout>
      <ApartTermsSection>
        <p className='pc'>B2B 이용약관 · 개인정보처리방침</p>
        <p className='mo'>
          B2B 이용약관 · <br />
          개인정보처리방침
        </p>
        <div>
          <section className='terms_change_btn'>
            <button className={pathname.indexOf('/1') > 0 ? 'active' : ''} onClick={() => ChangeTerms(1)}>
              이용약관
            </button>
            <button className={pathname.indexOf('/2') > 0 ? 'active' : ''} onClick={() => ChangeTerms(2)}>
              개인정보처리방침
            </button>
          </section>
          <section>
            {pathname.indexOf('/1') > 0
              ? (
                <pre dangerouslySetInnerHTML={{ __html: apartTerms.USETERMS ?? '' }} />
                )
              : (
                <pre dangerouslySetInnerHTML={{ __html: apartTerms.PERSONAL_INFO ?? '' }} />
                )}
            <button
              className='back-apart'
              onClick={() => {
                navigate('/b2b')
              }}
            >
              <span>도입문의 돌아가기</span>
            </button>
          </section>
        </div>
      </ApartTermsSection>
    </ApartLayout>
  )
}
export default ApartTerms
