import { useSetRecoilState } from 'recoil'
import { isLoading } from 'recoil/common/atom'

const useLoading = () => {
  const setLoading = useSetRecoilState(isLoading)
  function on () {
    setLoading(true)
  }
  function off () {
    setLoading(false)
  }
  return {
    on,
    off
  }
}
export default useLoading
