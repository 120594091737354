import menuRoutes from 'router/menuRoutes'
import { DisplayType, MenuRoute } from 'router/router'

export const useExtractRoute = (prop: DisplayType) => {
  // console.log("useExtractRoute", prop)
  const returnRoutes: MenuRoute[] = []

  if (prop === 'support') {
    menuRoutes
      .filter((route) => route.path === prop)
      .forEach((route) => {
        route.children?.forEach((child) => {
          returnRoutes.push(child)
        })
      })
    return returnRoutes
  }
  menuRoutes.forEach((route) => {
    if (route.children != null) {
      if (!route.meta.display?.includes('header')) {
        route.children.forEach((child) => {
          if (child.meta.display != null) {
            if (child.meta.display?.includes(prop)) {
              returnRoutes.push(child)
            }
          }
        })
      } else {
        returnRoutes.push(route)
      }
    } else {
      if (route.meta.display?.includes(prop)) {
        returnRoutes.push(route)
      }
    }
  })
  return returnRoutes
}
