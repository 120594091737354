import Main1TopSection from 'components/1_Home/Main1TopSection'
import Main2MenuSection from '../../components/1_Home/Main2MenuSection'
import Main4HowToSection from '../../components/1_Home/Main4HowToSection'
import Main5JoinSection from 'components/1_Home/Main5JoinSection'
import Main7VideoSection from 'components/1_Home/Main7VideoSection'
import Main6ApartSection from 'components/1_Home/Main6ApartSection'
import Main3DescSection from 'components/1_Home/Main3DescSection'
import Popup from '../../components/Common/Popup'

// const noticeModalProps = {
//   title: '시스템 점검 안내',
//   contents: <div className={'service-modal'}>
//     보다 나은 서비스 제공을 위해 시스템 개선을 하고자 점검을 진행할 예정입니다.<br/>
//     점검 중에는 전체 서비스 이용이 일시적으로 중단 되오니 양해 부탁 드립니다.<br/><br/>
//     *점검 시간 : 2024년 11월 6일(수) 01:00 ~ 03:00 (2H 예정)<br/>
//     *점검 영향 : 서비스 이용 및 가입, 해지 불가<br/><br/>
//     사정에 따라 점검 시간은 연장될 수 있는 점 양해 부탁 드립니다.
//   </div>,
//   startDt: dayjs('2024-11-04 00:00'),
//   endDt: dayjs('2024-11-06 18:00'),
// }

const Home = () => {
  // 20241104 오토콜 DB이관 관련 시스템 점검 공지
  // useServiceAlertModal(noticeModalProps)
  return (
    <>
      <Popup />
      <Main1TopSection />
      <>
        <Main2MenuSection />
        {/* 운전자, 호출자 */}
        {/* 자동차, 신호등 */}
        <Main3DescSection />
        <Main4HowToSection />
        {/* 서비스 가입 */}
        <Main5JoinSection />
        {/* 아파트 제휴 */}
        <Main6ApartSection />
        {/* 오토콜 홍보영상 */}
        <Main7VideoSection />
      </>
    </>
  )
}

export default Home
