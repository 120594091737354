import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import google_logo from 'assets/img/mainbanner/googleplay_logo.png'
import appstore_logo from 'assets/img/mainbanner/appstore_logo.png'
import google_logo_hover from 'assets/img/mainbanner/googleplay_logo_hover.png'
import appstore_logo_hover from 'assets/img/mainbanner/appstore_logo_hover.png'
import background_banner_pc from 'assets/img/mainbanner/background_pc_mp4.gif'
export const Main1Top = styled.div`
{
    width: 100%;
    background-color: #F0F9FB;
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    margin: 0 auto;
    .banner-top {
        position: absolute;
        display: flex;
        width: 854px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        -moz-animation: fadein 2s; /* Firefox */
        -webkit-animation: fadein 2s; /* Safari and Chrome */
        -o-animation: fadein 2s; /* Opera */

        @keyframes fadein {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @-moz-keyframes fadein { /* Firefox */
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @-webkit-keyframes fadein { /* Safari and Chrome */
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @-o-keyframes fadein { /* Opera */
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        &.visible {
            opacity: 0;
        }
        .banner_contents {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;

            > span {
                color: #12124F;
                font-family: "GmarketSansBold";
                font-style: normal;
                font-weight: 500;

                &.first-line {
                    font-size: 36px;
                }

                &.second-line {
                    font-size: 80px;
                }
            }
        }

        .app_store {
            display: flex;
            width: auto;

            > a {
                border-radius: 10px;
                width: 200px;
                height: 66px;
                line-height: 66px;
                background: #ffffff;
                border: 1px solid #dbe3e4;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
                margin-right: 14px;

                .appstore {
                    background-image: url(${appstore_logo});
                }

                .googleplay {
                    background-image: url(${google_logo});
                }

                > span {
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: contain;
                    display: flex;
                    margin: 0 auto;
                    width: 123px;
                    padding: 19.5px 0;
                    height: 66px;
                }
            }

            > a:hover {
                background-color: #12124f;

                .appstore {
                    background-image: url(${appstore_logo_hover});
                }

                .googleplay {
                    //line-height: 66px;
                    background-image: url(${google_logo_hover});
                }
            }
        }
    }
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMain1TopPageSize}) {
        background-image: url(${background_banner_pc});
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        height: 840px;
        .banner-video-mo {
            display: none;
        }
        .banner-top {
            margin: 323px 926px 271px 140px;
        }
    }
    @media (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) and (max-width: ${STYLE_GUIDE.SIZE.maxMain1TopPageSize}) {
        background-image: url(${background_banner_pc});
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        height: 840px;
        .banner-video-mo {
            display: none;
        }

        .banner-top {
            margin: calc(200px + (323 - 200) * ((100vw - 1200px) / (1920 - 1200))) 926px 271px 140px;
        }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        background-color: #F0F9FB;
        height: 596px;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        position: relative;
        .banner-video {
            display: none;
        }

        .banner-video-mo {
            position: absolute;
            height: 100%;
            object-fit: contain;
            pointer-events: none;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .banner-top {
            height: auto;
            width: 100%;
            position: relative;
            z-index: 2;
            flex-direction: column;
            align-content: center;
            gap: 46px;
            margin-top: 63px;
            
            .banner_contents {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 100%;

                > span {
                    color: #12124F;
                    font-family: GmarketSansBold, serif;
                    font-style: normal;
                    font-weight: 500;
                    text-align: center;

                    &.first-line {
                        font-size: 14px;
                    }

                    &.second-line {
                        font-size: 28px;
                    }
                }
            }

            .app_store {
                display: flex;
                margin: 0 auto;
                width: 288px;
                height: 46px;

                > a {
                    margin: 0 auto;
                    border-radius: 10px;
                    width: 139px;
                    line-height: 46px;
                    background: #ffffff;
                    border: 1px solid #dbe3e4;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
                    height: 100%;
                    //margin: 0 7px;

                    > span {
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        background-size: contain;
                        display: flex;
                        margin: 0 auto;
                        width: 90%;
                        height: 100%;
                    }

                    .appstore {
                        background-image: url(${appstore_logo});
                        background-size: 80%;
                    }

                    .googleplay {
                        background-size: 80%;
                        background-image: url(${google_logo});
                    }
                }

                > a:hover {
                    background-color: #12124f;

                    .appstore {
                        background-image: url(${appstore_logo_hover});
                    }

                    .googleplay {
                        background-image: url(${google_logo_hover});
                    }
                }
            }
        }
    }
`
