import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'

export const Main5Join = styled.div`
{
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    height: 850px;
    > div {
      display: flex;
      width: 100%;
      height: 100%;

      > section {
        width: 50%;
          .mo{
            display: none;
          }
        > img {
          margin: 230px auto 193px auto;
          width: 618px;
          height: 427px;
        }
      }

      .main-join-text {
        height: 334px;
        margin: 268px 0 248px 0;

        > span {
          font-weight: 700;
          font-size: 32px;
          line-height: 32px;
          text-align: center;
          color: #7d898b;
        }

        > p {
          margin: 18px 0 80px 0;
          font-weight: 500;
          font-size: 50px;
          line-height: 62px;
          font-family: GmarketSansBold, serif;

          .underline {
            font-family: GmarketSansBold, serif;
            background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
          }
        }

        > a {
          cursor: pointer;
          width: 238px;
          background: #10a8ca;
          border-radius: 20px;
          height: 80px;
          border: none;
          line-height: 80px;
          font-family: "GmarketSansBold", serif;
          font-weight: 500;
          font-size: 32px;
          color: white;
          padding: 22px 37px;
        }
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    width: 320px;
    margin: 0 auto;
    > div {
      > section {
        .pc{
          display: none;
        }
        > img {
          margin: 60px auto 90px auto;
          width: 100%;
          height: auto;
        }
      }

      .main-join-text {
        margin-top: 140px;
        text-align: center;

        > span {
          font-weight: 700;
          font-size: 20px;
          line-height: 16px;
          text-align: center;
          color: ${STYLE_GUIDE.COLOR.grey02};
        }

        > p {
          margin: 10px 0 40px 0;
          font-family: GmarketSansBold, serif;
          font-weight: 500;
          font-size: 36px;
          line-height: 48px;

          .underline {
            font-family: GmarketSansBold, serif;
            background: linear-gradient(0deg, #caf5ff 30%, transparent 50%);
          }
        }

        > a {
          cursor: pointer;
          width: 170px;
          background: #10a8ca;
          border-radius: 20px;
          height: 60px;
          border: none;
          font-family: "GmarketSansBold", serif;
          font-weight: 500;
          font-size: 22px;
          line-height: 60px;
          color: white;
          padding: 17.5px 28.5px;
        }
      }
    }
  }
}
}
`
