import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'
import servicejoin from '../../../assets/img/mainbanner/servicejoin.png'
import contact from '../../../assets/img/mainbanner/contact.png'
import useinfo from '../../../assets/img/mainbanner/useinfo.png'
import sticker from '../../../assets/img/mainbanner/sticker.png'

export const Main2Menu = styled.div`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      .menu-list {
        display: flex;
        justify-content: space-between;
        height: 160px;
        margin: 60px 0 0 0;
        > button {
          margin-top: 10px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;
          height: 150px;
          border: none;
          width: 260px;
          border-radius: 20px;
          cursor: pointer;
        }
        .service_join {
          background-image: url(${servicejoin});
        }
        .service_join:hover {
          margin-top: 0;
          transition: all 0.5s;
          box-shadow: 0 4px 20px rgba(16, 168, 202, 0.5);
        }
        .use_info {
          background-image: url(${useinfo});
        }
        .use_info:hover {
          margin-top: 0;
          transition: all 0.5s;
          box-shadow: 0 4px 20px rgba(82, 182, 255, 0.5);
        }
        .contact {
          background-image: url(${contact});
        }
        .contact:hover {
          margin-top: 0;
          transition: all 0.5s;
          box-shadow: 0 4px 20px rgba(21, 86, 212, 0.5);
        }
        .sticker {
          background-image: url(${sticker});
        }
        .sticker:hover {
          margin-top: 0;
          transition: all 0.5s;
          box-shadow: 0 4px 20px rgba(245, 186, 35, 0.5);
        }
      }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      display: none;
    }
  }
`
