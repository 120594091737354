import icon1 from 'assets/img/apart/apart-ico-call.png'
import icon2 from 'assets/img/apart/apart-ico-paper.png'
import icon3 from 'assets/img/apart/apart-ico-apart.png'
import icon4 from 'assets/img/apart/apart-ico-car.png'

export const StepData = {
  content: [
    {
      title: 'STEP 1',
      text: '오토콜 서비스 <br/> 전화 상담 진행',
      img: `${icon1}`
    },
    {
      title: 'STEP 2',
      text: '현장 방문 및 계약 진행',
      img: `${icon2}`
    },
    {
      title: 'STEP 3',
      text: '스티커 맞춤 제작 <br/> 관리사무소 배치',
      img: `${icon3}`
    },
    {
      title: 'STEP 4',
      text: '입주민 스티커 수령 후 <br/> 안심주차 서비스 이용',
      img: `${icon4}`
    }
  ]
}
