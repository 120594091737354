import { useModal } from 'hooks'
import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import stickerImg from '../../../assets/img/sticker/sticker1.png'
import { useNavigate } from 'react-router-dom'

const StickerPrintModalContent = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .mo {
      display: none;
    }
    > img {
      width: 170px;
      height: 170px;
    }
    > p {
      color: ${STYLE_GUIDE.COLOR.grey02};
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* or 125% */

      text-align: center;

      /* main-txt-color */

      color: #2b3639;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .pc {
      display: none;
    }
    > img {
      width: 80%;
    }
    > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #2b3639;
    }
  }
`
const ImgprintBtn = styled.button`
  cursor: pointer;
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    background-color: ${STYLE_GUIDE.COLOR.main01};
    border-radius: 8px;
    border: none;
    height: 50px;
    > span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      font-family: GmarketSansBold, serif;
      color: white;
    }
  }
`
export const SimpleComponent = (props: { printRef: any }) => {
  const { printRef } = props
  return (
    <div style={{ display: 'none' }}>
      <div ref={printRef}>
        <img src={stickerImg} alt='sticker img' />
      </div>
    </div>
  )
}
const StickerPrintBtn = () => {
  const modal = useModal()
  const navigate = useNavigate()
  const content = (
    <StickerPrintModalContent>
      <img src={stickerImg} alt='sticker img' />
      <p className='pc'>
        오토콜 서비스 가입 시 등록된 차량에 위의 이미지를 <br />
        인쇄하여 부착하세요. 관련 문의사항은 고객센터 <br /> 1566-7531로 연락 부탁드립니다.
      </p>
      <p className='mo'>
        오토콜 서비스 가입 시 등록된 차량에 위의 <br /> 이미지를 인쇄하여 부착하세요. 관련 문의 <br />
        사항은 고객센터 1566-7531로 <br /> 연락 부탁드립니다.
      </p>
    </StickerPrintModalContent>
  )
  const handleClick = () => {
    modal.form({
      title: '오토콜 대표번호 이미지 인쇄하기',
      contents: content,
      onCancel: () => {
        navigate('/usage')
      }
    })
  }
  return (
    <ImgprintBtn onClick={handleClick}>
      <span>이미지 인쇄</span>
    </ImgprintBtn>
  )
}

export default StickerPrintBtn
