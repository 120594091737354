import sticker_1 from 'assets/img/apart/apart_sticker_1.png'
import sticker_2 from 'assets/img/apart/apart_sticker_2.png'
import sticker_2_1 from 'assets/img/apart/apart_sticker_2_1.gif'
import sticker_3 from 'assets/img/apart/apart_sticker_3.png'
import sticker_4 from 'assets/img/apart/apart_sticker_4.png'
import sticker_5 from 'assets/img/apart/apart_sticker_5.png'
export const ServiceData = {
  content: [
    {
      title: '무점착 주차 스티커 제공',
      text: ' 혹시 아파트 주차 스티커가 오래됐나요? <br /> 낡은 주차 스티커를 최신 디자인으로 교체해보세요. ',
      img: `${sticker_1}`
    },
    {
      title: '오래가는 무점착 스티커',
      text: '무점착 스티커는 장시간 부착 후에도 끈적임이 없고 <br />빛 바램, 썬팅 훼손이 없습니다.',
      img: `${sticker_2}`,
      img2: `${sticker_2_1}`
    },
    {
      title: '입주민의 개인정보 보호',
      text: '아파트 주차장에 뿌려진 입주민의 개인정보, <br />오토콜 도입으로 지켜줄 수 있어요.',
      img: `${sticker_3}`
    },
    {
      title: '긍정적인 고객경험 사례',
      text: '실제 도입 아파트 주민들은 어떻게 생각하고 있을까요? <br /> <br />데시앙 아파트 329명 서비스 만족도 설문 결과<br />99%가 긍정적인 만족도 답변을! <br /> 95%가 이용 방법이 간편하다고 느꼈어요.',
      img: `${sticker_4}`
    },
    {
      title: '얼굴 붉히지 말아요',
      text: "통화 중에 기분이 상한적이 있나요? <br />오토콜 다이렉트 호출로 통화 없이 운전자와 <br /> 연결할 수 있어요. <br /> <br />'비상등이 켜져있어요~'와 같은 메시지를 <br />보낼수도 있습니다. 물론 내 번호는 노출되지 않아요.",
      img: `${sticker_5}`
    }
  ]
}
