import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { otpTimeOut } from '../../recoil/otp/atom'
import { mypagemodalundertext } from '../../recoil/common/atom'

const Timer = () => {
  const [timeLeft, setTimeLeft] = useRecoilState(otpTimeOut)
  const [min, setMin] = useState(timeLeft / 60)
  const [sec, setSec] = useState(timeLeft % 60)
  const setUndertext = useSetRecoilState(mypagemodalundertext)
  useEffect(() => {
    const startTimer = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1)
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(startTimer)
          setUndertext('유효시간이 초과되었습니다. 인증번호를 다시 받아주세요.')
          setTimeLeft(0)
        } else {
          setMin(min - 1)
          setSec(59)
        }
      }
    }, 1000)

    return () => {
      clearInterval(startTimer)
    }
  }, [min, sec, setTimeLeft, setUndertext])
  return (
    <span>
      {`0${min}`}:{sec < 10 ? `0${sec}` : sec}
    </span>
  )
}
export default Timer
