import AutocallBtn from 'components/Common/AutocallBtn'
import { AutocallForm, AutocallFormItem, CertPart } from 'components/Common/form'
import AutocallInput from 'components/Common/form/AutocallInput'
import TelecomSelector from 'components/Common/form/TelecomSelector'
import { useSendOTP, useCertOTP, useValidate } from 'hooks'
import {useEffect, useRef, useState} from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  otpAuthType,
  otpCarNumber,
  otpCouponCode,
  otpCryptoNumber, otpjoinready,
  otpPNumber,
  otpTelecom,
  otpTermsAgrees
} from 'recoil/otp/atom'
import { validateTypeFam } from 'recoil/validate/atom'
import { carNumWithValidate, pNumAndCertNumWithValidate } from 'recoil/validate/withFormItem'
import AutocallTerms from './AutocallTerms'
import {CertCompleteState, isLoading, mvnoData} from "recoil/common/atom";
import {CMD_TYPE} from "service/constants/common";
import useAPI from "hooks/useAPI.hook";

const AutocallJoin = () => {
  const [CarNumber, setCarNumber] = useRecoilState(otpCarNumber)
  const [pTelecom, setPTelecom] = useRecoilState(otpTelecom)
  const [checkCarNum, setCheckCarNum] = useState('')
  const [coupon, setCoupon] = useRecoilState(otpCouponCode)
  const certCarNumValid = useRecoilValue(carNumWithValidate)
  const pNumAndCertNumValid = useRecoilValue(pNumAndCertNumWithValidate)
  const pNum = useRecoilValue(otpPNumber)
  const certNum = useRecoilValue(otpCryptoNumber)
  const term = useRecoilValue(otpTermsAgrees)
  const setAuthType = useSetRecoilState(otpAuthType)
  const couponValid = useRecoilValue(validateTypeFam('couponNumber'))
  const certcomplete = useRecoilValue(CertCompleteState)
  const setTermsValid = useSetRecoilState(validateTypeFam('terms'))
  const { join, replace } = useValidate()
  const { phoneNumber, certNumber, carNumber, certCarNumber, couponNumber, termsAgree } = join
  const { replaceCoupon, replaceCar } = replace
  const send = useSendOTP()
  const cert = useCertOTP()
  const [JoinReadyCheck, setJoinReadyCheck] = useRecoilState(otpjoinready)
  const certNumValidState = useRecoilState(validateTypeFam('certNumber'))
  const pNumberValidState = useRecoilState(validateTypeFam('phoneNumber'))
  const certCarNumberValidState = useRecoilState(validateTypeFam('certCarNumber'))
  const carNumValidState = useRecoilState(validateTypeFam('carNumber'))
  const couponNumberValidState = useRecoilState(validateTypeFam('couponNumber'))
  const joinButtonRef = useRef<HTMLButtonElement>(null)
  const visible = useRecoilValue(isLoading)
  useEffect(() => {
    setPTelecom(0)
    setTermsValid({ valid: false, msg: '' })
    setAuthType('join')
    setCarNumber('')
    setCoupon('')
    setJoinReadyCheck(false)
    pNumberValidState[1]({ valid: false, msg: '' })
    certNumValidState[1]({ valid: false, msg: '' })
    carNumValidState[1]({ valid: true, msg: '' })
    certCarNumberValidState[1]({ valid: true, msg: '' })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPTelecom, setAuthType, setCarNumber, setCoupon, setTermsValid])
  const setIsMvnoConfirm = useSetRecoilState(mvnoData)

  const handleJoinBtnClick = async () => {
    const buttonEle = joinButtonRef.current
    if(buttonEle) buttonEle.blur()

    phoneNumber.validFunc(pNum)
    certNumber.validFunc(certNum)
    carNumber.validFunc(CarNumber)
    certCarNumber.validFunc(checkCarNum)
    termsAgree.validFunc(JSON.stringify(term))
    if(!certcomplete){
      window.scrollTo({
        top: 250,
        behavior: 'smooth'
      })
      pNumberValidState[1]({ valid: false, msg: '205' })
    }
    else if (!phoneNumber.validFunc(pNum) || !certNumber.validFunc(certNum)) {
      window.scrollTo({
        top: 310,
        behavior: 'smooth'
      })
    } else if (!carNumber.validFunc(CarNumber) || !certCarNumber.validFunc(checkCarNum)) {
      window.scrollTo({
        top: 515,
        behavior: 'smooth'
      })
    } else if (!termsAgree.validFunc(JSON.stringify(term))) {
      window.scrollTo({
        top: 750,
        behavior: 'smooth'
      })
    } else if (
      phoneNumber.validFunc(pNum) &&
      certNumber.validFunc(certNum) &&
      carNumber.validFunc(CarNumber) &&
      certCarNumber.validFunc(checkCarNum) &&
      termsAgree.validFunc(JSON.stringify(term))
    ) {
      // 휴대폰 인증번호 완료 확인
      if (JoinReadyCheck) {
      // 쿠폰 확인
        if (coupon.length > 11 || coupon.length === 0) {
          await cert.join_partner()
        } else {
          couponNumberValidState[1]({ valid: true, msg: '209' })
        }
      } else {
        certNumValidState[1]({ valid: true, msg: '205' })
      }
    } else {
    }
  }

  const api = useAPI()
  const checkMvnoMember = async (): Promise<CheckMvnoMemberBody | undefined> => {
    const result = await api.requestSRS<CheckMvnoMemberBody>(
        CMD_TYPE.CHECK_MVNO_MEMBER,
        {Telecom:pTelecom, PNumber:pNum}
    )
    return result
  }

  useEffect(() => {
    return () => {
      setIsMvnoConfirm(false)
    }
  }, [setIsMvnoConfirm]);

  return (
    <AutocallForm>
      <AutocallFormItem title='통신사 선택' essential>
        <TelecomSelector telecom={pTelecom} setTelecom={setPTelecom} />
      </AutocallFormItem>
      <AutocallFormItem title='휴대폰 번호' validate={pNumAndCertNumValid} essential>
        <CertPart onSendOTP={async () => await send.request()} onCertOTP={async () => await cert.join_ready()} isCertBtn
          onMvnoCheck={checkMvnoMember}/>
      </AutocallFormItem>
      <AutocallFormItem
        title='차량 번호'
        subTitle='정상적인 호출을 위해<br/> 정확하게 입력해 주세요.'
        validate={certCarNumValid}
        essential
      >
        <div className='input-part'>
          <AutocallInput
            inputType='text'
            placeholder='예) 123가1234, 서울12가3456'
            state={CarNumber}
            setState={setCarNumber}
            replacer={replaceCar}
            validate={carNumber}
            maxLength={10}
          />
        </div>
        <div className='input-part'>
          <AutocallInput
            inputType='text'
            placeholder='차량번호 재입력'
            state={checkCarNum}
            setState={setCheckCarNum}
            replacer={replaceCar}
            validate={certCarNumber}
            maxLength={10}
          />
        </div>
      </AutocallFormItem>
      <AutocallFormItem title='쿠폰번호' validate={couponValid}>
        <div className='input-part'>
          <AutocallInput
            inputType='text'
            placeholder='`-`를 제외하고 쿠폰번호를 정확히 입력하세요.'
            state={coupon}
            setState={setCoupon}
            replacer={replaceCoupon}
            validate={couponNumber}
            maxLength={12}
          />
        </div>
      </AutocallFormItem>
      <AutocallFormItem title='이용 약관 동의' validate={termsAgree.recoilState[0]} essential>
        <AutocallTerms />
      </AutocallFormItem>
      <AutocallFormItem>
        <div className='join-submit'>
          <AutocallBtn disabled={visible} buttonRef={joinButtonRef} title='오토콜 유료가입' type='default' onClickFunc={handleJoinBtnClick} />
          <p className='under-text'>휴대폰 통신요금에 매월 부가세포함 990원이 부과됩니다.</p>
        </div>
      </AutocallFormItem>
    </AutocallForm>
  )
}

export default AutocallJoin
