export const apartTerms = {
  USETERMS: `
  <p>  제 1조
<br/>
(목적) 본 이용약관은 ㈜데이터유니버스(이하“회사”)에서 제공하는 'AI오토콜'(이하 “서비스”)서비스를 이용하는 자(이하 "이용자")와 회사 간의 권리, 의무 및 책임사항 등 기본적인 사항의 규정을 목적으로 합니다.</p>

제 2조 (용어의 정의)
본 약관에서 사용되는 용어의 정의는 다음과 같습니다.
1. "서비스"란 운전자와 호출자의 개인정보 노출 없이 차량번호만으로 운전자를 호출하는 AI오토콜 서비스를 말합니다.
2. "제휴사"란 이용자가 본 서비스를 이용할 수 있도록 등록채널을 제공하는 회사 또는 단체를 의미합니다.
3. "이용자"란 제휴사를 통해 회사가 제공하는 서비스를 이용하는 개인을 말합니다.
4. “앱”이란 서비스의 제공 및 이용을 위해 단말기에 설치되는 스마트폰 전용 애플리케이션을 말합니다.
5. “보이는ARS”란 서비스 전화번호로 통화를 걸었을 때 웹 화면으로 운전자를 호출할 수 있는 ARS의 방법을 말합니다.
6. “말하는ARS”란 서비스 전화번호로 통화를 걸었을 때 음성으로 운전자를 호출할 수 있는 ARS의 방법을 말합니다.
7. “누르는ARS”란 서비스 전화번호로 통화를 걸었을 때 휴대전화 키패드 버튼을 통해 운전자를 호출할 수 있는 ARS의 방법을 말합니다.
8. 본 조 및 본 약관의 다른 조항에서 정의되지 않은 용어는 관계 법령 및 서비스 안내에서 정한 바에 따릅니다.

제 3조 (약관의 효력 및 변경)
1. 회사는 이 약관을 회사가 운영하는 사이트 또는 서비스 신청 화면에 게시하여 이용자에게 공시함으로써 약관의 효력이 발생합니다.
2. 회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며, 회사가 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 회사가 운영하는 사이트에서 적용일자 7일 전에 공지합니다.
3. 회사가 제2항에 따라 변경 약관을 공지하였음에도 이용자가 약관 변경 적용일까지 변경 약관에 대한 거부의사를 표시하지 않으면 약관의 변경에 동의한 것으로 간주합니다. 이용자는 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.
4. 서비스를 이용하려는 자(이하 “이용자”)가 “약관에 동의”의 체크박스를 체크하였을 경우 본 약관의 내용을 모두 인지하였으며 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의한 것으로 봅니다.
5. 이용자가 이 약관의 내용(약관 변경 시 변경된 내용 포함)을 알지 못하여 발생하는 손해 및 피해에 대해서는 회사는 일체 책임을 지지 않습니다.

제 4조 (이용 신청 및 이용 계약의 성립)
1. 이용계약은 이용자가 약관의 내용에 동의한 후 이용신청하고 회사가 이를 승낙함으로써 체결됩니다.

제 5조 (서비스 제공 시간)
1. 회사가 서비스를 제공하는 기간은 서비스 이용신청을 회사가 승낙한 날로부터 회사와 제휴사간 서비스 이용계약 해지 또는 서비스 종료 때까지입니다.
2. 서비스는 연중무휴 1일 24시간 제공을 원칙으로 합니다.
3. 서비스 이용 스티커는 기본적으로 무상으로 제공하나 이용자는 필요에 따라 유료로 구매할 수있습니다.

제 6조 (이용신청에 대한 승낙의 유보 및 서비스 이용의 제한)
1. 제 3조에 따라 이용자가 제휴사를 통해 서비스 이용을 신청하면 회사는 원칙적으로 이를 승낙합니다. 단, 다음 각 호에 해당하는 신청에 대해서는 그 사유가 해소될 때까지 승낙을 유보하거나 거부할 수 있습니다.
① 기술상 서비스를 제공할 수 없는 경우
② 가입자의 단말기가 문자 수신(SMS/MMS), 앱 PUSH 수신에 문제가 있는 경우 또는 기타 사유로 문자 전송(SMS/MMS), 앱 PUSH 전송을 받을 수 없는 경우
③ 단말기 특성 또는 OS 버전의 문제로 인해 정상적인 서비스 제공이 불가능한 경우
2. 회사는 정기점검 및 기타 기술상의 이유, 기타 운영상의 사유와 목적에 따라 회사가 정한 기간에 일시 중지될 수 있으며, 제휴사의 기술상, 운영상의 사유와 목적에 따라 사전 공지 없이 일시 중지될 수 있습니다
3. 회사는 전시•사변•천재지변 또는 이에 준하는 국가비상사태가 발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신사업자가 전기통신서비스를 중지하는 등 기타 부득이한 사유가 있는 경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
4. 이용자가 본 약관에 동의함은 서비스와 관련하여 회사에서 제공하거나 제공할 모든 서비스에 동의하는 것으로 봅니다.

제 7조 (서비스 이용신청) 
1. 본 서비스는 회사가 제휴사와 제휴 계약하여 제공하는 상품으로서 서비스 이용신청은 제휴사를 통해 진행합니다. 

제 8조 (서비스 해지) 
1. 이용자는 제휴사를 통해 절차에 따라 서비스를 해지할 수 있습니다.
2. 서비스 해지 시 차량에 부착한 주차번호판 스티커의 대표번호는 사용할 수 없으며 주차번호판 스티커는 제휴사에 반납해야 합니다.
3. 제휴사와의 계약 종료 등 이용자들의 비자발적 해지로 인한 고객 피해 방지를 위하여 회사는 사전 또는 해지 직후 이용자에게 서비스 유지 방법을 안내할 수 있습니다. 

제 9조 (서비스 이용문의) 
1. 서비스 이용 신청 및 해지, 서비스 이용 중 발생하는 문의 사항은 다음 각 호에 따라 처리됩니다.
① 서비스 이용신청 및 해지 : 제휴사 문의처 이용
② 서비스 이용 방법, 장애문의 : AI오토콜 고객센터 1566-7531(월~금, 공휴일 휴무, 09:00~12:00 / 13:00~18:00)

제 10조 (정보의 제공 및 광고의 게재)
1. 회사는 서비스를 운영할 때 결제 안내, 정책 변경, 장애 등 필수 주요 내용은 홈페이지에 게재하거나 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.
2. 회사는 서비스를 운영할 때 회사 또는 제휴사의 서비스 관련 각종 광고를 이용자의 동의를 얻어 이메일(e-mail), 문자메시지(SMS/MMS), Push message 등의 방법으로 이용자에게 제공할 수 있습니다.

제 11조 (데이터 수집 및 사용)
1. 회사는 업무와 관련하여 이용자의 사전 동의 없이 특정 개인을 알아볼 수 없는 형태로 가공된, 이용자 전체 또는 일부의 정보에 관한 통계자료를 작성하여 사용할 수 있고, 이를 위하여 이용자의 휴대폰 단말에 쿠키(Cookie : 인터넷 웹사이트의 방문기록 정보)를 전송할 수 있습니다. 이때 이용자는 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 휴대폰 단말의 브라우저 설정을 변경할 수 있습니다. 쿠키의 설정 변경으로 말미암아 서비스 이용이 변경되는 것은 이용자의 책임입니다.
2. 회사는 앱과 웹에 포함된 광고의 통계 수집을 위해 광고 노출수와 광고 클릭수 등을 수집합니다.
3. 수집한 데이터는 통계자료로만 활용됩니다.

제 12조 (회사의 의무)
1. 회사는 본 약관에 정한 바에 따라 지속적, 안정적으로 서비스를 제공할 의무가 있습니다. 단, 전쟁 지진, 재난 등 천재 지변이나 불가피한 시스템의 고장, 통신서비스 회사의 통신 두절 등 불가항력의 사유가 발생한 경우에는 본 서비스를 중단할 수 있습니다.
2. 회사는 서비스 제공과 관련하여 취득한 이용자 정보를 본인의 사전 승낙 없이 제 3자에게 누설 또는 배포하지 않습니다. 다만, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 국가기관의 요청이 있는 경우에는 그러하지 않습니다.
3. 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련 절차 및 내용 등에 있어 편의를 제공하도록 노력합니다.
4. 회사는 이용자에게 안정적인 서비스 제공을 위하여 지속적으로 관련 시스템이나 절차, 기능 등의 예방 점검, 유지 보수 등을 이행하며, 회사 내부의 원인에 의해 서비스 장애가 발생하는 경우에는 부득이한 사유가 없는 한 곧바로 이를 수리 및 복구합니다.
5. 회사는 서비스와 관련한 이용자의 불만사항을 접수하면 신속히 처리해야 할 의무가 있습니다.

제 13조 (이용자의 의무)
1. 이용자는 본인이 설정한 '서비스' 설정에 책임을 지며, 이용자 부주의로 인해 발생하는 모든 결과에 대한 책임은 이용자에게 있습니다.
2. 이용자는 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한 주의사항, 회사가 통지하는 사항 등을 따라야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안됩니다.
3. 이용자는 차량 이용관계가 없는 타인의 차량번호를 도용하여 서비스에 등록하는 행위를 해서는 안됩니다.

제 14조 (이용자의 개인정보 보호)
1. 회사는 관련 법령이 정하는 바에 따라 이용자 등록정보를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다.
2. 이용자의 개인정보 보호와 관련한 사항은 관계 법령과 회사가 정하는 개인정보처리방침을 따릅니다. 서비스 개인정보처리방침은 회사와 이용자 간 이용계약의 일부를 구성합니다.

제 15조 (손해배상)
1. 회사와 이용자는 일방 당사자의 귀책사유로 인하여 상대방에게 발생한 손해에 대하여 배상해야 합니다.
2. 회사는 본 서비스 이용자의 고의 또는 과실로 발생한 손해에 대해서 어떠한 책임도 지지 않습니다.

제 16조 (면책사항)
1. 회사는 천재지변 또는 이에 준하는 불가항력으로 말미암아 서비스를 제공할 수 없을 때에는 서비스 제공에 대한 책임이 면제됩니다.
2. 회사는 전기통신서비스 특성상 불가피한 사유로 서비스를 제공할 수 없거나 관련 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 일시적으로 서비스 제공을 중단하는 것에 책임을 지지 않습니다.
3. 회사는 이용자의 고의, 과실 또는 서비스 이용방법에 대한 미숙지 등의 귀책사유로 서비스 이용의 장애, 회원정보나 자료소실 또는 손해가 발생한 것에 책임을 지지 않습니다.
4. 회사는 이용자가 자료의 저장, 공유를 함으로써 발생하는 법적인 문제에 책임을 지지 않습니다.
5. 회사는 이용자 상호 간 또는 이용자와 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 개입할 의무가 없으며 이 때문에 발생한 손해를 배상할 책임도 없습니다.
6. 이용자가 자신의 개인정보를 타인에게 유출 또는 제공하거나 무단으로 사용 당하는 등 그 관리를 소홀히 했을 때 발생하는 피해에 대해 회사는 일절 책임을 지지 않습니다.
7. 회사는 이용자의 휴대폰 단말 오류, 제 3자 제공 프로그램으로 인한 서비스 종료 및 오류 또는 네트워크 환경상의 장애 및 오류, 서버 점검 등 환경에 따라 제공 기능이 정상적으로 동작하지 않아 발생한 손해에 책임을 지지 않습니다.
8. 제휴사의 상태 변경, 이용 계약 해지 또는 요금 미납으로 인해 서비스 이용이 정지되어 이용자에게 손해가 발생하였을 때 회사는 이에 책임을 지지 않습니다.
9. 회사가 공식적으로 제공한 경로가 아닌, 비공식적 경로를 통해 자사의 서비스 앱을 다운로드/설치한 이용자에게 발생한 손해에 대하여 회사는 책임을 지지 않습니다.
10. 공식적 경로를 통하지 않고 서비스를 이용하거나, 비정상적으로 접근한 웹페이지 또는 설치된타 앱 등에 의한 손해는 회사에서 책임을 지지 않습니다.

제 17조 (약관 외 준칙)
이 약관에 명시되지 아니한 사항에 대해서는 관계 법령과 회사가 정한 본 서비스의 세부이용지침 등의 규정을 따릅니다.

제 18조 (관할법원)
1. 서비스 이용과 관련하여 회사와 이용자 사이에 분쟁이 발생하면 회사와 이용자는 분쟁의 해결을 위해 성실히 협의합니다.
2. 제1항의 협의에서도 분쟁이 해결되지 않으면 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
 
부칙
(시행일) 본 약관은 2021년 09월 01일부터 시행됩니다.
  `,

  PERSONAL_INFO: `주식회사 데이터유니버스(이하 “회사”)는 이용자의 개인정보를 중요시하며, 『개인정보보호법』을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
회사는 개인정보 처리방침을 통하여 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보 보호를 위해 어떠한 조치가 취해지고 있는 지 알려드립니다.
회사는 개인정보 처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
본 개인정보 처리방침은 회사가 제공하는 AI오토콜 서비스 이용에 적용되며 다음과 같은 내용을 담고 있습니다.
· 개인정보의 수집 및 이용 목적
· 개인정보의 처리 및 보유기간
· 개인정보의 제3자 제공
· 권리·의무 및 행사방법
· 개인정보의 파기
· 개인정보의 안전성 확보조치
· 개인정보 보호책임자
· 권익침해 구제방법
· 고지의무
 

제 1조 개인정보의 수집 및 이용 목적
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 『개인정보 보호법』 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
 
가. 개인정보 수집 항목
[서비스 이용]
- 휴대폰 가입통신사, 휴대폰 번호, 차량번호, 주소, 서비스 이용 정보
- 서비스 접속 일시, 가입경로, 서비스 이용기록, SMS/MMS & Push 전송 기록
[홈페이지 고객 문의]
- 휴대폰 가입통신사, 휴대폰 번호, 이메일, 문의내용
 
나. 개인정보 수집 방법
회사는 다음과 같은 방법으로 개인정보를 수집합니다.
- PC 및 휴대폰 어플리케이션, 홈페이지 상담 게시판, 전화, 이메일
- 협력회사로부터의 제공
 
다. 이용 목적
AI오토콜(AIAutocall)(이하 “서비스”)의 제공, 유지, 관리, 서비스 분석을 위한 자료 활용, 분쟁 해결, 민원 처리, 법령상 의무이행, 회사의 개인정보 처리업무
- 서비스 해지 시 개인정보 수집, 이용이 철회 됩니다.
※ 자동수집 장치인 쿠키를 사용하지 않습니다.
 

제 2조 개인정보의 처리 및 보유기간
회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
 
각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
보존항목/내용\t보존기간\t보존근거/사유
거래에 관한 장부 및 증빙서류\t5년\t국세기본법, 법인세법, 부가가치세법 등
대금결제 및 재화 등의 공급에 관한 기록\t5년\t전자상거래 등에서의 소비자 보호에 관한 법률
본인확인에 관한 기록\t6개월\t정보통신 이용촉진 및 정보보호 등에 관한 법률
계약 또는 청약철회 등에 관한 기록\t5년\t전자상거래 등에서의 소비자보호에 관한 법률
소비자의 불만 또는 분쟁처리에 관한 기록\t3년\t전자상거래 등에서의 소비자보호에 관한 법률
※ 그 외, 법률에 정해진 개인정보 보유기간이 있을 경우 그에 따름
 

제 3조 개인정보의 취급 위탁
회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다. 회사의 개인정보 위탁업체 및 위탁업무 내용은 아래와 같습니다.
 
제공받는 자\t제공목적\t제공항목\t보유기간
㈜씨에스비즈케어\t서비스 운영, 이용자 관리,
회원제 서비스 이용에 따른 본인확인, 고객상담, 불만 처리 등 고객 지원 업무\t휴대폰 번호, 서비스 가입/해지 정보\t서비스 해지 또는 위탁 계약 종료 시까지
국민종합주택관리 전주에코시티 데시앙네스트 3블럭\t서비스 이용 등록 및 삭제\t휴대폰 번호, 동/호수, 차량번호\t서비스 이용등록 삭제 또는 위탁 계약 종료 시까지
 

제 4조 정보주체의 권리·의무 및 행사방법
정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
가. 개인정보 열람요구
나. 오류 등이 있을 경우 정정 요구
다. 삭제 요구
라. 처리정지 요구
 
제1항에 따른 권리 행사는 회사에 대해 『개인정보 보호법 시행규칙』 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.
정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 『개인정보 보호법 시행규칙』 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
 

제 5조 개인정보의 파기
회사는 수집한 개인정보의 보유기간 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
 
개인정보 파기의 절차 및 방법은 다음과 같습니다.
 
가. 파기 절차
보존기간이 경과하거나 목적이 달성된 개인정보는 회사의 『개인정보 보호규칙』에 따라 개인정보 보호책임자의 승인을 받아 엄격한 통제절차에 의거 파기를 실시하고 있습니다.
 
나. 파기 방법
회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
 

제 6조 개인정보의 안전성 확보조치
회사는 『개인정보보호법』 제29조에 따라 다음과 같이 개인정보 안전성 확보에 관한 기술적·관리적·물리적 조치를 취하고 있습니다.
 
가. 내부관리계획의 수립·시행
회사는 『개인정보 보호규칙』 및 개인정보보호 종합 추진계획을 통하여 다음 사항이 포함된 내부관리계획을 수립·시행하고 있습니다.
개인정보 보호책임자의 지정에 관한 사항
개인정보 보호책임자 및 개인정보취급자의 역할 및 책임에 관한 사항
개인정보의 안전성 확보에 필요한 조치에 관한 사항
개인정보취급자에 대한 교육에 관한 사항
그 밖에 개인정보 보호를 위하여 필요한 사항
 
나. 접근권한의 관리
개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 업무 담당자에 따라 차등 부여하고 있습니다.
전보 또는 퇴직 등 인사이동이 발생하여 개인정보취급자가 변경되었을 경우 지체 없이 개인정보처리시스템의 접근권한을 변경 또는 말소하고 있으며, 권한 부여, 변경 또는 말소에 대한 내역을 기록하고, 그 기록을 일정 기간 보관하고 있습니다.
개인정보처리시스템에 대한 접근권한을 업무 수행에 필요한 최소한의 범위로 업무 담당자에 따라 차등 부여하고 있습니다.
 
다. 개인정보암호화
개인정보는 암호화 등을 통해 안전하게 저장·관리되고 있습니다. 또한 개인정보 처리 시 중요 데이터를 DRM으로 관리하거나 파일 잠금 기능을 사용하는 등 별도 보안기능을 사용하고 있습니다.
 
라. 접속기록의 보관 및 위·변조방지
개인정보취급자가 개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관하고 있으며, 접속기록이 위·변조 및 도난, 분실되지 않도록 해당 접속기록을 안전하게 보관하고 있습니다.
 
마. 보안프로그램 설치 및 운영
악성 프로그램 등을 방지 치료할 수 있는 백신 소프트웨어 등의 보안 프로그램을 설치·운영
 
바. 물리적 접근 방지
전산실, 자료보관실 등 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 있으며, 이에 대한 출입통제를 수립·운영하고 있습니다.
 

제 7조 개인정보 보호책임자
회사는 이용자의 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
정보주체께서는 회사의 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
 
개인정보 보호 책임자 및 담당자
가. 개인정보 담당부서 : 기술개발본부
나. 개인정보 책임자 및 담당자 : 김정태 본부장
다. 전화번호 : 1566-7531
라. 메일 : privacy@datau.co.kr
 
서비스 고충처리 상담
전화번호 : 1566-7531
 

제 8조 권익침해 구제방법
정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등의 문의하실 수 있습니다. 아래 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
 
센터\t전화번호\t홈페이지
개인정보 침해신고센터/분쟁조정위원회\t(국번없이) 118\thttp://privacy.kisa.or.kr
대검찰청 사이버범죄수사단\t02)3480-3571\thttp://cybercid.spo.go.kr
경찰청 사이버안전국\t(국번없이) 182\thttp://cyberbureau.police.go.kr
 

제 9조 고지의무
현 개인정보처리방침은 2021년 09월 01일에 제정되었으며 정보의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 경우에는 개정 최소 7일 전부터 홈페이지를 통해 고지할 것입니다.
`
}
