import { AutocallBtn } from 'components'
import QnaTerm from 'components/5_Support/5_4/QnaTerm'
import { AutocallForm, AutocallFormItem } from 'components/Common/form'
import AutocallInput from 'components/Common/form/AutocallInput'
import { useUser, useValidate } from 'hooks'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  successStickerTerm,
  userAddress1,
  userAddress2,
  userPostCode,
  userRecvNumber,
  userUName
} from 'recoil/user/atom'
import { useEffect, useState } from 'react'
import { validateTypeFam } from '../../../recoil/validate/atom'
import DaumPostCodeEmbed from '../../Common/DaumPostCodeEmbed'

const SuccessSticker = () => {
  const { sticker } = useUser()
  const { success, replace } = useValidate()
  const { successBenefit,
    successPCode,
    successAdd1,
    successAdd2,
    successRecvNum,
    successUNAme
  } = success
  const { replacePNumber, replaceName } = replace
  const [UName, setUName] = useRecoilState(userUName)
  const [Postcode, setPostcode] = useRecoilState(userPostCode)
  const [Address1, setAddress1] = useRecoilState(userAddress1)
  const [Address2, setAddress2] = useRecoilState(userAddress2)
  const [RecvNumber, setRecvNumber] = useRecoilState(userRecvNumber)
  const [Agree, setAgree] = useRecoilState(successStickerTerm)
  const successRecvNumValidState = useRecoilState(validateTypeFam('successRecvNumber'))
  const setTermsValid = useSetRecoilState(validateTypeFam('terms'))
  const successPostcodeValidState = useRecoilState(validateTypeFam('successPostcode'))
  const successUNameValidState = useRecoilState(validateTypeFam('successUName'))
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false)
  useEffect(() => {
    successUNameValidState[1]({ valid: true, msg: '' })
    successPostcodeValidState[1]({ valid: true, msg: '' })
    successRecvNumValidState[1]({ valid: true, msg: '' })
    setTermsValid({ valid: true, msg: '' })

    setUName('')
    setPostcode('')
    setAddress1('')
    setAddress2('')
    setRecvNumber('')
    setAgree(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAddress1, setAddress2, setAgree, setPostcode, setRecvNumber, setUName])
  const submitBenefitSticker = () => {
    successUNAme.validFunc(UName)
    successPCode.validFunc(Postcode)
    successAdd1.validFunc(Address1)
    successRecvNum.validFunc(RecvNumber)
    successBenefit.validFunc(`${Agree}`)
    if (!successUNAme.validFunc(UName)) {
      window.scrollTo({
        top: 630,
        behavior: 'smooth'
      })
    } else if (!successPCode.validFunc(Postcode) || !successAdd1.validFunc(Address1)) {
      window.scrollTo({
        top: 770,
        behavior: 'smooth'
      })
    } else if (!successRecvNum.validFunc(RecvNumber)) {
      window.scrollTo({
        top: 1150,
        behavior: 'smooth'
      })
    } else if (!successBenefit.validFunc(`${Agree}`)) {
      window.scrollTo({
        top: 1300,
        behavior: 'smooth'
      })
    } else if (
      successUNAme.validFunc(UName) &&
      successPCode.validFunc(Postcode) &&
      successAdd1.validFunc(Address1) &&
      successRecvNum.validFunc(RecvNumber) &&
      successBenefit.validFunc(`${Agree}`)
    ) {
      sticker.request()
    }
  }

  const handleComplete = (data: any) =>  {
    setPostcode(data.zonecode)
    setAddress1(data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress)
    successPCode.validFunc(data.zonecode)
    successAdd1.validFunc(data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress)
    setIsSearchMode(false)
  }
  useEffect(() => {
   if (isSearchMode) {
     const element = document.getElementById('배송지 입력')
     if (element) element.scrollIntoView()
   }
  }, [isSearchMode])
  return (
    <AutocallForm>
      <AutocallFormItem title='수령인' validate={successUNAme.recoilState[0]} essential>
        <AutocallInput
          inputType='text'
          placeholder='ex) 홍길동'
          state={UName}
          setState={setUName}
          validate={successUNAme}
          replacer={replaceName}
          maxLength={10}
        />
      </AutocallFormItem>
      <AutocallFormItem title='배송지 입력' validate={successPCode.recoilState[0]} essential>
        {isSearchMode ? <div className={'postcode-container'}>
          <button className={'close-btn'} onClick={() => setIsSearchMode(false)}>X</button>
          <div className={'daum-postcode-embed-container'}>
            <DaumPostCodeEmbed
              onComplete={handleComplete}
              style={{ height: '480px', outline: 'none', zIndex: 200 }}
              autoClose={false} />
          </div>
        </div>
        : <>
            <div className="input-part">
              <AutocallInput
                inputType="text"
                placeholder="우편번호"
                state={Postcode}
                setState={setPostcode}
                validate={successPCode}
              />
              <AutocallBtn title="우편번호" type="nomal" onClickFunc={() => setIsSearchMode(true)}/>
            </div>
            <AutocallInput
              inputType="text"
              placeholder="기본주소"
              state={Address1}
              setState={setAddress1}
              validate={successAdd1}
            />
            <AutocallInput
              inputType="text"
              placeholder="상세 주소를 입력해 주세요. (동, 호수)"
              state={Address2}
              setState={setAddress2}
              validate={successAdd2}
              maxLength={30}
            />
          </>}
      </AutocallFormItem>
      <AutocallFormItem title="휴대폰 번호" validate={successRecvNum.recoilState[0]} essential>
        <AutocallInput
          inputType="tel"
          placeholder={'\'-\'없이 숫자만 입력하세요.'}
          state={RecvNumber}
          setState={setRecvNumber}
          validate={successRecvNum}
          replacer={replacePNumber}
          maxLength={11}
        />
      </AutocallFormItem>
      <AutocallFormItem/>
      <AutocallFormItem title="이용 약관 동의" validate={successBenefit.recoilState[0]}>
        <QnaTerm title="개인정보 수집 및 이용약관" setState={setAgree} state={Agree} validate={successBenefit}/>
      </AutocallFormItem>
      <AutocallFormItem>
        <div className="join-submit">
          <AutocallBtn title='혜택 신청' type='default' onClickFunc={submitBenefitSticker} />
          <p className='under-text'>
            본 혜택은 신규 가입 후 최초 1회 신청이 가능하며
            <br /> 이용 1개월 내 해지 고객은 대상에서 제외됩니다.
          </p>
        </div>
      </AutocallFormItem>
    </AutocallForm>
  )
}

export default SuccessSticker
