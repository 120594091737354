import styled from 'styled-components'
import { useRecoilState } from 'recoil'
import { popupopen } from '../../recoil/common/atom'
import STYLE_GUIDE from '../../service/constants/styleGuide'
import { useCookies, useGetHomeDatas } from '../../hooks'
import dayjs from 'dayjs'

export const InProgressPopup = styled.div`
  z-index: 998;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const InProgressPopupContent = styled.div`
  display: block;
  background: white;
  width: 280px;
  margin-top: 20px;
  min-height: 170px;
  height: auto;
  box-shadow: 0 2px 5px rgba(42, 41, 46, 0.16);
  border-radius: 16px;

  > img {
    border-radius: 16px 16px 0 0;
    width: 100%;
    cursor:pointer;
  }

  > div {
    margin-top: -5px;
    display: flex;
    width: 100%;

    .left {
      border-radius: 0 0 0 16px;
    }

    .right {
      border-left: 1px solid #f1f1f1;
      border-radius: 0 0 16px 0;
    }

    > button {
      cursor: pointer;
      color: ${STYLE_GUIDE.COLOR.main01};
      background-color: #ffffff;
      border: none;
      height: 60px;
      width: 50%;
    }
  }
`

const Popup = () => {
  const datas = useGetHomeDatas()
  const [popopen, setPopOpen] = useRecoilState(popupopen)
  const cookie = useCookies()
  function closeToday () {
    if (!cookie.get('popup')) {
      cookie.set('popup', 'home', 24 - dayjs(Date.now()).hour())
      setPopOpen(false)
    }
  }
  const handleImgClick = () => {
    if (datas.popup) {
      window.open(datas.popup()?.LinkPath, 'main popup')
    }
  }
  // console.log(datas.popup())
  return (
    <>
      {popopen && !cookie.get('popup') && datas?.popup()?.ImgPath && (
        <InProgressPopup>
          <InProgressPopupContent>
            <img
              src={`https://images.autocall.ai${datas.popup()?.ImgPath}`}
              alt='팝업 이미지'
              onClick={handleImgClick}
            />
            <div>
              <button className='left' onClick={() => closeToday()}>
                오늘 그만 보기
              </button>
              <button className='right' onClick={() => setPopOpen(false)}>
                닫기
              </button>
            </div>
          </InProgressPopupContent>
        </InProgressPopup>
      )}
    </>
  )
}
export default Popup
