import { Main3Desc } from '../../../styles/Web/Main/main3desc_styled'
import green_light from '../../../assets/img/mainbanner/green_light.png'
import green_light_img from '../../../assets/img/mainbanner/main3_third.gif'

const MainService4Section = () => {
  return (
    <Main3Desc>
      <div className='main-service_content service4'>
        <section>
          <div className='section_desc service4'>
            <div>
              <img className='color_light' src={green_light} alt='red_light' />
              <p>
                <span className='underline'>보이는 ARS</span>로
                <br />
                간편하게 운전자 호출!
              </p>
            </div>
          </div>
          <div>
            <span>전화걸고, 화면보호, 버튼 누르면 호출 완료!</span>
            <p>
              ARS, 다시 들을 필요 없이 직접 화면을 보고 <br />
              운전자를 호출 할 수 있는 방법을 선택해보세요.
            </p>
          </div>
        </section>
        <section>
          <img src={green_light_img} alt='red_light_img' />
        </section>
      </div>
    </Main3Desc>
  )
}

export default MainService4Section
