import { Main3Desc } from '../../../styles/Web/Main/main3desc_styled'
import yellow_light from '../../../assets/img/mainbanner/yellow_light.png'
import pc_yellow_light_img from '../../../assets/img/mainbanner/pc_main3_second.gif'
import mo_yellow_light_img from '../../../assets/img/mainbanner/mo_main3_second.gif'
import warning_icon from '../../../assets/img/mainbanner/warning_icon.png'
const MainService3Section = () => {
  return (
    <Main3Desc>
      <div className='main-service_content service3'>
        <div className='mobile_waring'>
          <img src={warning_icon} alt='' />
          <p>
            <span>를 확인해 주세요!</span>
            <p>
              호출하려는 차량이 오토콜에 <br />
              가입되어 있지 않으면 이용이 어렵습니다.
            </p>
          </p>
        </div>
        <section>
          <img className='pc' src={pc_yellow_light_img} alt='car imoticon' />
          <img className='mo' src={mo_yellow_light_img} alt='car imoticon' />
        </section>
        <section>
          <div className='section_desc service3'>
            <div>
              <img className='color_light' src={yellow_light} alt='yellow_light_img' />
              <p>
                <span className='underline'>직접 통화 없이</span>
                <br />
                운전자를 호출하세요.
              </p>
            </div>
          </div>
          <div>
            <span>"차 빼달라고 전화했는데 왜 짜증을 내세요..."</span>
            <p className='ser3_pc_text'>
              불편할 수 있는 직접적인 상대방과 전화통화 없이
              <br />
              버튼만 누르면 운전자를 호출할 수 있습니다.
            </p>
          </div>
          <div className='warning_message'>
            <img src={warning_icon} alt='' />
            <p>
              <span>를 확인해 주세요!</span>
              <br />
              호출하려는 차량이 오토콜에 가입되어 있지 않으면 이용이 어렵습니다.
            </p>
          </div>
        </section>
      </div>
    </Main3Desc>
  )
}

export default MainService3Section
