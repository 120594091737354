import STYLE_GUIDE from '../../service/constants/styleGuide'
import styled from 'styled-components'
import sticker1 from 'assets/img/sticker/sticker1.png'
import sticker2 from 'assets/img/sticker/sticker2.png'
import sticker3 from 'assets/img/sticker/sticker3.png'
import sticker4 from 'assets/img/sticker/sticker4.png'
import sticker5 from 'assets/img/sticker/sticker5.png'
import sticker1_real from 'assets/img/sticker/real-sticker.png'
import React from 'react'
export const imgOption = {
  content: [
    {
      img: `${sticker1}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker2}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker3}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker4}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker5}`,
      detail_img: `${sticker1_real}`
    }
  ],
  mo_content: [
    {
      img: `${sticker1}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker2}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker3}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker5}`,
      detail_img: `${sticker1_real}`
    },
    {
      img: `${sticker4}`,
      detail_img: `${sticker1_real}`
    }
  ]
}

const StickerOptionWrap = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .mo {
      display: none;
    }
    .pc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .exception {
        width: 362px;
        height: 258px;
      }
      > img {
        margin: 55px auto;
        width: 300px;
      }
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    .pc {
      display: none;
    }
    .mo {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .exception {
        margin-left: 90px;
        width: 140px;
        height: 100px;
      }
      > img {
        width: 140px;
        height: 140px;
        margin-bottom: 40px;
      }
    }
  }
`
const StickerOption = () => {
  return (
    <StickerOptionWrap>
      <div className='pc'>
        {imgOption.content.map((option, index) => (
          <React.Fragment key={index}>
            {index === 3
              ? (
                <img className='exception' src={option.img} alt='' />
                )
              : (
                <img src={option.img} alt='sticker_img' />
                )}
          </React.Fragment>
        ))}
      </div>
      <div className='mo'>
        {imgOption.mo_content.map((option, index) => (
          <React.Fragment key={index}>
            {index === 4
              ? (
                <img className='exception' src={option.img} alt='' />
                )
              : (
                <img src={option.img} alt='sticker_img' />
                )}
          </React.Fragment>
        ))}
      </div>
    </StickerOptionWrap>
  )
}
export default StickerOption
